/**
 * 车辆诊断
 */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Input,
  message,
  Tooltip,
  Switch,
  Divider,
} from "antd";
import dayjs from "dayjs";
import gcoord from "gcoord";
import {
  carLastLocationRequest,
  carListRequest,
  CarListRequestParams,
  carListRequests,
} from "@/service/carManage";
import { operationGroupListRequest } from "@/service/operationGroupManage";
import DatePicker from "@/components/datePicker";
import CarListModal from "@/components/carListModal";
import ParamsModal from "@/components/paramsModal";
import VersionModal from "@/components/versionModal";
import { copyLocationLink } from "@/utils/location";
import { importMap } from "@/utils/map";
import { getInnerPermissionEnable } from "@/utils/permission";
import { isKlsAeb, isKlsV3Aeb } from "@/utils/device";
import { renderImuStatus } from "@/utils/imu";
import {
  carTypeData,
  deviceCategoryData,
  DeviceCategoryEnum,
  DeviceTypeEnum,
} from "@/constants/deviceManage";
import MaintenanceRecord from "./components/maintenanceRecord";
import CarDiagnosisTable from "./components/carDiagnosisTable";
import CarAdasTable from "./components/carAdasTable";
import styles from "./index.module.scss";
import SurveyRecoed from "./components/surveyRecoed";
import PermissionButton from "@/components/permissionButton";
import CostRecord from "./components/costRecord";
import ContractRecoed from "./components/contractRecord";
import CarProceedRecoed from "./components/carProceedRecoed";
import InsurePayRecord from "./components/insurePayRecord";
import UnderwritingRecord from "./components/underwritingRecord";
import ParameterConfigModal from "./components/parameterConfigModal";
import EditServiceState from "./components/editServiceState"; //编辑运维备注
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useUserCateIdArr } from "@/utils/ownHooks";

import EditVehicleStateModal from "./components/editVehicleStateModal"; // 编辑车辆状态
import EditDeviceStateModal from "./components/editDeviceStateModal";
import ServiceRecoed from "@/pages/carDiagnosis/components/serviceRecoed";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn"; // 编辑设备状态
import EditPhoneModal from "../carDiagnosis/components/editPhoneModal";
import OperateTeamModal from "./components/editTeamModal";

const { RangePicker } = DatePicker;

const CarDiagnosis = () => {
  const cateIdArr = useUserCateIdArr();
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  let mapRef: any = useRef(null); // 存储地图实例
  let markerRef: any = useRef(null); // 存储车辆点位实例
  let timerRef: any = useRef(null); // 存储获取车辆位置的定时器
  let heartTimerRef: any = useRef(null); // 存储获取心跳的定时器
  const [carInfo, setCarInfo] = useState<any>({}); // 车辆信息
  const [hasSearch, setHasSearch] = useState(false); // 是否搜索过
  const [recordVisible, setRecordVisible] = useState<boolean>(false); // 是否显示维保记录
  const [costRecordVisible, setCostRecordVisible] = useState<boolean>(false); // 是否显示收费记录
  const [surveyRecoedVisible, setSurveyRecoedVisible] =
    useState<boolean>(false); // 是否显示收费记录
  const [operateInfo, setOperateInfo] = useState<string>(""); // 显示弹窗相关操作的信息
  const [searchValues, setSearchValues] = useState<any>(null); // 搜索到多辆车时的搜索参数
  const [diagnosisSearchParams, setDiagnosisSearchParams] = useState<any>(null); // 获取车辆诊断表格数据的搜索参数
  const [operationGroupList, setOperationGroupList] = useState<any>([]); // 运维组列表数据
  const [form] = Form.useForm();
  const [underwritingRecordVisible, setUnderwritingRecordVisible] =
    useState<boolean>(false); // 是否显示保费支付记录
  const [contractRecordVisible, setContractRecordVisible] =
    useState<boolean>(false); // 是否显示合同记录
  const [carProceedRecordVisible, setCarProceedRecordVisible] =
    useState<boolean>(false); // 是否显示诉讼记录
  const [insurePayRecordVisible, setInsurePayRecordVisible] =
    useState<boolean>(false); // 是否显示保费支付记录
  const [parameterConfigVisible, setParameterConfigVisible] =
    useState<boolean>(false); // 是否显示参数配置
  const [isVehicleStateEditModalOpen, setIsVehicleStateEditModalOpen] =
    useState<boolean>(false); // 是否显示车辆状态编辑弹窗
  const [isAdasStateEditModalOpen, setIsAdasStateEditModalOpen] =
    useState<boolean>(false); // 是否显示adas设备状态编辑弹窗
  const [isAebStateEditModalOpen, setIsAebStateEditModalOpen] =
    useState<boolean>(false); // 是否显示aeb设备状态编辑弹窗
  const [editServiceState, setEditServiceState] = useState<any>({}); //编辑运维状态
  const [serviceRecordVisible, setServiceRecordVisible] =
    useState<boolean>(false); // 是否显示客服记录
  const [isAdasMap, setIsAdasMap] = useState<boolean>(false); // 是否显示adas地图
  const [isMultipleDevice, setIsMultipleDevice] = useState<boolean>(false); // 是否有多个设备（adas,aeb）
  const [isConcatModal, setIsConcatModal] = useState<string>('')
  const [isConcatTeamModal, setIsConcatTeamModal] = useState<string>('')
  const [plateNumber, setPlateNumber] = useState('')

  //是否可以查看合同信息
  // const canViewContract = getInnerPermissionEnable("/car-admin/car-diagnosis/view-contract");
  const canViewContract = true;
  // 是否可以查看设备信息
  // const canViewDevice = getInnerPermissionEnable("/car-admin/car-diagnosis/view-device");
  const canViewDevice = true;
  // 是否是昆仑山设备
  const isKls = isKlsAeb(carInfo?.deviceTypeCategory);
  // 是否是昆仑山3.0设备
  const isKlsV3 = isKlsV3Aeb(carInfo?.deviceTypeCategory);

  const editaaa = () => (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2275"
      width="13"
      height="13"
    >
      <path
        d="M928 365.664a32 32 0 0 0-32 32V864a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32h429.6a32 32 0 0 0 0-64H160a96 96 0 0 0-96 96v704a96 96 0 0 0 96 96h704a96 96 0 0 0 96-96V397.664a32 32 0 0 0-32-32z"
        fill="#9c9c9c"
        p-id="2276"
      ></path>
      <path
        d="M231.616 696.416a38.4 38.4 0 0 0 44.256 53.792l148-38.368L950.496 185.248 814.72 49.472 290.432 573.76l-58.816 122.656z m111.808-85.12L814.72 140l45.248 45.248-468.992 468.992-77.824 20.16 30.272-63.104z"
        fill="#9c9c9c"
        p-id="2277"
      ></path>
    </svg>
  );
  const OpenSoundIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={editaaa} {...props} />
  );

  // 组件销毁时清楚定时器
  useEffect(() => {
    getOperationGroupList();
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
      heartTimerRef.current && clearTimeout(heartTimerRef.current);
    };
  }, []);

  // 监听跳转时携带参数的形式
  useEffect(() => {
    tabParamsSearch();
  }, [activeKey, tabParams]);

  // 获取运维组列表
  const getOperationGroupList = async () => {
    const res = await operationGroupListRequest();
    setOperationGroupList(res.data?.data || []);
  };
  // 跳转时携带参数时搜索
  const tabParamsSearch = async () => {
    let mapSuccess: any = false;
    if (window.BMap && mapRef.current) {
      mapSuccess = true;
    } else {
      mapSuccess = await importMap(initMapCallback);
    }
    if (mapSuccess && activeKey === "/car-admin/car-diagnosis" && tabParams) {
      const newTabParams = {
        plateNumber: tabParams.plateNumber,
        sn: "",
        frameNumber: "",
        time: [dayjs(tabParams.beginDate), dayjs(tabParams.endDate)],
      };
      form.setFieldsValue(newTabParams);
      form.submit();
    }
  };

  // 地图初始化成功的回调
  const initMapCallback = () => {
    mapRef.current = new window.BMap.Map("carMap");
    const top_left_control = new window.BMap.ScaleControl({
      anchor: window.BMAP_ANCHOR_TOP_LEFT,
    });
    const top_left_navigation = new window.BMap.NavigationControl({
      anchor: window.BMAP_ANCHOR_TOP_LEFT,
    });
    mapRef.current.addControl(top_left_control); // 左上角添加比例尺控件
    mapRef.current.addControl(top_left_navigation); // 左上角添加缩放控件
    mapRef.current.enableScrollWheelZoom(); // 开启鼠标滚轮缩放
    mapRef.current.addControl(
      new window.BMap.MapTypeControl({
        mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
      })
    ); // 添加卫星图层
    const mapCenter = new window.BMap.Point(116.404, 39.915);
    mapRef.current.centerAndZoom(mapCenter, 16);
  };

  // 获取匹配搜索的车辆
  const getMatchCar = async (params: any) => {
    const newParams = {
      page: 1,
      limit: 10,
      plateNumber: params.plateNumber,
      sn: params.sn,
      frameNumber: params.frameNumber,
      cate_id: cateIdArr,
    };
    const res = await carListRequest(newParams);
    const matchCar = res.data?.data || [];
    if (matchCar.length === 0) {
      message.warning("车辆不存在");
      setCarInfo({});
      setDiagnosisSearchParams(null);
      getCarLocation();
      timerRef.current && clearTimeout(timerRef.current);
      heartTimerRef.current && clearTimeout(heartTimerRef.current);
    } else if (matchCar.length === 1) {
      // 只匹配到一辆车时获取该车信息
      plateNumberClick(matchCar[0], params);
      setPlateNumber(matchCar[0]?.plate_number)
    } else {
      // 获取到多辆车时显示车辆列表进行选择
      setSearchValues(params);
    }
  };

  // 获取车辆位置信息
  const getCarLocation = async (carInfo?: any) => {
    if (carInfo.id && carInfo.plate_number) {
      const car_path =
        "M689.7 217.7c-7.2-5.6-14.3-10.2-20.3-13.2 0.4-5.1 0.7-10.2 1.1-15.3 0.1 0.3 0.1 0.6 0.2 0.9 0.3-4.9 0.7-9.8 1.1-14.7 3.2-35.8-24.1-80.1-61.5-90.3-65.2-17.1-130.4-17.1-195.7 0.2-37.4 10.2-64.6 54.5-61.5 90.3 0.4 4.9 0.8 9.8 1.1 14.7 0.1-0.3 0.1-0.6 0.2-0.9 0.4 5.1 0.8 10.2 1.1 15.3-6 3-13.1 7.5-20.3 13.2-16.6 13.1-27.4 27.1-24.1 31.3 3.3 4.2 19.5-3 36.1-16.1 3.5-2.8 6.7-5.6 9.7-8.3 4.6 70.5 7.1 141.1 7.5 211.6v2.2c0.1 1.3 0.6 4.3 4.4 4.6h286.5c0.2 0 0.4-0.1 0.6-0.1 3.5-0.3 4.3-2.9 4.4-4.3v-2.4c0.4-70.5 2.9-141.1 7.6-211.7 2.9 2.7 6.2 5.5 9.7 8.3 16.6 13.1 32.8 20.3 36.1 16.1 3.4-4.3-7.4-18.3-24-31.4z m-89.5-104.3c3.8-4.8 17.6-0.3 30.8 10.1s20.9 22.7 17.1 27.5c-3.8 4.8-17.6 0.3-30.8-10.1-13.3-10.4-20.9-22.7-17.1-27.5zM394 123.5c13.2-10.4 27-14.9 30.8-10.1 3.8 4.8-3.9 17.1-17.1 27.5-13.2 10.4-27 14.9-30.8 10.1-3.8-4.8 3.9-17.1 17.1-27.5z m-24.1 263.2v-0.4L363.8 231l2.4 4.2c12.1 21.1 17.1 46.6 21.1 67 5.3 27.7 8.2 61.8 9.2 107.4l0.1 2.8-26.7-25.7zM402 330l-9.7-99.9 0.6-0.5c10.4-8.8 21.7-16.4 33.5-22.8 26.4-14 56.2-21.4 86.1-21.4 17.9 0 35.6 2.6 52.6 7.7 14 4.2 27.5 10 40.2 17.5 1.1 0.6 2.1 1.2 3.2 1.9 8.3 5.1 16.3 10.8 23.7 17.1l0.6 0.5L623 330H402z m253.1 56.3v0.4l-26.7 25.7 0.1-2.8c1-45.6 3.9-79.7 9.2-107.4 4-20.5 9-45.9 21.1-67l2.4-4.2-6.1 155.3zM680.5 455.9h-334c-3.9 0-7.1 3.2-7.1 7.1v482.2c0 3.9 3.2 7.1 7.1 7.1h334.1c3.9 0 7.1-3.2 7.1-7.1V463c0-3.9-3.2-7.1-7.2-7.1zM421.8 910.1c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z m66.7 0c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z m66.8 0c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z m66.7 0c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z";
      const car_online = new window.BMap.Symbol(car_path, {
        fillColor: "#22c306",
        fillOpacity: 1,
        scale: 0.036,
      });
      // const params = {
      //   carId: id,
      //   cate_id: cateIdArr
      // }
      // const res = await carListRequest(params as CarListRequestParams);

      const lastLocationParams = {
        carId: carInfo.id,
        plateNumber: carInfo.plate_number,
        //是不是昆仑山这个字段判断依据：
        // 如果是既有aeb设备又有adas设备，那就根据切换开关的值来确定，
        // 否则就是有设备id的情况下视为kls设备（即aeb）
        isKls: isMultipleDevice ? !isAdasMap : !!carInfo.deviceId,
      };
      const res = await carLastLocationRequest(lastLocationParams);

      const carLocationData = res.data?.data || {};
      if (carLocationData.lat && carLocationData.lon) {
        // 有车辆位置信息显示车辆位置信息
        const transformGcoord = gcoord.transform(
          [carLocationData.lat, carLocationData.lon],
          gcoord.WGS84,
          gcoord.BD09
        );
        const carPoint = new window.BMap.Point(
          transformGcoord[0],
          transformGcoord[1]
        );
        // 如果之前有marker则清除
        if (markerRef.current) {
          mapRef.current.removeOverlay(markerRef.current);
        }
        mapRef.current.centerAndZoom(carPoint, 16);
        markerRef.current = new window.BMap.Marker(carPoint, {
          title: carLocationData.plate_number,
          icon: car_online,
          offset: new window.BMap.Size(-15, -15),
        });
        mapRef.current.addOverlay(markerRef.current);
        markerRef.current.addEventListener("click", function () {
          markClick(carLocationData, transformGcoord, carPoint);
        });
        markClick(carLocationData, transformGcoord, carPoint);
      } else {
        // 没有车辆位置时的显示
        const carPoint = new window.BMap.Point(116.404, 39.915);
        mapRef.current.centerAndZoom(carPoint, 16);
      }

      //清楚之前的定时器
      timerRef.current && clearTimeout(timerRef.current);
      // 轮询调用最新位置
      timerRef.current = setTimeout(() => {
        getCarLocation(carInfo);
      }, 30000);
    } else {
      // 没有车辆位置时的显示
      const carPoint = new window.BMap.Point(116.404, 39.915);
      mapRef.current.centerAndZoom(carPoint, 16);
    }
  };

  // 根据匹配车辆的车牌号进行搜索
  const plateNumberClick = (data: any, searchData?: any) => {
    timerRef.current && clearTimeout(timerRef.current);
    heartTimerRef.current && clearTimeout(heartTimerRef.current);
    setPlateNumber(data.plate_number)
    form.setFieldsValue({
      plateNumber: data.plate_number,
      sn: "",
      // sn: data.sn,
      frameNumber: "",
    });
    const params = {
      beginDate: (searchData || searchValues).beginDate,
      endDate: (searchData || searchValues).endDate,
      plateNumber: data.plate_number,
    };
    setDiagnosisSearchParams(params);
    setCarInfo(data);
    //改到useEffect里面调用
    // getCarLocation(data?.carId);
    setHasSearch(true);

    //需求：车辆诊断显示内容要根据设备是adas或者是aeb来进行区分
    //根据aeb设备id（deviceId）和adas设备id（jt808DeviceId）两个字段的有无判断是否有多个设备
    const isMultipleDevice = !!(data.deviceId && data.jt808DeviceId);
    setIsMultipleDevice(isMultipleDevice);
    //根据设备大类来判断应该先显示那个设备
    if (isMultipleDevice) {
      const deviceTypeCategory = data.deviceTypeCategory;
      //多设备的情况下，只有设备大类字段值是5（部标机设备）才默认显示aeb设备
      setIsAdasMap(deviceTypeCategory === DeviceCategoryEnum.adas);
    }
    //单设备的情况，根据有无设备id判断显示的地图数据
    else {
      setIsAdasMap(!!data.jt808DeviceId);
    }
  };

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    delete newParams.time;
    delete newParams.plateNumber; // 出现连续两次请求，保留以前的车牌号的情况
    newParams.beginDate = dayjs(params.time[0]).format("YYYY-MM-DD");
    newParams.endDate = dayjs(params.time[1]).format("YYYY-MM-DD");
    newParams.plateNumber = (params.plateNumber || "").trim();
    newParams.sn = (params.sn || "").trim();
    newParams.frameNumber = (params.frameNumber || "").trim();
    newParams.carId = (params.carId || "").trim();
    if (isExport) {
      newParams.export = true;
    }
    if (newParams.plateNumber || newParams.sn || newParams.frameNumber) {
      return newParams;
    }
  };

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams = operateSearchParams(values);
    if (newSearchParams) {
      getMatchCar(newSearchParams);
    } else {
      message.warning("请输入车牌号或设备SN或车架号进行搜索");
    }
  };

  // 点击车辆图标显示信息
  const markClick = (
    carLocationData: any,
    transformGcoord: any,
    carPoint: any
  ) => {
    //更新接口以后数据的字段换了，这里加一个缺省值的处理：改成--
    const {
      plateNumber: plate_number = "--",
      gpsSpeed: speed = "--",
      reportTime: gpsTime = "--",
    } = carLocationData;
    const carInfoWindow = new window.BMap.InfoWindow("", {
      title: "车辆位置信息",
      width: 400,
    });
    const locationLink = `http://api.map.baidu.com/marker?location=${transformGcoord[1]},${transformGcoord[0]}&title=${plate_number}&content=${plate_number}&output=html`;
    const content = `车牌号：${plate_number}<br>GPS速度: ${
      speed != 0 && speed ? speed : "--"
    }<br>上报时间: ${
      gpsTime ? dayjs(gpsTime).format("YYYY-MM-DD HH:mm:ss") : gpsTime || ""
    }<br>地址链接: <a target='_blank' href="${locationLink}">${locationLink}</a><button id='copyLinkButton'>复制</button>`;
    carInfoWindow.setContent(content);
    mapRef.current.openInfoWindow(carInfoWindow, carPoint);
    copyLocationLink(locationLink, carInfoWindow);
  };

  // 渲染车辆信息
  const renderCarInfo = (key: string) => {
    let value;
    switch (key) {
      case "adasOnline":
        value = <RenderEllipsisColumn text={ carInfo[key] === '时间有误' ? '是' : carInfo[key]}/>;
        break;
      case "online":
        value = <RenderEllipsisColumn text={ carInfo[key] === '时间有误' ? '是' : carInfo[key]}/>;
        break;
      case "carType":
        const type = carInfo[key];
        value = carTypeData.find((item) => item.value === type)?.label || "--";
        break;
      case "vehicleState": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={() => setIsVehicleStateEditModalOpen(true)}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break;
      }
      case "deviceState": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={() => setIsAebStateEditModalOpen(true)}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break;
      }
      case "car_driver":
      case "car_principal":
      case "car_driver_phone":
      case "car_principal_phone": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={() => setIsConcatModal(key)}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break
      }
      case "ooe":
      case "ooePhone": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={() => setIsConcatTeamModal(key)}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break
        break
      }
      case "adasDeviceState": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={() => setIsAdasStateEditModalOpen(true)}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break;
      }
      case "operateRemark": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={editServiceStatebtn}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break;
      }
      case "coordinateAbility": {
        const valueStr = carInfo[key] || "--";
        value = (
          <span
            style={{ display: "flex", justifyContent: "flex-start" }}
            onClick={editUserCoStatebtn}
          >
            {valueStr}
            <div style={{ cursor: "pointer", marginLeft: "5px" }}>
              <OpenSoundIcon />
            </div>
          </span>
        );
        break;
      }
      case "参数信息":
        //黄河设备只显示版本号
        if (carInfo.deviceTypeCategory === DeviceCategoryEnum.huanghe) {
          value = (
            <RenderEllipsisColumn
              ellipsis={true}
              text={carInfo?.param_info || carInfo?.paramInfo || "--"}
            />
          );
        }
        //其他设备显示弹窗
        else {
          value = (
            <Button
              type="link"
              onClick={() => setOperateInfo("paramInfo")}
              style={{ fontSize: "12px", height: "22px", lineHeight: "22px" }}
            >
              参数信息详情
            </Button>
          );
        }
        break;
      case "版本信息":
        //黄河设备只显示版本号
        if (carInfo.deviceTypeCategory === DeviceCategoryEnum.huanghe) {
          value = (
            <RenderEllipsisColumn
              ellipsis={true}
              text={carInfo.version_info || carInfo.versionInfo || "--"}
            />
          );
        }
        //其他设备显示弹窗
        else {
          value = (
            <Button
              type="link"
              onClick={() => setOperateInfo("versionInfo")}
              style={{ fontSize: "12px", height: "22px", lineHeight: "22px" }}
            >
              版本信息详情
            </Button>
          );
        }

        break;
      default:
        value = <RenderEllipsisColumn ellipsis={true} text={carInfo[key]} />;
        break;
    }
    return value;
  };
  const renderCarInfoDom = (item: any) => {
    if (!item.isShowKey || (item.isShowKey &&  carInfo[item.isShowKey])) {
      if (item.title) {
        return (
          <Col span={item.span} key={item.title}>
            <div className={styles.carInfoTitle}>{item.title}</div>
            <div className={styles.carInfoContent}>
              {/*<RenderEllipsisColumn text={renderCarInfo(item.key)}/>*/}
              {renderCarInfo(item.key) || "--"}
            </div>
          </Col>
        );
      } else {
        return (
          <Col span="24">
            <hr />
          </Col>
        );
      }
    } else {
      return null
    }

  };

  // 是否显示运维组信息
  const showOperationGroup = getInnerPermissionEnable(
    "/car-admin/car-diagnosis/show-operation-group"
  );

  // 要展示的车辆信息
  const showCarInfo = [
    //--行驶证信息
    { title: "车牌号", key: "plate_number", span: 8 },
    { title: "车架号", key: "frame_number", span: 8 },
    { title: "车辆品牌", key: "car_brand", span: 8 },
    { title: "车辆类型", key: "carType", span: 8 },
    { title: "车辆所有人（所属公司）", key: "company", span: 8 },
    // 联系信息
    
    { title: "车队长", key: "ooe", span: 8 },
    { title: "司机", key: "car_driver", span: 8 },
    { title: "老板", key: "car_principal", span: 8 },
    { title: "车队长联系方式", key: "ooePhone", span: 8 },
    { title: "司机联系方式", key: "car_driver_phone", span: 8 }, //14
    { title: "老板联系方式", key: "car_principal_phone", span: 8 },
    // 业务信息
    { title: "业务模式", key: "mode", span: 8 },
    { title: "车辆查勘评级", key: "carEvaluate", span: 8 }, //7
    { title: "投保保司", key: "insurance", span: 8 },
    { title: "业务渠道", key: "channel", span: 8 },
    { title: "设备合同号", key: "deviceContractNo", span: 8 },
    { title: "最新设备合同开始时间", key: "contractBeginDate", span: 8 },
    { title: "最新设备合同结束时间", key: "contractEndDate", span: 8 },
    { title: "合同状态", key: "contractState", span: 8 },
    // 没有承保单号
    { title: "承保状态", key: "businessState", span: 8 },
    { title: "最新服务单开始时间", key: "commercialBeginDate", span: 8 },
    { title: "最新服务单结束时间", key: "commercialEndDate", span: 8 },
    { title: "商业险保单号", key: "commercialNumber", span: 8 },
    { title: "累计出险次数", key: "riskCount", span: 8 },
    { title: "客服累计服务次数", key: "totalServiceCount", span: 8 },
    { title: "累计线下运维次数", key: "maintainceCount", span: 8 },
    { title: "累计车队走访次数", key: "companyInterviewCount", span: 8 },
    { title: "运维备注", key: "operateRemark", span: 8 }, //34
    { title: "用户配合度", key: "coordinateAbility", span: 8},
    //设备信息
    { title: "安装设备类型", key: "deviceTypeStr", span: 8 },
    { title: "设备安装日期", key: "installTime", span: 8 },
    { title: "设备拆除日期", key: "deviceRemoveTime", span: 8 },
    { title: "AEB_SN", key: "sn", span: 8, isShowKey: 'deviceId' },
    { title: "AEB_SIM卡ICCID", key: "sim", span: 8, isShowKey: 'deviceId'  },
    { title: "aeb在线信息", key: "online", span: 8, isShowKey: 'deviceId'  },
    { title: "ADAS_SN", key: "adas_sn", span: 8, isShowKey: 'jt808DeviceId' },
    { title: "ADAS_SIM卡ICCID", key: "adas_sim", span: 8, isShowKey: 'jt808DeviceId' },
    { title: "adas在线信息", key: "adasOnline", span: 8, isShowKey: 'jt808DeviceId' },   
    { title: "AEB工作状态", key: "deviceState", span: 8 },
    { title: "ADAS工作状态", key: "adasDeviceState", span: 8 },
    { title: "相机SN", key: "cameraSn", span: 8 },
    { title: "设备类型", key: "deviceTypeCategoryStr", span: 8 },
    { title: "参数信息", key: "参数信息", span: 8 }, //---
    { title: "版本信息", key: "版本信息", span: 8 }, //---

    { title: "车队最新评级", key: "companyGrade", span: 8 },
    { title: "合同期内投保次数", key: "serviceCount", span: 8 },
    { title: "车辆状态", key: "vehicleState", span: 8 },
    { title: "运维评级", key: "grade", span: 8 },
    { title: "硬件配置类型", key: "hardwareConfigTypeStr", span: 16 },
    // {title: "客服周期内服务次数", key: "", span: 8},
    // {title: "周期内线下运维次数", key: "serviceCount", span: 8},
    //33
    // {title: "周期内车队走访次数", key: "", span: 8},
    //安装设备信息
  ];
  const basicCarInfo = showCarInfo.slice(0, 5); //车队基础信息
  const teamCarInfo = showCarInfo.slice(5, 11); //车队管理信息
  const contractCarInfo = showCarInfo.slice(11, 29); //合同管理信息
  const deviceCarInfo = showCarInfo.slice(29, 44); //安装设备信息

  //运维状态关闭的方法
  const anewSearch1 = () => {
    setEditServiceState({});
    form.submit();
  };

  //编辑运维状态
  const editServiceStatebtn = async () => {
    // const params = {
    //   carId: carInfo.carId
    // }
    // const res = await carListRequest(params as CarListRequestParams);
    // const carDetail = res.data?.data || {}

    setEditServiceState({ type: "editServiceState", data: carInfo });
  };

  // 编辑客户配合度
  const editUserCoStatebtn = () => {
    setEditServiceState({type: 'coordinateAbility', data: carInfo})
  }

  //切换地图显示开关之后的操作
  const onChangeGpsType = (value: boolean) => {
    setIsAdasMap(value);
  };
  useEffect(() => {
    const carId = carInfo.carId;
    getCarLocation(carInfo);
  }, [isAdasMap, carInfo]);

  return (
    <Card bordered={false} className={styles.carDiagnose}>
      <Form
        onFinish={onSearch}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={24} wrap>
          <Col lg={4} xs={14}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col lg={4} xs={14}>
            <Form.Item label="sn" name="sn">
              <Input placeholder="请输入sn号" />
            </Form.Item>
          </Col>
          <Col lg={5} xs={14}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder="请输入车架号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col lg={6} xs={14}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[
                dayjs().subtract(7, "day"),
                dayjs().subtract(1, "day"),
              ]}
            >
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={3} xs={14}>
            <Form.Item label="  ">
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button
                    disabled={!carInfo?.carId}
                    onClick={() => setRecordVisible(true)}
                  >
                    维保记录
                  </Button>
                  <PermissionButton
                    disabled={!carInfo?.carId}
                    onClick={() => setUnderwritingRecordVisible(true)}
                    operateType="/car-admin/car-diagnosis/underwriting-recoed"
                  >
                    承保记录
                  </PermissionButton>
                  <PermissionButton
                    disabled={!carInfo?.carId}
                    onClick={() => setContractRecordVisible(true)}
                    operateType="/car-admin/car-diagnosis/contract-recoed"
                  >
                    合同记录
                  </PermissionButton>
                  <PermissionButton
                    disabled={!carInfo?.carId}
                    onClick={() => setCarProceedRecordVisible(true)}
                    operateType="/car-admin/car-diagnosis/carProceed-recoed"
                  >
                    诉讼记录
                  </PermissionButton>
                  <PermissionButton
                    disabled={!carInfo?.carId}
                    onClick={() => setInsurePayRecordVisible(true)}
                    operateType="/car-admin/car-diagnosis/pay-recode"
                  >
                    保费支付记录
                  </PermissionButton>
                  <PermissionButton
                    disabled={!carInfo?.carId}
                    onClick={() => setCostRecordVisible(true)}
                    operateType="/car-admin/car-diagnosis/cost-recoed"
                  >
                    收费记录
                  </PermissionButton>
                  <PermissionButton
                    disabled={!carInfo?.carId}
                    onClick={() => setSurveyRecoedVisible(true)}
                    operateType="/car-admin/car-diagnosis/survey-recoed"
                  >
                    问卷调查记录
                  </PermissionButton>
                  <PermissionButton
                    disabled={!carInfo?.plate_number}
                    onClick={() => setServiceRecordVisible(true)}
                    operateType="/car-admin/car-diagnosis/service-recoed"
                  >
                    客服记录
                  </PermissionButton>
                  <Button
                    disabled={!carInfo?.carId}
                    onClick={() => setParameterConfigVisible(true)}
                  >
                    参数版本
                  </Button>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          visibility: hasSearch ? "visible" : "hidden",
          minHeight: "250px",
        }}
      >
        <Row>
          <Col span={12} className={styles.carInfoBox}>
            <Row wrap gutter={12}>
              <>
                <Col span="24">
                  <Divider
                    dashed
                    orientation="left"
                    style={{ borderColor: "#000000d9" }}
                  >
                    行驶证信息
                  </Divider>
                </Col>
                {basicCarInfo.map(renderCarInfoDom)}
              </>
              <>
                <Col span="24">
                  <Divider
                    dashed
                    orientation="left"
                    style={{ borderColor: "#000000d9" }}
                  >
                    联系信息
                  </Divider>
                </Col>
                {teamCarInfo.map(renderCarInfoDom)}
              </>
              {canViewContract && (
                <>
                  <Col span="24">
                    <Divider
                      dashed
                      orientation="left"
                      style={{ borderColor: "#000000d9" }}
                    >
                      业务信息
                    </Divider>
                  </Col>
                  {contractCarInfo.map(renderCarInfoDom)}
                </>
              )}
              {canViewDevice && (
                <>
                  <Col span="24">
                    <Divider
                      dashed
                      orientation="left"
                      style={{ borderColor: "#000000d9" }}
                    >
                      设备信息
                    </Divider>
                  </Col>
                  {deviceCarInfo.map(renderCarInfoDom)}
                </>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isMultipleDevice && (
                <div style={{ display: "flex", padding: "12px" }}>
                  <span style={{ marginRight: "20px" }}>地图</span>
                  <Switch
                    className={styles.switchTitle}
                    checkedChildren="ADAS"
                    unCheckedChildren="AEB"
                    defaultChecked={isAdasMap}
                    onChange={onChangeGpsType}
                  />
                </div>
              )}
              <div style={{ height: isMultipleDevice ? "95%" : "100%" }}>
                <div id="carMap" className={styles.carMap}></div>
              </div>
            </div>
          </Col>
        </Row>

        {
          // 有设备id的情况下说明有aeb设备，显示诊断表格
          carInfo.deviceId && (
            <CarDiagnosisTable
              isKls={isKls}
              isKlsV3={isKlsV3}
              diagnosisSearchParams={diagnosisSearchParams}
              hasModem={Boolean(carInfo?.hasModem)}
              hasJtDevice={Boolean(carInfo?.jt808DeviceId)}
            />
          )
        }
        {
          // 有设备id的情况下说明有adas设备，显示adas诊断表格
          // tly4.0多显示一列需要特殊处理
          carInfo.jt808DeviceId && (
            <CarAdasTable
              plateNumber={plateNumber}
              diagnosisSearchParams={diagnosisSearchParams}
              isTly40={carInfo.deviceType === DeviceTypeEnum.junchi1}
            />
          )
        }
      </div>
      {/* 维保记录 */}
      {recordVisible && (
        <MaintenanceRecord
          carInfo={carInfo}
          onClose={() => setRecordVisible(false)}
        />
      )}
      {/* 承保记录 */}
      {underwritingRecordVisible && (
        <UnderwritingRecord
          carInfo={carInfo}
          onClose={() => setUnderwritingRecordVisible(false)}
        />
      )}
      {/* 合同记录 */}
      {contractRecordVisible && (
        <ContractRecoed
          carInfo={carInfo}
          onClose={() => setContractRecordVisible(false)}
        />
      )}
      {/* 诉讼记录 */}
      {carProceedRecordVisible && (
        <CarProceedRecoed
          carInfo={carInfo}
          onClose={() => setCarProceedRecordVisible(false)}
        />
      )}
      {/* 保单收费记录 */}
      {insurePayRecordVisible && (
        <InsurePayRecord
          carInfo={carInfo}
          onClose={() => setInsurePayRecordVisible(false)}
        />
      )}
      {/* 收费记录 */}
      {costRecordVisible && (
        <CostRecord
          carInfo={carInfo}
          onClose={() => setCostRecordVisible(false)}
        />
      )}
      {/* 客服记录 */}
      {serviceRecordVisible && (
        <ServiceRecoed
          carInfo={carInfo}
          onClose={() => setServiceRecordVisible(false)}
        />
      )}
      {/* 问卷调查记录 */}
      {surveyRecoedVisible && (
        <SurveyRecoed
          carInfo={carInfo}
          onClose={() => setSurveyRecoedVisible(false)}
        />
      )}

      {parameterConfigVisible && (
        <ParameterConfigModal
          data={carInfo}
          closeModal={() => setParameterConfigVisible(false)}
        />
      )}
      {operateInfo === "paramInfo" && (
        <ParamsModal data={carInfo} closeModal={() => setOperateInfo("")} />
      )}
      {operateInfo === "versionInfo" && (
        <VersionModal data={carInfo} closeModal={() => setOperateInfo("")} />
      )}
      {searchValues && (
        <CarListModal
          desc="当前搜索匹配到多辆车辆，点击车牌可进行精准搜索"
          closeModal={() => setSearchValues(null)}
          searchValues={searchValues}
          plateNumberClick={(data: any) => plateNumberClick(data)}
        />
      )}
      {isVehicleStateEditModalOpen && (
        <EditVehicleStateModal
          data={carInfo}
          onClose={() => setIsVehicleStateEditModalOpen(false)}
          onOk={() => {
            form.submit(); //让搜索form重新搜索
            setIsVehicleStateEditModalOpen(false);
          }}
        />
      )}
      {isAdasStateEditModalOpen && (
        <EditDeviceStateModal
          data={carInfo}
          type={2}
          onOk={() => {
            form.submit(); //让搜索form重新搜索
            setIsAdasStateEditModalOpen(false);
          }}
          onClose={() => setIsAdasStateEditModalOpen(false)}
        />
      )}
      {isAebStateEditModalOpen && (
        <EditDeviceStateModal
          data={carInfo}
          type={1}
          onOk={() => {
            form.submit(); //让搜索form重新搜索
            setIsAdasStateEditModalOpen(false);
          }}
          onClose={() => setIsAebStateEditModalOpen(false)}
        />
      )}
      {/*运维备注*/}
      {editServiceState?.type === "editServiceState" && (
        <EditServiceState
          data={editServiceState.data}
          onClose={() => setEditServiceState({})}
          onOk={anewSearch1}
          type={editServiceState.type}
        />
      )}
      {/*运维备注*/}
      {(editServiceState?.type === "editServiceState" || editServiceState?.type === "coordinateAbility") && (
        <EditServiceState
          data={editServiceState.data}
          onClose={() => setEditServiceState({})}
          onOk={anewSearch1}
          type={editServiceState.type}
        />
      )}
      {isConcatModal && <EditPhoneModal data={carInfo} type="edit" onClose={() => {setIsConcatModal("")}} onOk={() => {form.submit(); setIsConcatModal("")}} />}
      {isConcatTeamModal && <OperateTeamModal data={carInfo}  closeModal={() => {setIsConcatTeamModal("")}} onOk={() => {form.submit(); setIsConcatTeamModal("")}} />}
    </Card>
  );
};

export default CarDiagnosis;
