/* eslint-disable react/jsx-key */
/**
 * 部标机设备报警对比
 */
import React, { useState } from 'react';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Select,
  Input,
  Switch,
  InputNumber,
  TreeSelect,
  message
} from 'antd';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import DatePicker from '@/components/datePicker';
import AlarmInfoModal from '@/components/alarmInfoModal';
import VideoPreviewModal from '@/components/videoPreviewModal';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import LoadingWrapper from '@/components/loadingWrapper';
import PermissionButton from '@/components/permissionButton';
import { formatTime } from '@/utils/formatTime';
import { getAlarmTypeDisplay } from '@/utils/alarm';
import {
  displayJtDeviceAlarm,
  displayJtDeviceInfo
} from '@/utils/jtDeviceAlarm';
import { changeUrlProtocol } from '@/utils/common';
import { ownRound } from '@/utils/math';
import { formSearchGutter } from '@/constants/common';
import {
  jtDeviceAlarmDirectionData,
  alarmCompareIntervalData,
  alarmCompareTableTitle,
  alarmCompareDeviceType,
  compareDeviceTypeEnum,
  alarmCompareDeviceCount,
  driveBehaviorDirectionData
} from '@/constants/jtDeviceAlarmCompare';
import { driveBehaviorAlarmValue } from '@/constants/driveBehavior';
import { driveAnalysisRequest } from '@/service/driveAnalysis';
import { jtDeviceAlarmListRequest } from '@/service/jtDeviceAlarm';
import JtDeviceAlarmOperateColumn from '@/pages/jtDeviceAlarm/components/jtDeviceAlarmOperateColumn';
import EvaluteModal from './components/evaluteModal';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';

const { RangePicker } = DatePicker;
const JtDeviceAlarmCompare = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // 表格的laoding
  const [data, setData] = useState<any>([]); // 表格的数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储
  const [evaluteData, setEvaluteData] = useState<any>(null); // 总结的数据
  const [searchValues, setSearchValues] = useState<any>({}); // 搜索参数
  const [exportLoading, setExportLoading] = useState<boolean>(false); // 导出按钮的loading

  // 获取请求参数和方法
  const getRequestParams = (type: number, order: string, searchParams: any) => {
    const {
      beginTime,
      endTime,
      plateNumber1,
      plateNumber2,
      dataType1,
      dataType2
    } = searchParams;
    let requestParams = {};
    let requestFunc = null;
    const matchPlateNumber = order === 'first' ? plateNumber1 : plateNumber2;
    const matchDataType = order === 'first' ? dataType1 : dataType2;
    if (type === compareDeviceTypeEnum.aeb) {
      // AEB设备
      requestParams = {
        page: 1,
        limit: 99999,
        beginDate: beginTime,
        endDate: endTime,
        plateNumber: matchPlateNumber,
        type: driveBehaviorAlarmValue
      };
      requestFunc = driveAnalysisRequest;
    } else {
      // 部标机设备
      requestParams = {
        page: 1,
        limit: 99999,
        start: beginTime,
        end: endTime,
        plateNumber: matchPlateNumber,
        alarmTypes: matchDataType
          .map((item: string) => item.split(','))
          .flat(Infinity)
      };
      requestFunc = jtDeviceAlarmListRequest;
    }
    return { requestParams, requestFunc };
  };

  // 处理接口请求回来的数据
  const operateRequestData = (
    type: number,
    order: string,
    searchParams: any,
    data: any,
    resultData: any
  ) => {
    const { dataType1, dataType2, fixTime } = searchParams;
    if (type === compareDeviceTypeEnum.aeb) {
      const matchDataType = order === 'first' ? dataType1 : dataType2;
      // 遍历AEB报警数据
      data.forEach((aeb: any) => {
        const displayAlarmType = getAlarmTypeDisplay(aeb);
        const formatAebType = matchDataType
          .map((item: string) => item.split(','))
          .flat(Infinity);
        if (formatAebType.includes(displayAlarmType)) {
          aeb.displayAlarmType = displayAlarmType;
          let displayReportTime = aeb.reportedTime;
          if (fixTime && order === 'second') {
            displayReportTime = formatTime(
              dayjs(displayReportTime).add(fixTime, 'second')
            );
          }
          aeb.displayReportTime = displayReportTime;
          aeb.ownType = 'aeb'; // 区分是部标机还是aeb
          aeb.order = order; // 区分设备一还是设备二
          aeb.valid = 1; // 默认报警结果为正常 1:正常 0:误报
          resultData.push(aeb);
        }
      });
    } else {
      // 遍历部标机设备报警数据
      data.reverse().forEach((jtDevice: any) => {
        let displayReportTime = displayJtDeviceInfo(
          jtDevice,
          'time1',
          'reportedAt',
          true
        );
        if (fixTime && order === 'second') {
          displayReportTime = formatTime(
            dayjs(displayReportTime).add(fixTime, 'second')
          );
        }
        jtDevice.displayReportTime = displayReportTime;
        jtDevice.displayAlarmType = displayJtDeviceAlarm(jtDevice.contentMap);
        jtDevice.vehicleSpeed = displayJtDeviceInfo(jtDevice, 'speed', 'speed');
        jtDevice.ownType = 'jtDevice'; // 区分是部标机还是aeb
        jtDevice.order = order; // 区分设备一还是设备二
        jtDevice.valid = 1; // 默认报警结果为正常 1:正常 0:误报
        resultData.push(jtDevice);
      });
    }
  };

  // 将数据按对齐间隔进行分组
  const groupResultData = (resultData: any, interval: number) => {
    const sortData = sortBy(resultData, item => dayjs(item.displayReportTime));
    const groupData: any = [];
    sortData.reduce((prev: any, curr: any, currentIndex: number) => {
      const currTime = dayjs(curr.displayReportTime);
      if (
        prev?.length &&
        currTime.diff(prev[0].displayReportTime, 'second') <= interval
      ) {
        prev.push(curr);
      } else if (
        prev?.length &&
        currTime.diff(prev[0].displayReportTime, 'second') > interval &&
        currTime.diff(prev[prev.length - 1].displayReportTime, 'second') <=
          interval &&
        sortData[currentIndex + 1] &&
        Math.abs(
          currTime.diff(sortData[currentIndex + 1].displayReportTime, 'second')
        ) > interval
      ) {
        groupData.push([prev[prev.length - 1], curr]);
        prev.splice(prev.length - 1, 1);
      } else {
        prev = [curr];
        groupData.push(prev);
      }
      return prev;
    }, []);
    setData(groupData);
  };

  // 搜索
  const onSearch = async (values: any) => {
    try {
      setLoading(true);
      const params = { ...values };
      delete params.time;
      params.beginTime = formatTime(values.time[0]);
      params.endTime = formatTime(values.time[1]);
      const { type1, type2 } = params;
      const { requestParams: fisrtParams, requestFunc: fisrtRequest } =
        getRequestParams(type1, 'first', params);
      const { requestParams: secondParams, requestFunc: secondRequest } =
        getRequestParams(type2, 'second', params);
      const res = await Promise.all([
        fisrtRequest(fisrtParams as any),
        secondRequest(secondParams as any)
      ]);
      const firstData = res[0].data?.data || [];
      const secondData = res[1].data?.data || [];
      const resultData: any = []; // 用于渲染的二维数组
      operateRequestData(type1, 'first', params, firstData, resultData);
      operateRequestData(type2, 'second', params, secondData, resultData);
      setSearchValues(params);
      setLoading(false);
      groupResultData(resultData, params.interval);
      document
        .getElementById('compareTable')
        ?.firstElementChild?.scrollTo(0, 0);
    } catch (error) {
      setLoading(false);
    }
  };

  // 总结
  const onEvalute = (returnData?: boolean) => {
    if (data?.length) {
      // 计算每个时间区域的正常、误报、漏报数量
      const alarmIntervalBlockSummary = (alarmIntervalBlock: any) => {
        let firstNormal = 0,
          firstError = 0,
          firstLoss = 0,
          secondNormal = 0,
          secondError = 0,
          secondLoss = 0,
          firstCount = 0, // 设备一数据数量
          secondCount = 0, // 设备二数据数量
          matchCount = 0; // 匹配数量
        alarmIntervalBlock.forEach((item: any) => {
          if (item.order === 'first') {
            item.valid ? firstNormal++ : firstError++;
            firstCount++;
          } else if (item.order == 'second') {
            item.valid ? secondNormal++ : secondError++;
            secondCount++;
          }
        });
        const diff = firstNormal - secondNormal;
        if (diff > 0) {
          secondLoss = Math.abs(diff) - firstError;
        } else if (diff < 0) {
          firstLoss = Math.abs(diff) - secondError;
        }
        // 添加匹配的数量
        if (firstCount && secondCount) {
          matchCount += Math.min(firstCount, secondCount);
        }
        return {
          firstNormal,
          firstError,
          firstLoss,
          secondNormal,
          secondError,
          secondLoss,
          firstCount,
          secondCount,
          matchCount
        };
      };
      const evaluteData: any = {
        firstNormal: 0,
        firstError: 0,
        firstLoss: 0,
        secondNormal: 0,
        secondError: 0,
        secondLoss: 0,
        firstCount: 0,
        secondCount: 0,
        matchCount: 0
      };
      data.forEach((group: any) => {
        if (group) {
          const {
            firstNormal,
            firstError,
            firstLoss,
            secondNormal,
            secondError,
            secondLoss,
            firstCount,
            secondCount,
            matchCount
          } = alarmIntervalBlockSummary(group);
          evaluteData.firstNormal += firstNormal;
          evaluteData.firstError += firstError;
          evaluteData.firstLoss += firstLoss;
          evaluteData.secondNormal += secondNormal;
          evaluteData.secondError += secondError;
          evaluteData.secondLoss += secondLoss;
          evaluteData.firstCount += firstCount;
          evaluteData.secondCount += secondCount;
          evaluteData.matchCount += matchCount;
        }
      });
      // 获取匹配率
      const macthRate = `${ownRound(
        (evaluteData.matchCount * 200) /
          (evaluteData.firstCount + evaluteData.secondCount),
        2
      )}%`;
      evaluteData.macthRate = macthRate;
      if (returnData) {
        return evaluteData;
      } else {
        setEvaluteData(evaluteData);
      }
    }
  };

  // 是否误报的评价
  const onCheck = (
    checked: boolean,
    alarmIntervalBlockIndex: number,
    alarmIndex: number
  ) => {
    const newData: any = [...data];
    newData[alarmIntervalBlockIndex][alarmIndex].valid = checked ? 1 : 0;
    setData(newData);
  };

  // 渲染表格每一列（区分AEB设备和部标机设备的显示）
  const renderTableItem = (
    alarmItem: any,
    alarmIntervalBlockIndex: number,
    alarmIndex: number
  ) => {
    const { ownType } = alarmItem;
    const isAeb = ownType === 'aeb'; // 是否为AEB设备
    return (
      <div className={ownStyles.tableRow}>
        <div className={`${ownStyles.tableCell} ${ownStyles.type}`}>
          <RenderEllipsisColumn text={alarmItem.displayAlarmType} />
        </div>
        <div className={`${ownStyles.tableCell} ${ownStyles.time}`}>
          <RenderEllipsisColumn text={alarmItem.displayReportTime} />
        </div>
        <div className={`${ownStyles.tableCell} ${ownStyles.speed}`}>
          {alarmItem.vehicleSpeed}
        </div>
        <div className={`${ownStyles.tableCell} ${ownStyles.operate}`}>
          {isAeb ? (
            <div className={styles.tableOperateColumn}>
              {alarmItem.info && (
                <Button
                  type="link"
                  onClick={() => {
                    setOperateInfo({
                      type: 'alarm',
                      data: alarmItem
                    });
                  }}
                >
                  详细信息
                </Button>
              )}
              {Boolean(alarmItem.hasAttach) && (
                <Button
                  type="link"
                  onClick={() =>
                    setOperateInfo({
                      type: 'video',
                      data: alarmItem
                    })
                  }
                >
                  播放
                </Button>
              )}
            </div>
          ) : (
            <JtDeviceAlarmOperateColumn alarmData={alarmItem} />
          )}
        </div>
        <div className={`${ownStyles.tableCell} ${ownStyles.evalute}`}>
          <Switch
            checkedChildren="正常"
            unCheckedChildren="误报"
            checked={alarmItem.valid === 1}
            size="small"
            onChange={(checked: boolean) =>
              onCheck(checked, alarmIntervalBlockIndex, alarmIndex)
            }
          />
        </div>
      </div>
    );
  };

  // 导出
  const onExport = async () => {
    try {
      setExportLoading(true);
      const xlsx = await import('xlsx');
      const {
        beginTime,
        endTime,
        plateNumber1,
        plateNumber2,
        type1,
        type2,
        fixTime,
        interval
      } = searchValues;
      const {
        firstNormal,
        firstError,
        firstLoss,
        secondNormal,
        secondError,
        secondLoss,
        firstCount,
        secondCount,
        matchCount,
        macthRate
      } = onEvalute(true);
      // sheet1统计数据
      const statsData = [
        ['开始时间', '结束时间', '设备二时间修正', '事件对齐间隔'],
        [beginTime, endTime, fixTime ? `${fixTime}秒` : 0, `${interval}秒`],
        [],
        ['设备一数据总数', '设备二数据总数', '数据匹配次数', '匹配数据占比'],
        [firstCount, secondCount, matchCount, macthRate],
        [],
        ['', '车牌号', '设备类型', '正常', '误报', '漏报'],
        [
          '设备一',
          plateNumber1,
          type1 === compareDeviceTypeEnum.aeb ? 'AEB设备' : '部标机设备',
          firstNormal,
          firstError,
          firstLoss
        ],
        [
          '设备二',
          plateNumber2,
          type2 === compareDeviceTypeEnum.aeb ? 'AEB设备' : '部标机设备',
          secondNormal,
          secondError,
          secondLoss
        ]
      ];
      const compareData = [
        [
          '数据类型',
          '时间',
          '速度',
          '评价',
          '',
          '数据类型',
          `时间(修正)(+${fixTime || 0}s)`,
          '速度',
          '评价'
        ]
      ];
      data.forEach((groupData: any) => {
        if (groupData?.length) {
          const groupExportData: any = [];
          const groupFirstData: any = [];
          const groupSecondData: any = [];
          groupData.forEach((item: any) => {
            if (item.order === 'first') {
              groupFirstData.push(item);
            } else {
              groupSecondData.push(item);
            }
          });
          groupFirstData.forEach((item: any, index: number) => {
            const { displayAlarmType, displayReportTime, vehicleSpeed, valid } =
              item;
            groupExportData[index] = [];
            groupExportData[index][0] = displayAlarmType;
            groupExportData[index][1] = displayReportTime;
            groupExportData[index][2] = vehicleSpeed;
            groupExportData[index][3] = valid ? '正常' : '误报';
            groupExportData[index][4] = '';
          });
          groupSecondData.forEach((item: any, index: number) => {
            const { displayAlarmType, displayReportTime, vehicleSpeed, valid } =
              item;
            if (groupExportData[index]) {
              groupExportData[index][5] = displayAlarmType;
              groupExportData[index][6] = displayReportTime;
              groupExportData[index][7] = vehicleSpeed;
              groupExportData[index][8] = valid ? '正常' : '误报';
            } else {
              groupExportData[index] = [
                '',
                '',
                '',
                '',
                '',
                displayAlarmType,
                displayReportTime,
                vehicleSpeed,
                valid ? '正常' : '误报'
              ];
            }
          });
          compareData.push(...groupExportData);
        }
      });
      const wb = xlsx.utils.book_new();
      const ws1 = xlsx.utils.aoa_to_sheet(statsData);
      xlsx.utils.book_append_sheet(wb, ws1, 'Sheet1');
      const ws2 = xlsx.utils.aoa_to_sheet(compareData);
      xlsx.utils.book_append_sheet(wb, ws2, 'Sheet1');
      xlsx.writeFile(wb, '报警对比.xlsx');
      message.success('导出成功');
      setExportLoading(false);
    } catch (error) {
      console.log(error);
      message.error('导出失败');
      setExportLoading(false);
    }
  };

  // 设备类型修改置空数据类型
  const onTypeChange = (value: number) => {
    form.setFieldValue(`dataType${value}`, []);
  };

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form
        onFinish={onSearch}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={formSearchGutter}>
          {alarmCompareDeviceCount.map((item: any) => {
            const { label, value } = item;
            return (
              <Col span={12} key={label}>
                <div className={ownStyles.formDeviceTitle}>{label}</div>
                <Row
                  gutter={formSearchGutter}
                  className={ownStyles.formDeviceBlock}
                >
                  <Col span={8}>
                    <Form.Item
                      label="车牌号"
                      name={`plateNumber${value}`}
                      rules={[{ required: true, message: '请输入车牌号' }]}
                    >
                      <Input placeholder="请输入车牌号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="设备类型"
                      name={`type${value}`}
                      rules={[{ required: true, message: '请选择设备类型' }]}
                    >
                      <Select
                        options={alarmCompareDeviceType}
                        placeholder="请选择设备类型"
                        onChange={() => onTypeChange(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item noStyle shouldUpdate>
                      {() => (
                        <Form.Item
                          label="数据类型"
                          name={`dataType${value}`}
                          rules={[
                            { required: true, message: '请选择数据类型' }
                          ]}
                        >
                          <TreeSelect
                            allowClear
                            showCheckedStrategy="SHOW_PARENT"
                            maxTagCount="responsive"
                            treeCheckable
                            style={{ width: '100%' }}
                            treeData={
                              form.getFieldValue(`type${value}`) ===
                              compareDeviceTypeEnum.aeb
                                ? driveBehaviorDirectionData
                                : jtDeviceAlarmDirectionData
                            }
                            treeDefaultExpandAll
                            placeholder="请选择数据类型"
                            dropdownMatchSelectWidth={false}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
        <Row gutter={formSearchGutter}>
          <Col span={12}>
            <Form.Item
              label="时间范围"
              name="time"
              rules={[{ required: true, message: '请选择时间范围' }]}
            >
              <RangePicker style={{ width: '100%' }} showTime />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="设备二时间修正(s)" name="fixTime">
              <InputNumber
                placeholder="请输入设备二时间修正(s)"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="事件对齐间隔" name="interval" initialValue={3}>
              <Select
                options={alarmCompareIntervalData}
                placeholder="请选择事件对齐间隔"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <Button onClick={() => onEvalute()}>总结</Button>
                <PermissionButton
                  operateType="/car-admin/dvr-alarm-compare/export"
                  onClick={onExport}
                  loading={exportLoading}
                >
                  导出
                </PermissionButton>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={ownStyles.headerBlock}>
        {alarmCompareTableTitle(searchValues.fixTime || 0).map(
          (titleGroupItem: any) => (
            <div
              className={ownStyles[titleGroupItem.className]}
              key={titleGroupItem.title}
            >
              <div className={ownStyles.headerGroupTitle}>
                {titleGroupItem.title}
              </div>
              <div className={ownStyles.headerTitle}>
                {titleGroupItem.children.map((titleItem: any) => (
                  <div
                    className={`${ownStyles.title} ${
                      ownStyles[titleItem.className]
                    }`}
                    key={titleItem.title}
                  >
                    {titleItem.title}
                  </div>
                ))}
              </div>
            </div>
          )
        )}
        <div className={ownStyles.scrollHeader} />
      </div>
      <LoadingWrapper
        spinning={loading}
        autoHeight
        wrapperClassName={ownStyles.contentBlock}
        id="compareTable"
      >
        {data.map(
          (alarmIntervalBlock: any, alarmIntervalBlockIndex: number) => {
            if (alarmIntervalBlock?.length) {
              return (
                <div className={ownStyles.alarmIntervalBlock}>
                  <div className={ownStyles.leftPart}>
                    {alarmIntervalBlock.map(
                      (alarmItem: any, alarmIndex: number) => {
                        const { order } = alarmItem;
                        if (order === 'first') {
                          return renderTableItem(
                            alarmItem,
                            alarmIntervalBlockIndex,
                            alarmIndex
                          );
                        }
                      }
                    )}
                  </div>
                  <div className={ownStyles.rightPart}>
                    {alarmIntervalBlock.map(
                      (alarmItem: any, alarmIndex: number) => {
                        const { order } = alarmItem;
                        if (order === 'second') {
                          return renderTableItem(
                            alarmItem,
                            alarmIntervalBlockIndex,
                            alarmIndex
                          );
                        }
                      }
                    )}
                  </div>
                </div>
              );
            }
          }
        )}
      </LoadingWrapper>
      {operateInfo.type === 'alarm' && (
        <AlarmInfoModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          infoKey="info"
        />
      )}
      {operateInfo.type === 'video' && (
        <VideoPreviewModal
          url={changeUrlProtocol(operateInfo.data?.attachUrl)}
          fileName={`${operateInfo.data?.reportedTime}  ${getAlarmTypeDisplay(
            operateInfo.data
          )}`}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 评价总结弹窗 */}
      {evaluteData && (
        <EvaluteModal data={evaluteData} onClose={() => setEvaluteData(null)} />
      )}
    </Card>
  );
};

export default JtDeviceAlarmCompare;
