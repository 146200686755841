export enum DeviceTypeEnum {
  huanghe1 = 1,
  huanghe2 = 3,
  huanghe3 = 4,
  adas = 5,
  kunlunshan1 = 2,
  kunlunshan2 = 6,
  kunlunshan3 = 7,
  huanghe4 = 8,
  kunlunshan4 = 9,
  adas1 = 10,
  adas2 = 11,
  adas3 = 12,
  kunlunshan5 = 13,
  junchi1 = 14,
  new1 = 15,
  new2 = 16,
  new3 = 17
}

export interface DeviceType {
  label: string;
  value: Number;
}
export interface stateType {
  label: string;
  value: string;
}

// 车辆类型
export const carTypeData: Array<DeviceType> = [
  {label: '自卸车', value: 1},
  {label: '牵引车', value: 2},
  {label: '商砼车', value: 3},
  {label: '消防车', value: 4},
  {label: '拖车', value: 5},
  {label: '未确认', value: 6},
];

// 设备类型数据
export const deviceTypeData: Array<DeviceType> = [
  {label: 'TLY0.6', value: DeviceTypeEnum.new1},//15
  {label: 'TLY1.1', value: DeviceTypeEnum.huanghe1}, //1
  {label: 'TLY1.2', value: DeviceTypeEnum.huanghe2}, //3
  {label: 'TLY1.3', value: DeviceTypeEnum.huanghe3},//4
  {label: 'TLY1.4', value: DeviceTypeEnum.huanghe4},//8
  {label: 'TLY1.6', value: DeviceTypeEnum.new2},//16
  {label: 'TLY2.0', value: DeviceTypeEnum.kunlunshan1},//2
  {label: 'TLY2.2', value: DeviceTypeEnum.kunlunshan2},//6
  {label: 'TLY2.3', value: DeviceTypeEnum.kunlunshan3},//7
  {label: 'TLY2.4', value: DeviceTypeEnum.kunlunshan4},//9
  {label: 'TLY0.5', value: DeviceTypeEnum.adas},//5
  {label: 'TLY0.4', value: DeviceTypeEnum.adas1},//10
  {label: '博富CBS2.0', value: DeviceTypeEnum.adas2},//11
  {label: 'TLY3.6', value: DeviceTypeEnum.new3},//17
  {label: 'TLY3.4', value: DeviceTypeEnum.adas3},//12
  {label: 'TLY3.0', value: DeviceTypeEnum.kunlunshan5},//13
  {label: 'TLY4.0', value: DeviceTypeEnum.junchi1},//14
];

// 硬件配置类型
export const deviceConfigData: Array<DeviceType> = [
  {label: '行车记录仪', value: 1},
  {label: '声光报警器', value: 2},
  {label: '前毫米波', value: 3},
  {label: '超声波雷达', value: 4},
  {label: 'IMU', value: 5},
  {label: '右角雷达', value: 6},
];

// export const aebDeviceTypeData = [
//   {label:'黄河设备',value:1},
//   {label:'昆仑山设备',value:2},
//   {label:'部标机设备',value:3},
// ]
// 车辆类型
export const carType = (value: any) => {
  if (value == 1) {
    return '自卸车'
  } else if (value == 2) {
    return '牵引车'
  } else if (value == 3) {
    return '商砼车'
  } else if (value == 4) {
    return '消防车'
  } else if (value == 5) {
    return '拖车'
  } else if (value == 7) {
    return '货车'
  }
}


// 车辆状态
export const carState: Array<stateType> = [
  {label: '正常', value: '正常' },
  {label: '停用', value: '停用'},
  {label: '故障', value: '故障'},
];
// 设备状态
export const deviceState: Array<stateType> = [
  {label: '正常', value: '正常'},
  {label: '停用', value: '停用'},
  {label: '故障', value: '故障'},
];
// 设备类型大类
export enum DeviceCategoryEnum {
  huanghe = 1,
  kunlunshan = 2,
  kunlunshanV3 = 3,
  changxing = 4,
  adas = 5
}

// 设备类型大类数据
export const deviceCategoryData: Array<DeviceType> = [
  {label: '黄河设备', value: DeviceCategoryEnum.huanghe},
  {label: '昆仑山设备', value: DeviceCategoryEnum.kunlunshan},
  {label: '昆仑山3.0设备', value: DeviceCategoryEnum.kunlunshanV3},
  {label: '视频机', value: DeviceCategoryEnum.adas}
];

export const devicveDelStatusData: Array<{[key:string]: string}> = [
  {label: '卖车拆除', value: '卖车拆除'},
  {label: '拒保拆除', value: '拒保拆除'},
  {label: '迁移拆除', value: '迁移拆除'},
  {label: '满期拆除', value: '满期拆除'},
  {label: '退出拆除', value: '退出拆除'},
  {label: '其它拆除', value: '其它拆除'},
];
