/**
 * 报价记录相关请求
 */
import request from "@/utils/request";

export interface PremiumListParams {
  page: number,
  limit: number;
  licenseNo?: any;
  carVin?: any;
  vehicleType?: any;
  areaCode?: any;
  cityCode?: any;
  carOwnerName?: any;
  carOwnerDepartment?: any;
  carOwnersName?: any;
  fuelType?: any;
  deviceType?: any;
  day?: any;
}

// 获取操作记录类型
export const premiumListRequestPath = '/api/v2/car/premium/list';
export const premiumListRequest = (params: PremiumListParams) => {
  return request.get(premiumListRequestPath, {
    params,
  })
}

// 获取城市
export const areaListRequestPath = '/api/v2/car/area/all/list';
export const areaListRequest = () => {
  return request.get(areaListRequestPath)
}


// 获取城市
export const carTypeListRequestPath = '/api/v2/car/insure/carType/list';
export const carTypeListRequest = () => {
  return request.get(carTypeListRequestPath)
}

// 新增报价记录
export const addPremiumRequestPath = '/api/v2/car/premium/upsert';
export const addPremiumRequest = (data: any) => {
  return request.post(addPremiumRequestPath, data)
}
//批量导入报价记录
export const importPremiumRequestPath = '/api/v2/car/premium/import';
//删除报价记录
export const deletePremiumRequestPath = '/api/v2/car/premium/'
export const deletePremiumRequest = (id: string) => {
  return request.delete(deletePremiumRequestPath + id)
}

//申请报价
export const applyPremiumRequestPath = '/api/v2/car/premium/apply'
export const applyPremiumRequest = (params: any) => {
  return request.post(applyPremiumRequestPath, {...params})
}

export const quotePath = '/api/v2/car/premium/quote'
export const quoteRequest = (params: any) => {
  return request.get(quotePath, {params})
}

//获取配置信息
export const getConfigRequestPath = '/api/v2/car/premium/price'
export const getConfigRequest = (params: any) => {
  return request.get(getConfigRequestPath, {params})
}

//获取报价失败记录
export const quotationPath = '/api/v2/quotation/list'
export const quotationRequest = (params: any) => {
  return request.get(quotationPath, {params})
}

