/**
 * 工单
 */
import React, { Fragment, useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Select, Input, Popconfirm, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { workOrderListRequest, workOrderListRequestPath, deleteWorkOrderRequest, confirmWorkOrderRequest, workOrderDetailRequest } from '@/service/workOrder';
import { userListRequest } from '@/service/userManage';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from "@/components/datePicker";
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import { operateDayRange } from '@/utils/operateSearchParams';
import { getInnerPermissionEnable } from '@/utils/permission';
import { workOrderTypeData, workOrderStatusData, WorkOrderStatusEnum, workOrderListColumns } from '@/constants/workOrder';
import WorkOrderDetail from './components/workOrderDetail';
import OperateModal from './components/operateModal';
import ReopenModal from './components/reopenModal';
import AssignModal from './components/assignModal';
import CommentModal from './components/commentModal';
import styles from '@/styles/pageContent.module.scss';
import dayjs from 'dayjs';
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';

const { RangePicker } = DatePicker;
const WorkOrder = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);
  const dispatch = useDispatch();
  // 获取接口请求的issuId参数值
  const getSearchIssuId = () => {
    if (getInnerPermissionEnable('/car-admin/work-order/work-order-super')) {
      return undefined;
    } else {
      return userDetail.id;
    }
  }

  const [searchParams, setSearchParams] = useState({
    expireBeginTime: `${dayjs().subtract(0, 'day').format('YYYY-MM-DD') + ' 00:00:00'}`,
    expireEndTime: `${dayjs().subtract(0, 'day').format('YYYY-MM-DD') + ' 23:59:59'}`,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [userData, setUserData] = useState([]); // 工单创建人员数据
  const [filterList, setFilterList] = useState<any>([]); // 筛选选项
  const [tagData, setTagData] = useState<any>([]); // 工单相关的标签
  const [vehicleState, setVehicleState] = useState<any>([]); // 车辆状态
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: workOrderListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  
  // 获取用户数据
  const getUserData = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUserData(res.data?.data || [])
  }

  useEffect(() => {
    getUserData();
    getWorkOrderTags();
  }, []);

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    let newSearchParams:any = {}
    if (params.time) {
      newSearchParams.beginTime = dayjs(params.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
      newSearchParams.endTime = dayjs(params.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
    }
    if (params.times) {
      newSearchParams.expireBeginTime = dayjs(params.times[0]).format('YYYY-MM-DD') + ' 00:00:00'
      newSearchParams.expireEndTime = dayjs(params.times[1]).format('YYYY-MM-DD') + ' 23:59:59'
    }
    if (params.plateNumber) {
      newSearchParams.plateNumber = params.plateNumber.trim();
    } else {
      newSearchParams.plateNumber = undefined;
    }
    if (params.no) {
      newSearchParams.no = params.no.trim();
    } else {
      newSearchParams.no = undefined;
    }
    if (params.stats) {
      newSearchParams.stats = params.stats
    }
    if (params.types) {
      newSearchParams.types = params.types
    }
    if (params.tag) {
      newSearchParams.tag = params.tag
    }
    if (params.tel) {
      newSearchParams.tel = params.tel.trim();
    } else {
      newSearchParams.tel = undefined;
    }
    if (isExport) {
      newSearchParams.export = true;
    }
    if (params.doubleAssign) {
      newSearchParams.doubleAssign = params.doubleAssign
    }
    newSearchParams.company = params.company
    newSearchParams.issuId = params?.issuId || getSearchIssuId();
    newSearchParams.operatorId = params?.operatorId || getSearchIssuId();
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const newSearchParams = operateSearchParams(values, true);
    newSearchParams.ignoreColumns = filterList
    return newSearchParams;
  }
  // 获取工单标签
  const getWorkOrderTags = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.maintainType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title, id, isDisabled } = item;
      tags.push({ label: title, value: id, disabled: isDisabled });
    });
    setTagData(tags);
  }

  // 删除工单
  const deleteWorkOrder = async(id: number) => {
    const res = await deleteWorkOrderRequest(id);
    if (res?.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

   // 确认工单完成并关闭工单
   const confirmWorkOrder = async(id: number) => {
    const res = await confirmWorkOrderRequest({ id });
    if (res?.data?.code === 0) {
      message.success('确认工单成功');
      getData();
    }
    //将数据管理界面的车辆状态改为待审核
    const ress = await tagListRequest({
      
    });

  }

  // 点击编辑工单
  const onWorkEdit = async(data: any) => {
    const res = await workOrderDetailRequest(data.id);
    const workDetail = res.data?.data || {};
    if ((workDetail?.events || []).find((item: any) => item.type === 'signIn')) {
      message.warning('该工单已签到无法修改工单')
    } else {
      setOperateInfo({ type: 'edit', data });
    }
  }

  const getWorkOrderId = (value: any) => {
  }

  const onPlateNumberClick = (value: any) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber:value.plateNumber,beginDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD') }
      }
    })
  }
  
  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as 'left',
      render: (value: string, item: any) => (
        <div className={styles.tableOperateColumn}>
          <Button type="link" onClick={() => onPlateNumberClick(item)}>{value}</Button>
        </div>
      )
    },
    ...workOrderListColumns,
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        // 是否隐藏编辑按钮(工单已签到)
        const hiddenEditButton = Boolean((item?.events || []).find((item: any) => item.type === 'signIn'));
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>详情</Button>
            <PermissionButton operateType='/car-admin/work-order/comment' type="link" onClick={() => setOperateInfo({ type: 'comment', data: item.id })}>备注</PermissionButton>
            {item.stat == 7 || hiddenEditButton || <PermissionButton operateType='/car-admin/work-order/edit' type="link" onClick={() => onWorkEdit(item)}>编辑</PermissionButton>}
            <Popconfirm
              title="此操作将删除该工单, 是否继续?"
              onConfirm={() => deleteWorkOrder(item.id)}
            >
              <PermissionButton operateType='/car-admin/work-order/delete' type="link">删除</PermissionButton>
            </Popconfirm>
            {/* 未分配的工单才可以指派 */}
            {item.stat === WorkOrderStatusEnum.unassign &&
              <PermissionButton operateType='/car-admin/work-order/assign' type="link" onClick={() => setOperateInfo({ type: 'assign', data: item })}>指派</PermissionButton>
            }
            {/* 已分派的工单才可以重新指派 */}
            {item.stat === WorkOrderStatusEnum.assigned &&
              <PermissionButton operateType='/car-admin/work-order/assign-reassign' type="link" onClick={() => setOperateInfo({ type: 'assign', data: item })}>重新指派</PermissionButton>
            }
            {/* 待处理的工单才可以重新指派 */}
            {item.stat === WorkOrderStatusEnum.pending &&
              <PermissionButton operateType='/car-admin/work-order/assign-reassign' type="link" onClick={() => setOperateInfo({ type: 'assign', data: item })}>重新指派</PermissionButton>
            }
            {/* 被拒绝的工单才可以重新指派 */}
            {item.stat === WorkOrderStatusEnum.refuse &&
              <PermissionButton operateType='/car-admin/work-order/reassign' type="link" onClick={() => setOperateInfo({ type: 'reassign', data: item })}>重新指派</PermissionButton>
            }
            {/* 已完工的工单才显示此操作 */}
            {item.stat === WorkOrderStatusEnum.finished && <Fragment>
              <Popconfirm
                title="确认工单完成并关闭?"
                onConfirm={() => confirmWorkOrder(item.id)}
              >
                <PermissionButton operateType='/car-admin/work-order/confirm' type="link">确认工单</PermissionButton>
              </Popconfirm>
              <PermissionButton operateType='/car-admin/work-order/reopen' type="link" onClick={() => setOperateInfo({ type: 'reopen', data: item.id })}>重开工单</PermissionButton>
            </Fragment>}
            {item.stat == 6 ? null : item.stat == 7 ? null : <Popconfirm
                title="确认工单完成并关闭?"
                onConfirm={() => confirmWorkOrder(item.id)}
              >
                <PermissionButton operateType='/car-admin/work-order/oneKeyConfirm' type="link">一键关闭</PermissionButton>
              </Popconfirm>}
          </div>
        )
      },
    },
  ];

  // 筛选列
  const changeFilter = (value:any) => {
    const filter: any[] = []
    columns.map((item:any) => {
      filter.push(item.dataIndex)
    })
    const noFilter: any[] = []
    filter.map((itemm:any) => {
      const aaa = value.indexOf(itemm)
      if (aaa == -1) {
        noFilter.push(itemm)
      }
      setFilterList(noFilter)
    })
  }

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete='off'>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="工单编号" name="no">
              <Input placeholder="请输入工单编号" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="工单创建时间"
              name="time"
            // initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="期望完成时间"
              name="times"
              initialValue={[dayjs(`${dayjs().format('YYYY-MM-DD')}`), dayjs(`${dayjs().format('YYYY-MM-DD')}`)]}
            >
              <RangePicker allowClear={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="工单类型" name="types">
              <Select
                options={workOrderTypeData}
                allowClear
                showArrow
                mode="multiple"
                placeholder="请选择工单类型"
                optionFilterProp="label"
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="工单状态" name="stats">
              <Select
                options={workOrderStatusData}
                allowClear
                showArrow
                mode="multiple"
                placeholder="请选择工单状态"
                optionFilterProp="label"
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="工单标签" name="tag">
              <Select
                options={tagData}
                allowClear
                showArrow
                mode="multiple"
                placeholder="请选择工单标签"
                optionFilterProp="label"
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="客户电话" name="tel">
              <Input placeholder="请输入客户电话" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="所属公司" name="company" >
              <Input placeholder="请输入所属公司" />
            </Form.Item>
          </Col>
          <Col span={4}>
              <Form.Item label="工单处理人" name="operatorId">
                <Select
                  placeholder="请选择工单处理人"
                  options={userData}
                  allowClear
                  showArrow
                  showSearch
                  fieldNames={{
                    label: 'nickname',
                    value: 'id'
                  }}
                  optionFilterProp="nickname"
                />
              </Form.Item>
            </Col>
          {/* 工单管理员才可见创建人的搜索项 */}
          {getInnerPermissionEnable('/car-admin/work-order/work-order-super') && 
            <Col span={4}>
              <Form.Item label="工单创建人" name="issuId">
                <Select
                  placeholder="请选择工单创建人"
                  options={userData}
                  allowClear
                  showArrow
                  showSearch
                  fieldNames={{
                    label: 'nickname',
                    value: 'id'
                  }}
                  optionFilterProp="nickname"
                />
              </Form.Item>
            </Col>
          }
          <Col span={4}>
          <Form.Item label="是否重开工单" name="doubleAssign">
              <Select  placeholder='请选择是否重开工单'
               options={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
                allowClear/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label=" ">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={workOrderListRequestPath}
                  fileName='工单列表'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/work-order/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/work-order/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          创建工单
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        changeFilter={changeFilter}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo?.type === 'detail' &&
        <WorkOrderDetail item={operateInfo.data} onClose={() => setOperateInfo({})} />
      }
      {['add', 'edit'].includes(operateInfo?.type) &&
        <OperateModal
          type={operateInfo.type}
          workOrderInfo={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={getData}
          workId={getWorkOrderId}
        />
      }
      {operateInfo?.type === 'reopen' &&
        <ReopenModal id={operateInfo.data} onClose={() => setOperateInfo({})} reloadData={getData} />
      }
      {['reassign', 'assign'].includes(operateInfo?.type) &&
        <AssignModal data={operateInfo.data} onClose={() => setOperateInfo({})} reloadData={getData} type={operateInfo.type} />
      }
      {operateInfo?.type === 'comment' &&
        <CommentModal id={operateInfo.data} onClose={() => setOperateInfo({})} reloadData={getData} />
      }
    </Card>
  )
}

export default WorkOrder;
  