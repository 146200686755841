// extracted by mini-css-extract-plugin
export default {"operateUltrasonicInstall":"operateModal_operateUltrasonicInstall__Qh1pu","formBox":"operateModal_formBox__0sKCp","imageBox":"operateModal_imageBox__KyEYn"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"operateUltrasonicInstall\":\"operateModal_operateUltrasonicInstall__Qh1pu\",\"formBox\":\"operateModal_formBox__0sKCp\",\"imageBox\":\"operateModal_imageBox__KyEYn\"}";
        // 1731660741212
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  