// extracted by mini-css-extract-plugin
export default {"tagManageBox":"tagManage_tagManageBox__95nHs","operateBox":"tagManage_operateBox__WV+6N","tagBox":"tagManage_tagBox__-sZyG","sortTree":"tagManage_sortTree__LC64B","treeTitle":"tagManage_treeTitle__0vkBl","tagList":"tagManage_tagList__X1aj3"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"tagManageBox\":\"tagManage_tagManageBox__95nHs\",\"operateBox\":\"tagManage_operateBox__WV+6N\",\"tagBox\":\"tagManage_tagBox__-sZyG\",\"sortTree\":\"tagManage_sortTree__LC64B\",\"treeTitle\":\"tagManage_treeTitle__0vkBl\",\"tagList\":\"tagManage_tagList__X1aj3\"}";
        // 1731660723648
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  