/**
 * 历史疲劳驾驶车辆
 */
import React, { Fragment, useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider, Input, InputNumber } from 'antd';
import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import DatePicker from '@/components/datePicker';
import OperationGroupSelect from '@/components/operationGroupSelect';
import LoadingWrapper from '@/components/loadingWrapper';
import ExportButton from '@/components/exportButton';
import EchartsWrapper from '@/components/echartsWrapper';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { formatTime } from '@/utils/formatTime';
import {
  historyFatiguesRequest,
  historyFatiguesRequestPath,
  HistoryFatiguesRequestParams
} from '@/service/fatigueDrive';
import { snColumn } from '@/columns/carDetail';
import { formSearchGutter } from '@/constants/common';
import styles from '@/styles/pageContent.module.scss';

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
const { RangePicker } = DatePicker;
const HistoryFatigue = () => {
  const cateIdArr = useUserCateIdArr();
  const [loading, setLoading] = useState(false); // 数据是否请求中
  const [data, setData] = useState<any>([]); // 表格数据
  const [chartOption, setChartOption] = useState<any>(null); // 图表配置
  const [form] = Form.useForm();

  useEffect(() => {
    form.submit();
  }, []);

  // 获取当前疲劳驾驶的车辆
  const getHistoryFatigue = async (params: HistoryFatiguesRequestParams) => {
    try {
      setLoading(true);
      const res = await historyFatiguesRequest(params);
      const tableData = res.data?.data?.first || [];
      const chartData = res.data?.data?.second || [];
      const xData: any = [];
      const yData: any = [];
      chartData.forEach((item: any) => {
        xData.push(item.key);
        yData.push(item.data);
      });
      const chartConfig = {
        title: { text: '疲劳驾驶趋势', x: 'center' },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xData,
          name: '日期'
        },
        yAxis: {
          type: 'value',
          name: '疲劳驾驶车辆数'
        },
        series: [
          {
            data: yData,
            type: 'line',
            markLine: {
              data: [{ type: 'average', name: 'Avg' }]
            }
          }
        ]
      };
      setChartOption(chartConfig);
      setData(tableData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // 处理搜索参数
  const operateSearchParams = (values: any, isExport?: boolean) => {
    // 存在校验不通过的信息时不触发请求
    if (
      (form.getFieldsError() || []).find((item: any) => item.errors?.length > 0)
    ) {
      return;
    }
    const {
      cateId,
      plateNumber,
      fatigueThresholdHour,
      fatigueThresholdMintue,
      restThresholdHour,
      restThresholdMintue
    } = values;
    const params: any = { cateId, plateNumber };
    params.beginTime = dayjs(values.time[0]).format('YYYY-MM-DD HH:mm:ss');
    params.endTime = dayjs(values.time[1]).format('YYYY-MM-DD HH:mm:ss');
    params.fatigueThreshold =
      fatigueThresholdHour * 60 + fatigueThresholdMintue;
    params.restThreshold = restThresholdHour * 60 + restThresholdMintue;
    params.export = Boolean(isExport);
    return params;
  };

  // 搜索
  const onSearch = (values: any) => {
    const params = operateSearchParams(values);
    if (params) {
      getHistoryFatigue(params);
    }
  };

  // 生成导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  };

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId
      });
    }
  };

  // 校验疲劳驾驶时长阈值
  const validatorFatigueThreshold = () => {
    const { getFieldsValue, setFields } = form;
    const { fatigueThresholdHour, fatigueThresholdMintue } = getFieldsValue();
    if (fatigueThresholdHour || fatigueThresholdMintue) {
      setFields([{ name: 'fatigueThreshold', errors: [], value: '' }]);
    } else {
      setFields([
        {
          name: 'fatigueThreshold',
          errors: ['请输入疲劳驾驶时长阈值'],
          value: ''
        }
      ]);
    }
  };

  // 校验退出疲劳驾驶时长阈值
  const validatorRestThreshold = () => {
    const { getFieldsValue, setFields } = form;
    const { restThresholdHour, restThresholdMintue } = getFieldsValue();
    if (restThresholdHour || restThresholdMintue) {
      setFields([{ name: 'restThreshold', errors: [], value: '' }]);
    } else {
      setFields([
        {
          name: 'restThreshold',
          errors: ['请输入退出疲劳驾驶时长阈值'],
          value: ''
        }
      ]);
    }
  };

  // 额外的展开行的渲染
  const expandedRowRender = (record: any) => {
    const innerColumns = [
      {
        title: '疲劳开始时间',
        dataIndex: 'beginTime',
        width: 150,
        render: (value: string) => formatTime(value)
      },
      {
        title: '疲劳结束时间',
        dataIndex: 'endTime',
        width: 150,
        render: (value: string) => formatTime(value)
      },
      {
        title: '连续驾驶时长(分钟)',
        dataIndex: 'minute',
        width: 160
      }
    ];
    return (record.records || []).map((item: any) => (
      <Fragment>
        <Divider
          orientation="center"
          plain
        >{`驾驶时长 ${item.totalMinute} 分`}</Divider>
        <CommonTable
          rowKey="id"
          columnFilter={false}
          columns={innerColumns}
          dataSource={item.records || []}
          pagination={false}
          bordered
          scroll={{ y: 300 }}
          size="small"
          isNestTable
        />
      </Fragment>
    ));
  };

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as const
    },
    snColumn,
    {
      title: '疲劳驾驶次数',
      dataIndex: 'fatigueCounts',
      width: 160
    }
  ];

  return (
    <Fragment>
      <Form
        onFinish={onSearch}
        form={form}
        style={{ marginTop: '16px' }}
        autoComplete="off"
      >
        <Row gutter={formSearchGutter} wrap>
          <Col span={8}>
            <Form.Item
              label="时间范围"
              name="time"
              initialValue={[
                dayjs().startOf('day').subtract(7, 'days'),
                dayjs().endOf('day').subtract(1, 'days')
              ]}
            >
              <RangePicker
                showTime
                allowClear={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey="/car-admin/fatigue-drive/show-operation-group"
            span={8}
          />
          <Col span={8}>
            <Form.Item
              label="分组选择"
              name="cateId"
              initialValue={cateIdArr}
              rules={[{ required: true, message: '请选择分组' }]}
            >
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="疲劳驾驶阈值" required name="fatigueThreshold">
              <Input.Group compact>
                <Form.Item noStyle name="fatigueThresholdHour" initialValue={5}>
                  <InputNumber
                    addonAfter="小时"
                    precision={0}
                    min={0}
                    style={{ width: '50%' }}
                    onChange={validatorFatigueThreshold}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  name="fatigueThresholdMintue"
                  initialValue={0}
                >
                  <InputNumber
                    addonAfter="分钟"
                    precision={0}
                    min={0}
                    max={60}
                    style={{ width: '50%' }}
                    onChange={validatorFatigueThreshold}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="退出疲劳驾驶阈值" name="restThreshold" required>
              <Input.Group compact>
                <Form.Item noStyle name="restThresholdHour" initialValue={0}>
                  <InputNumber
                    addonAfter="小时"
                    precision={0}
                    min={0}
                    style={{ width: '50%' }}
                    onChange={validatorRestThreshold}
                  />
                </Form.Item>
                <Form.Item noStyle name="restThresholdMintue" initialValue={30}>
                  <InputNumber
                    addonAfter="分钟"
                    precision={0}
                    min={0}
                    style={{ width: '50%' }}
                    onChange={validatorRestThreshold}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                  <ExportButton
                    url={historyFatiguesRequestPath}
                    fileName={`疲劳驾驶数据导出-${formatTime(
                      form.getFieldValue('time')[0]
                    )}-${formatTime(form.getFieldValue('time')[1])}`}
                    getParamsFunc={getExportParams}
                    requestMethod="POST"
                    permissionKey="/car-admin/fatigue-drive/history-export"
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <EchartsWrapper
          chartOption={chartOption}
          style={{ height: '400px' }}
          useTabKey="/car-admin/fatigue-drive"
        />
      </LoadingWrapper>
      <CommonTable
        rowKey="carId"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
        expandable={{
          expandedRowRender
        }}
        scroll={{ y: 500 }}
      />
    </Fragment>
  );
};

export default HistoryFatigue;
