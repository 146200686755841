/**
 * 车辆诊断表格数据(在此组件区分展示黄河设备和昆仑山设备)
 */
import React from 'react';
import { Tabs } from 'antd';
import CarOperationTable from './carOperationTable';
import KlsDeviceTable from './klsDeviceTable';
import KlsVersionTable from './klsVersionTable';
import KlsParamsTable from './klsParamsTable';
import KlsVehicleTypeTable from './klsVehicleTypeTable';
import KlsModemErrorTable from './klsModemErrorTable';
import JtDeviceErrorTable from './jtDeviceErrorTable';
import styles from './index.module.scss';

interface CarDiagnosisTableProps {
  isKls: boolean; // 是否是昆仑山设备
  isKlsV3: boolean; // 是否是昆仑山3.0设备
  diagnosisSearchParams: any; // 搜索参数
  hasModem: boolean; // 是否安装行车记录仪
  hasJtDevice: boolean; // 是否安装部标机
}

const CarDiagnosisTable = (props: CarDiagnosisTableProps) => {
  const { isKls, isKlsV3, diagnosisSearchParams, hasModem, hasJtDevice } =
    props;

  // 获取导出的参数
  const getExportParams = async () => {
    if (diagnosisSearchParams) {
      return {
        ...diagnosisSearchParams,
        export: true
      };
    }
  };

  if (isKls) {
    return (
      <div className={styles.carDiagnosisTable}>
        <div className={styles.aebTitle}>AEB运行数据</div>
        <Tabs>
          <Tabs.TabPane tab="车辆运行数据" key="operation">
            <CarOperationTable
              diagnosisSearchParams={diagnosisSearchParams}
              getExportParams={getExportParams}
              isKls
              noTitle={true}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="设备状态变更" key="device">
            <KlsDeviceTable
              diagnosisSearchParams={diagnosisSearchParams}
              getExportParams={getExportParams}
              isKlsV3={isKlsV3}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="设备版本变更" key="version">
            <KlsVersionTable
              diagnosisSearchParams={diagnosisSearchParams}
              getExportParams={getExportParams}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="设备参数变更" key="params">
            <KlsParamsTable
              diagnosisSearchParams={diagnosisSearchParams}
              getExportParams={getExportParams}
              isKlsV3={isKlsV3}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="车身类型变更" key="vehicleType">
            <KlsVehicleTypeTable
              diagnosisSearchParams={diagnosisSearchParams}
              getExportParams={getExportParams}
            />
          </Tabs.TabPane>
          {hasModem && (
            <Tabs.TabPane tab="记录仪异常" key="modem">
              <KlsModemErrorTable
                diagnosisSearchParams={diagnosisSearchParams}
                getExportParams={getExportParams}
              />
            </Tabs.TabPane>
          )}
          
          {/*{hasJtDevice && (*/}
          {/*  <Tabs.TabPane tab="部标机异常" key="jtDevice">*/}
          {/*    <JtDeviceErrorTable*/}
          {/*      diagnosisSearchParams={diagnosisSearchParams}*/}
          {/*      getExportParams={getExportParams}*/}
          {/*    />*/}
          {/*  </Tabs.TabPane>*/}
          {/*)}*/}
        </Tabs>
      </div>
    );
  } else {
    return (
      <CarOperationTable
        diagnosisSearchParams={diagnosisSearchParams}
        getExportParams={getExportParams}
      />
    );
  }
};

export default React.memo(CarDiagnosisTable);
