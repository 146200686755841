/**
 * 部标机报警表格列配置
 */
import React from 'react';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import JtDeviceAlarmOperateColumn from '@/pages/jtDeviceAlarm/components/jtDeviceAlarmOperateColumn';
import {
  displayJtDeviceAlarm,
  displayJtDeviceInfo
} from '@/utils/jtDeviceAlarm';
import { ownRound } from '@/utils/math';
import { formatTime } from '@/utils/formatTime';

// 表格列配置
export const jtDeviceAlarmColumns = [
  {
    title: '车牌号',
    dataIndex: 'plateNumber',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />
  },
  {
    title: '报警类型',
    dataIndex: 'contentMap',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => (
      <RenderEllipsisColumn text={displayJtDeviceAlarm(value)} />
    )
  },
  {
    title: '上报时间',
    dataIndex: 'contentMap',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: any) => {
      const key = Object.keys(value)  || []
      return <>
    
    <RenderEllipsisColumn text={formatTime(value[key[0]]?.time)} />
    </>
  }
  },
  {
    title: '速度',
    dataIndex: 'speed',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string, item: any) => (
      <RenderEllipsisColumn
        text={ownRound(displayJtDeviceInfo(item, 'speed', 'speed'), 1)}
      />
    )
  },
  {
    title: '操作',
    dataIndex: 'operate',
    width: 150,
    fixed: 'right' as const,
    render: (value: any, item: any) => (
      <JtDeviceAlarmOperateColumn
        alarmData={item}
        downloadPermissionKey="/car-admin/dvr-alarm/download-video"
      />
    )
  }
];
