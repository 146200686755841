// extracted by mini-css-extract-plugin
export default {"commentTitle":"workOrderDetail_commentTitle__Rs6Wr","commentBox":"workOrderDetail_commentBox__H959q","commentItem":"workOrderDetail_commentItem__iDECJ"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"commentTitle\":\"workOrderDetail_commentTitle__Rs6Wr\",\"commentBox\":\"workOrderDetail_commentBox__H959q\",\"commentItem\":\"workOrderDetail_commentItem__iDECJ\"}";
        // 1731660740863
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  