/**
 * 部标机设备报警列表的操作列
 */
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import VideoPreviewModal from '@/components/videoPreviewModal';
import ImagePreviewModal from '@/components/imagePreviewModal';
import Thumbnail from '@/components/thumbnail';
import { jtDeviceAlarmVideoRequest } from '@/service/jtDeviceAlarm';
import { getInnerPermissionEnable } from '@/utils/permission';
import { changeUrlProtocol } from '@/utils/common';
import { formatTime } from '@/utils/formatTime';
import styles from '@/styles/pageContent.module.scss';

interface JtDeviceAlarmOperateColumnProps {
  alarmData: any; // 报警信息
  downloadPermissionKey?: string; // 报警附件下载的权限key
}
const JtDeviceAlarmOperateColumn = (props: JtDeviceAlarmOperateColumnProps) => {
  const { alarmData, downloadPermissionKey } = props;
  const { contentMap, hasVideo, phone, ossKeyArr, osses } = alarmData;
  const alarmInfo = contentMap?.adas || contentMap?.blind || contentMap?.driver;
  const [attachmentInfo, setAttachmentInfo] = useState<any>({}); // 附件展示的信息

  // 查看报警附件
  const onPlay = async () => {
    let fileName = '';
    if ((osses || []).length > 1) {
      // 有多个附件时显示缩略图
      const allAttachmentRequest = (osses || []).map((oss: {ossKey: string, ossGroup: string}) =>
        jtDeviceAlarmVideoRequest(oss.ossKey, oss.ossGroup)
      );
      const attachmentData: any = [];
      const res = await Promise.allSettled(allAttachmentRequest);
      res.forEach((item: any, index: number) => {
        attachmentData.push({
          url: changeUrlProtocol(item.value?.data?.data),
          contentType: osses[index].ossKey.endsWith('mp4') ? 'video' : 'image'
        });
      });
      setAttachmentInfo({ type: 'attachment', attachmentData });
    } else if ((osses || []).length === 1) {
      // 多种附件只有一条数据时直接显示
      const res = await jtDeviceAlarmVideoRequest(osses[0].ossKey, osses[0].ossGroup);
      setAttachmentInfo({
        type: osses[0]?.ossKey.endsWith('mp4') ? 'video' : 'image',
        url: changeUrlProtocol(res.data.data)
      });
    } else if (hasVideo) {
      // 只支持视频附件的数据格式兼容
      let alarmKey = '';
      if (contentMap?.adas) {
        alarmKey = '64';
      } else if (contentMap?.blind) {
        alarmKey = '67';
      } else if (contentMap?.driver) {
        alarmKey = '65';
      }
      fileName = `02_${alarmKey}_${alarmKey}${alarmInfo.warningType
        .toString(16)
        .padStart(2, '0')}_${phone}_${formatTime(
        alarmInfo.time1,
        'YYYY-MM-DD HH-mm-ss'
      )}.mp4`;
      const res = await jtDeviceAlarmVideoRequest(fileName);
      setAttachmentInfo({
        type: 'video',
        url: changeUrlProtocol(res.data.data)
      });
    }
  };

  // 兼容报警附件数据结构的变化
  if ((hasVideo && alarmInfo) || (osses || []).length) {
    const showDownload = getInnerPermissionEnable(downloadPermissionKey); // 是否可以下载
    return (
      <div className={styles.tableOperateColumn}>
        <Button type="link" onClick={onPlay}>
          查看报警附件
        </Button>
        {/* 预览视频的弹窗 */}
        {attachmentInfo?.type === 'video' && (
          <VideoPreviewModal
            url={attachmentInfo.url}
            fileName="报警视频"
            closeModal={() => setAttachmentInfo({})}
            showDownload={showDownload}
          />
        )}
        {/* 预览照片的弹窗 */}
        {attachmentInfo?.type === 'image' && (
          <ImagePreviewModal
            url={attachmentInfo.url}
            fileName="报警图片"
            closeModal={() => setAttachmentInfo({})}
            showDownload={showDownload}
          />
        )}
        {/* 预览多个附件的弹窗 */}
        {attachmentInfo?.type === 'attachment' && (
          <Modal
            title="报警附件"
            open
            footer={null}
            onCancel={() => setAttachmentInfo({})}
            centered
          >
            <Thumbnail
              dataSource={attachmentInfo.attachmentData || []}
              downloadImgName="报警图片"
              downloadVideoName="报警视频"
              size="large"
              showDownload={showDownload}
            />
          </Modal>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default JtDeviceAlarmOperateColumn;
