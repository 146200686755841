/**
 * 车辆诊断表格数据(在此组件区分展示adas设备)
 */
import React, {Fragment, useEffect, useState} from 'react';
import {Button, Tabs} from 'antd';
import CommonTable from '@/components/commonTable';
import {carAdasRequest, carAdasRequestPath} from '@/service/carDiagnosis';
import {useTable} from '@/utils/useTable';
import dayjs from 'dayjs';
import pageStyles from '@/styles/pageContent.module.scss';
import ExportButton from '@/components/exportButton';
import JtDeviceErrorTable from "@/pages/carDiagnose/components/carDiagnosisTable/jtDeviceErrorTable";
import { useDispatch } from 'react-redux';

interface CarDiagnosisTableProps {
  diagnosisSearchParams: any;
  isTly40?: boolean;//是否是TLY4.0
  plateNumber?: string;
}

const CarAdasTable = (props: CarDiagnosisTableProps) => {
  const {diagnosisSearchParams,isTly40, plateNumber} = props;
  const [searchParams, setSearchParams] = useState(diagnosisSearchParams);
  const dispatch = useDispatch();
  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(diagnosisSearchParams);
  }, [diagnosisSearchParams]);


  const {page, limit, loading, data, totalCount, onPageChange} = useTable({
    requestFunc: carAdasRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 获取导出的参数
  const getExportParams = async () => {
    if (searchParams) {
      return {
        ...searchParams,
        export: true,
      }
    }
  }

    // 点击表格日期跳转到驾驶行为分析页面
    const onDateClick = (plateNumber: string, date: string) => {
      dispatch({
        type: 'addTab',
        payload: {
          tab: '驾驶行为分析',
          key: '/car-admin/drive-analysis',
          params: { plateNumber, date }
        }
      })
    }

  const columns: any = [
    {
      title: '日期',
      dataIndex: 'day',
      width: 70,
      render: (value: string, item: any) => (
        <div className={pageStyles.tableOperateColumn}>
          <Button
            type="link"
            onClick={() => onDateClick(plateNumber || '', dayjs(value).format('YYYY-MM-DD'))}
          >
            {dayjs(value).format('MM-DD')}
          </Button>
        </div>
      ),
      fixed: 'left' as 'left'
    },
    {
      title: "运行里程",
      dataIndex: "diffMileage",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },{
      title: "运行时长（分钟）",
      dataIndex: "minute",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "前向碰撞报警数量",
      dataIndex: "fcwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车道偏离报警数",
      dataIndex: "ldwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车距过近报警数",
      dataIndex: "hmwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "行人碰撞报警数",
      dataIndex: "pcwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "疲劳驾驶报警数",
      dataIndex: "fdwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "接打电话报警数",
      dataIndex: "mpwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "抽烟报警数",
      dataIndex: "sdwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "分神驾驶报警数",
      dataIndex: "ddwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "驾驶员异常报警数",
      dataIndex: "dawCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "相机遮挡报警数",
      dataIndex: "cowCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "未系安全带报警数",
      dataIndex: "nwwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "玩手机报警数",
      dataIndex: "ppwCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "盲区报警数",
      dataIndex: "bsdCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "gnss模块故障数",
      dataIndex: "gnssErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "gnss天线未接或被剪断数",
      dataIndex: "gnssConnectionFailureCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "gnss天线短路数",
      dataIndex: "gnssShortCircuit",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "终端主电源欠压",
      dataIndex: "powerUndervoltageCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "终端主电源掉电",
      dataIndex: "powerDownCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "终端LCD或显示器故障数",
      dataIndex: "lcdErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "TTS模块故障数",
      dataIndex: "ttsErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "摄像头故障数",
      dataIndex: "cameraErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "道路运输证IC卡模块故障数",
      dataIndex: "icErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车辆VSS故障数",
      dataIndex: "vehicleVssErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车辆油量异常数",
      dataIndex: "vehicleOilErrorCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车辆被盗数",
      dataIndex: "vehicleTheftCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车辆非法点火数",
      dataIndex: "vehicleLaunchIllegallyCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
    {
      title: "车辆非法位移数",
      dataIndex: "vehicleMoveIllegallyCount",
      width: 130,
      render: (value: number) => value===null ? '--' : value
    },
  ]

  //仅在TLY4.0时展示前像制动字段
  if (isTly40){
    columns.push(
      {
        title: "前向制动报警数",
        dataIndex: "aebCount",
        width: 130,
        render: (value: number) => value===null ? '--' : value
      }
    )
  }

  return (
    <Fragment>
      <Tabs>
        <Tabs.TabPane tab="ADAS数据" key="operation">
          <div style={{margin: '12px 0', textAlign: 'right'}}>
            <ExportButton
              type='primary'
              disabled={!diagnosisSearchParams}
              url={carAdasRequestPath}
              fileName={`${diagnosisSearchParams?.plateNumber}车辆分析`}
              getParamsFunc={getExportParams}
              requestMethod="GET"
              permissionKey='/car-admin/car-diagnosis/car-operation-export'
            />
          </div>
          <CommonTable
            rowKey="id"
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalCount,
              current: page,
              pageSize: limit,
              onChange: onPageChange,
            }}
            bordered
            loading={loading}
            scroll={{x: '100%', y: 400}}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='ADAS异常'>
          <JtDeviceErrorTable
            diagnosisSearchParams={diagnosisSearchParams}
            getExportParams={getExportParams}
          />
        </Tabs.TabPane>
      </Tabs>

    </Fragment>
  )
}

export default CarAdasTable;