/**
 * 部标机设备视频回放
 */
import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, message, Select, Row, Col, Radio, Tabs } from 'antd';
import flvjs from 'flv.js';
import dayjs from 'dayjs';
import { formSearchGutter } from '@/constants/common';
import { codeStreamPlaybackData } from '@/constants/jtDevice';
import {
  jt808Command9201Request,
  jt808Command9202Request
} from '@/service/jtDevicePlayback';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import JtDeviceResourceTable from './components/jtDeviceResourceTable';
import FtpResourceTable from './components/ftpResourceTable';
import { formatTime } from '@/utils/formatTime';
import { useJtDeviceChannel } from '@/utils/jtDevice';
import { operateJt808VersionPath } from '@/utils/jtDeviceRequest';
import { useDebounce } from '@/utils/ownHooks';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';
import Player, { Events } from 'xgplayer';
import FlvJsPlugin from 'xgplayer-flv.js';
import { event } from 'jquery';

const { RangePicker } = DatePicker;
const JtDevicePlayback = () => {
  const jtDevicePlaybackParamsRef: any = useRef(null); // 存储部标机设备视频回放参数(用于终止视频回放)
  const flvPlayerRef: any = useRef(null); // 播放器实例
  const ftpResourceTableRef: any = useRef(null); // ftp资源列表实例
  const [selectJtDeviceInfo, setSelectJtDeviceInfo] = useState<any>({}); // 选择车辆对应的部标机设备信息
  const [selectDeviceChannelData, setSelectDeviceChannelData] = useState<any>(
    []
  ); // 选择部标机设备的通道数据
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 是否搜索过
  const [jtDeviceResourceRequestParams, setJtDeviceResourceRequestParams] =
    useState<any>([]); // 部标机设备资源列表请求参数
  const [playbackLoading, setPlaybackLoading] = useState<boolean>(false); // 回放传输的loading
  const [activeTab, setActiveTab] = useState<string>('jtDevice'); // 激活的tab
  const [form] = Form.useForm();
  const jtDeviceChannelData = useJtDeviceChannel();

  useEffect(() => {
    return () => {
      closeJtDevicePlayback();
    };
  }, []);

  // 选择车辆
  const treeSelect = (selectCarInfo: any) => {
    console.log(selectCarInfo)
    const { jt808DeviceId, terminalPhone, title, channelNoList, version, manufacturer } =
      selectCarInfo;
    // 存储部标机相关信息
    setSelectJtDeviceInfo({
      phone: terminalPhone,
      jt808DeviceId,
      plateNumber: title,
      version,
      manufacturer
    });
    // 获取到当前设备有的通道数据
    const currentJtDeviceChannelData = jtDeviceChannelData.filter((item: any) =>
      channelNoList.includes(item.value)
    );
    setSelectDeviceChannelData(currentJtDeviceChannelData);
    closeJtDevicePlayback();
    setHasSearched(false);
    setJtDeviceResourceRequestParams([]);
    form.setFieldValue('channelNo', []);
  };

  // 搜索获取资源列表
  const onSearch = async (values: any) => {
    if (selectJtDeviceInfo.phone) {
      closeJtDevicePlayback();
      setHasSearched(true);
      setJtDeviceResourceRequestParams([values]);
      setActiveTab('jtDevice');
    } else {
      message.warning('请选择车辆');
    }
  };

  // 关闭视频回放
  const closeJtDevicePlayback = async () => {
    if (flvPlayerRef.current) {
      flvPlayerRef.current?.pause();
      flvPlayerRef.current?.unload();
      flvPlayerRef.current?.detachMediaElement();
      flvPlayerRef.current?.destroy();
      flvPlayerRef.current = null;
    }
    if (jtDevicePlaybackParamsRef.current) {
      try {
        const { channelNo, phone, jt808DeviceId } =
          jtDevicePlaybackParamsRef.current;
        const params = {
          phone,
          jt808DeviceId,
          channelNo,
          cmd: 2, // 结束回放
          speed: 0, // 无快进或快退
          time: formatTime(dayjs())
        };
        await jt808Command9202Request(params);
        jtDevicePlaybackParamsRef.current = null;
      } catch (error) {
        message.error('视频回放结束指令下发失败');
      }
    }
  };

  // 播放进度条调整事件
  const onSeeking = async (videoElement: any, start: string) => {
    console.log(start, 1231231231231)
    const currentTime = videoElement.currentTime;
    // if (!videoElement.seeking) {
    //   flvPlayerRef.current.unload()
    //   flvPlayerRef.current.detachMediaElement()
    // }
    const { channelNo, phone, jt808DeviceId } =
      jtDevicePlaybackParamsRef.current;
    const params = {
      phone,
      jt808DeviceId,
      channelNo,
      cmd: 5, // 拖动回放位置
      speed: 0, // 无快进或快退
      time: formatTime(dayjs(start).add(currentTime, 'second'))
    };
    await jt808Command9202Request(params);
    // if (!videoElement.seeking) {
    //   flvPlayerRef.current.attachMediaElement(videoElement);
    //   flvPlayerRef.current.load();
    //   flvPlayerRef.current.play();
    // }
  };

  // 播放进度条调整事件防抖
  const debounceOnSeeking = useDebounce(onSeeking);

  // 开始视频回放
  const onPlayback = async (data: any) => {
    try {
      setPlaybackLoading(true);
      await closeJtDevicePlayback();
      const { phone, jt808DeviceId, version, manufacturer } = selectJtDeviceInfo;
      const hasAudio = manufacturer == 4 ? false : true
      const { channelNo, start, end, type, codeStream, storageType } = data;
      const params = {
        phone,
        jt808DeviceId,
        channelNo,
        codeStream,
        start: formatTime(start),
        end: formatTime(end),
        type,
        storageType,
        playbackMode: 0, // 正常回放
        speed: 0 // 无快进或快退
      };
      const res = await jt808Command9201Request(params);
      if (res.data?.data) {
        jtDevicePlaybackParamsRef.current = params;
        if (flvjs.isSupported()) {
          const videoElement = document.getElementById(
            'playbackVideo'
          ) as HTMLMediaElement;
          const flvPlayer = flvjs.createPlayer({
            type: 'flv',
            isLive: true,
            cors: true,
            hasAudio: hasAudio,
            hasVideo: true,
            duration: dayjs(end).diff(dayjs(start)),
            url: `/video/${operateJt808VersionPath(
              version
            )}/${phone}-${channelNo}`
          });
          flvPlayerRef.current = flvPlayer;
          flvPlayer.attachMediaElement(videoElement);
          flvPlayer.load();
          flvPlayer.play();
          videoElement.addEventListener('seeking', () =>
            debounceOnSeeking(videoElement, start, end)
          );
        }
        // if (FlvJsPlugin.isSupported()) {
        //   const player = new Player({
        //     el: document.getElementById('playbackVideo') as HTMLMediaElement,
        //     isLive: false,
        //     url: `/video/${operateJt808VersionPath(
        //       version
        //     )}/${phone}-${channelNo}`,
        //     plugins: [FlvJsPlugin],
        //     flv: {
        //       retryCount: 0,
        //       maxLatency: 5,
        //       targetLatency: 0
        //     },
        //     height: '100%',
        //     width: '100%',
        //     autoplay: true,
        //     lang: 'zh-cn',
        //     closeVideoClick: true,
        //     ignores: ['cssFullScreen', 'playbackrate', 'loading', 'start']
        //   });
        //   player.on(Events.PAUSE,(event) => {
        //     console.log(event, '123123123123123')
        //   })
        // }
      } else if (res.data?.code === 0) {
        message.error('视频回放开始指令下发失败');
      }
      setPlaybackLoading(false);
    } catch (error) {
      message.error('视频回放开始指令下发失败');
      setPlaybackLoading(false);
    }
  };

  // 播放ftp资源
  const playFtpVideo = (url: string) => {
    closeJtDevicePlayback();
    const videoElement = document.getElementById(
      'playbackVideo'
    ) as HTMLMediaElement;
    videoElement.src = url;
    videoElement?.play();
  };

  // tab切换
  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    if (activeKey === 'ftp') {
      ftpResourceTableRef?.current?.reload();
    }
  };

  return (
    <LoadingWrapper
      spinning={playbackLoading}
      autoHeight
      style={{ height: '100%' }}
    >
      <div className={styles.spaceBetweenPageContentBox}>
        <div className={styles.carTree}>
          <CarTree
            treeSelect={treeSelect}
            selectableTypes={['car']}
            defaultFilterKey="hasJtDevice"
          />
        </div>
        <div className={styles.contentBox}>
          <Row
            className={ownStyles.videoBox}
            gutter={formSearchGutter}
            style={hasSearched ? { flexGrow: 1 } : {}}
          >
            <Col span={12}>
              <Form
                onFinish={onSearch}
                form={form}
                className={ownStyles.videoForm}
              >
                <Row gutter={formSearchGutter} wrap>
                  <Col span={24}>
                    <Form.Item
                      label="时间范围"
                      name="time"
                      rules={[{ required: true, message: '请选择时间范围' }]}
                    >
                      <RangePicker style={{ width: '100%' }} showTime />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="视频通道"
                      name="channelNo"
                      rules={[{ required: true, message: '请选择视频通道' }]}
                    >
                      <Select
                        placeholder="请选择视频通道"
                        options={selectDeviceChannelData}
                        showArrow
                        maxTagCount="responsive"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="码流类型"
                      name="codeStream"
                      initialValue={0}
                    >
                      <Radio.Group options={codeStreamPlaybackData} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        查询
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            {hasSearched && (
              <Col span={12} className={ownStyles.videoPlay}>
                <video id="playbackVideo" controls />
                {/* <div id="playbackVideo"></div> */}
              </Col>
            )}
          </Row>
          {hasSearched ? (
            <div className={ownStyles.resourceTable}>
              <Tabs onChange={onTabChange} activeKey={activeTab}>
                <Tabs.TabPane tab="视频文件" key="jtDevice">
                  <JtDeviceResourceTable
                    requestParams={jtDeviceResourceRequestParams}
                    onPlayback={onPlayback}
                    selectJtDeviceInfo={selectJtDeviceInfo}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="下载列表" key="ftp">
                  <FtpResourceTable
                    plateNumber={selectJtDeviceInfo.plateNumber}
                    jt808DeviceId={selectJtDeviceInfo.jt808DeviceId}
                    playFtpVideo={(url: string) => playFtpVideo(url)}
                    ref={ftpResourceTableRef}
                    downloadPermissionKey="/car-admin/dvr-playback/download-ftp"
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          ) : (
            <div className={styles.tipBox}>
              请在左侧选择车辆后选择视频通道后点击“查询”按钮
            </div>
          )}
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default JtDevicePlayback;
