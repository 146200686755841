// extracted by mini-css-extract-plugin
export default {"loginBg":"login_loginBg__zHb3m","icp":"login_icp__eewcU","loginBox":"login_loginBox__ugjoB","logo":"login_logo__mjNFL","loginForm":"login_loginForm__1knyQ","title":"login_title__zT-wX","desc":"login_desc__C42S5"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"loginBg\":\"login_loginBg__zHb3m\",\"icp\":\"login_icp__eewcU\",\"loginBox\":\"login_loginBox__ugjoB\",\"logo\":\"login_logo__mjNFL\",\"loginForm\":\"login_loginForm__1knyQ\",\"title\":\"login_title__zT-wX\",\"desc\":\"login_desc__C42S5\"}";
        // 1731660683104
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  