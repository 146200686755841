/**
 * 操作车辆运维评级记录(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertCarEvaluation } from '@/service/carEvaluationRecord'
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';


interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [gradeList, setGradeList] = useState<any>([]); //获取运维评级

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
    if (type === 'add') {
      if (data !== '') {
        form.setFieldsValue(data)
      }
    }
    getServiceState()
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertCarEvaluation(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }

  // 获取运维评级
  const getServiceState = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carEvaluation],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setGradeList(tags);
  }

  return (
    <Modal
      title={type === 'add' ? '添加运维评级记录' : '编辑运维评级记录'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item label="车架号" name="frameNumber">
          <Input placeholder="请输入车架号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
        </Form.Item>
        <Form.Item label="评级" name="grade">
            <Select
                options={gradeList} 
                placeholder="请选择评级"
                showSearch
                showArrow
                maxTagCount="responsive"
              />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
