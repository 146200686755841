const carType = [
  // {label: '客车', value: '0'},
  {label: '货车', value: '1'},
  {label: '半挂牵引车', value: '2'},
  {label: '货车挂车', value: '3'},
  // {label: '油罐车', value: '4'},
  // {label: '气罐车', value: '5'},
  // {label: '液罐车', value: '6'},
  // {label: '冷藏车', value: '7'},
  // {label: '罐车挂车', value: '8'},
  // {label: '混凝土搅拌车', value: '9'},
  // {label: '特种车二挂车', value: '10'},
  // {label: '特种车二类其他', value: '11'},
  // {label: '监测车', value: '12'},
  // {label: '警用特种车', value: '13'},
  // {label: '混凝土泵车', value: '14'},
  // {label: '特种车三类挂车', value: '15'},
  // {label: '特种车三类其他', value: '16'},
]

export const NCDOptions= new Array(20).fill('').map((item, index: number) => {return {value: ((index + 1)*0.1).toFixed(1), label: ((index + 1)*0.1).toFixed(1) + ''}})
export default carType;
