/**
 * 车辆管理系统(以tab形式渲染所有页面)
 */
import React, {useState} from 'react';
import {Layout, Tabs} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import Home from '@/pages/home';
import EffectiveAlarmStatistics from '@/pages/effectiveAlarmStatistics';
import CarTimeStatistics from '@/pages/carTimeStatistics';
import CarManage from '@/pages/carManage';
import CarImport from '@/pages/carImport';
import GroupManage from '@/pages/groupManage';
import UserManage from '@/pages/userManage';
import FaultCodeFilter from '@/pages/faultCodeFilter';
import BreakAbnormal from '@/pages/breakAbnormal';
import Overspeed from '@/pages/overspeed';
import AebOff from '@/pages/aebOff';
import OfflineDay from '@/pages/offlineDay';
import AlarmCount from '@/pages/alarmCount';
import TrackPlayback from '@/pages/trackPlayback';
import OnlineRecord from '@/pages/onlineRecord';
import AlarmSearch from '@/pages/alarmSearch';
import StatusSearch from '@/pages/statusSearch';
import CarInfo from '@/pages/carInfo';
import CarLocation from '@/pages/carLocation';
import CarLocationQuery from '@/pages/carLocationQuery';
import OnlineDay from '@/pages/onlineDay';
import CarAlarm from '@/pages/carAlarm';
import OnlineNoAlarm from '@/pages/onlineNoAlarm';
import SpeedAbnormal from '@/pages/speedAbnormal';
// import AlarmSpeedAbnormal from '@/pages/alarmSpeedAbnormal';
import DriveAnalysis from '@/pages/driveAnalysis';
import CarDiagnosis from '@/pages/carDiagnosis';
import MaintenanceLogSearch from '@/pages/maintenanceLogSearch';
import AccidentReport from '@/pages/accidentReport';
import RobotPushRecord from '@/pages/robotPushRecord';
import AbnormalSummary from '@/pages/abnormalSummary';
import TagManage from '@/pages/tagManage';
import CarArchive from '@/pages/carArchive';
import DriveVideo from '@/pages/driveVideo';
import AccidentRecord from '@/pages/accidentRecord';
import OperateLog from '@/pages/operateLog';
import OperationGroupManage from '@/pages/operationGroupManage';
import ChannelAssessment from '@/pages/channelAssessment';
import DataV from '@/pages/dataV';
import CarGroupMove from '@/pages/carGroupMove';
import WorkOrder from '@/pages/workOrder';
import FleetRisk from '@/pages/fleetRisk';
import FleetRiskPush from '@/pages/fleetRiskPush';
import WorkOrderOperation from '@/pages/workOrderOperation';
import WorkOrderStatistics from '@/pages/workOrderStatistics';
import MarketingIndicators from '@/pages/marketingIndicators'
import OperationGroupAssessment from '@/pages/operationGroupAssessment';
import SignalMonitor from '@/pages/signalMonitor';
import CallRecord from '@/pages/callRecord';
import FatigueDrive from '@/pages/fatigueDrive';
import ChannelManageCost from '@/pages/channelManageCost';
import MyCar from '@/pages/myCar';
import MenuManage from '@/pages/menuManage';
import RoleManage from '@/pages/roleManage';
import OrganizationManage from '@/pages/organizationManage';
import FatalDefine from '@/pages/fatalDefine';
import MainHeader from '@/components/mainHeader';
import LeftMenu from './components/leftMenu';
import styles from './index.module.scss';

import ClientFile from '@/pages/clientFile';
import ClientFace from '@/pages/clientFace';
import ClientLearn from '@/pages/clientLearn';
import RealMonitoring from '@/pages/realMonitoring';
import AdasEquipmentManage from '@/pages/adasEquipmentManage';
import AdasAlarmSearch from '@/pages/adasAlarmSearch';
import PlaybackMonitoring from '@/pages/playbackMonitoring';
import WorkOrderCostRecord from '@/pages/workOrderCostRecord';
import DataSize from '@/pages/dataSize';
import FleetRiskMonth from '../fleetRiskMonth';
import ServiceRecord from '../serviceRecord';
import CarInterview from '@/pages/carInterview';
import ServiceTask from '@/pages/serviceTask';
import ServiceTaskDispose from '@/pages/serviceTaskDispose';
import ServiceManage from '@/pages/serviceManage';
import QuestionnaireSurvey from '@/pages/questionnaireSurvey';
import QuestionnaireSurveyTotal from '@/pages/questionnaireSurveyTotal';
import DataDepartment from '@/pages/dataDepartment';
import CarScore from '@/pages/carScore';
import ServiceCount from '@/pages/serviceCount';
import MonitoringRecord from '@/pages/monitoringRecord';
import MonitoringClientRecord from '@/pages/monitoringClientRecord';
import SafeReport from '@/pages/safeReport';
import VideoInvest from '@/pages/videoInvest'
import VideoInvustClient from '@/pages/videoInvustClient';
import EquipmentManage from '@/pages/equipmentManage';
import CarDeviceCharge from '@/pages/carDeviceCharge';
import OfferManage from '@/pages/offerManage';
import documentData from '@/pages/documentData';
import OfferRecord from '@/pages/offerRecord';
import ContractsManager from '@/pages/contractManage';
import UnderwritingRecord from '@/pages/underwritingRecord';
import CarProceed from '@/pages/carProceed';
import CarOnline from '@/pages/carOnline';
import ChannelRecode from '@/pages/channelRecode';
import PayRecode from '@/pages/payRecords';
import SurrenderRecode from '@/pages/surrenderRecord';
import InsurePayRecord from '@/pages/insurePayRecord';
import CarSitution from '@/pages/carSitution';
import InsureManage from '@/pages/insurerManage';
import CarEvaluationRecord from '@/pages/carEvaluationRecord';
import productInsureRecord from '@/pages/productInsureRecord';
import CarManages from '@/pages/carManages';
import CarDiagnose from '@/pages/carDiagnose';
import CarExploration from '@/pages/carExploration';
/**新迁移的页面start */
import DeviceOperation from '@/pages/deviceOperation';
import DriveTrack from '@/pages/driveTrack';
import GuestCarManage from '@/pages/guestCarManage';
import VideoTurning from '@/pages/videoTurning';

import AtCommand from '@/pages/atCommand';
import CarCan from '@/pages/carCan';
import RecorderFlowConfiguration from '@/pages/recorderFlowConfiguration';
import ApproveSearch from '@/pages/approveSearch';
import ParameterConfiguration from '@/pages/parameterConfiguration';
import DeviceManage from '@/pages/deviceManage';
import FirmwareManage from '@/pages/firmwareManage';

import ProjectManage from '@/pages/sensorProjectManage';
import SensorDeviceTypeManage from '@/pages/sensorDeviceTypeManage';
import SensorSupplierManage from '@/pages/sensorSupplierManage';
import SensorDeviceManage from '@/pages/sensorDeviceManage';
import UltrasonicHardware from '@/pages/sensorUltrasonicHardware';
import UltrasonicInstall from '@/pages/sensorUltrasonicInstall';
import SensorConfigManage from '@/pages/sensorConfigManage';

import DeviceInspect from '@/pages/deviceInspect';
import CrossMark from '@/pages/crossMark';
import Jt808VirtualDevice from '@/pages/jt808VirtualDevice';
import ElectronFence from '@/pages/electronFence';

import JtDevice from '@/pages/jtDevice';
import JtDeviceAlarm from '@/pages/jtDeviceAlarm';
import JtDeviceAlarmCompare from '@/pages/jtDeviceAlarmCompare';
import RealTimeMonitor from '@/pages/realTimeMonitor';
import JtDevicePlayback from '@/pages/jtDevicePlayback';
import JtDeviceUploadRule from '@/pages/jtDeviceUploadRule';
import JtDeviceUploadConfig from '@/pages/jtDeviceUploadConfig';
import {DingTalkIssues} from "@/pages/summaryOfDingTalkIssues";
import  OfferFailRecord from '../OfferFailRecord';

/**新迁移的页面end */

interface TabPagesProps {
}

const {TabPane} = Tabs;
const {Content} = Layout;
// 每一个tab对应渲染的组件
const tabComMap: any = {
  '/car-admin/home': Home,
  '/car-admin/effective-alarm-statistics': EffectiveAlarmStatistics,
  '/car-admin/time-statistics': CarTimeStatistics,
  // '/car-admin/car-manage': CarManage,
  '/car-admin/car-manage': CarManages,
  '/car-admin/car-import': CarImport,
  '/car-admin/group-manage': GroupManage,
  '/car-admin/car-location': CarLocation,
  '/car-admin/drive-analysis': DriveAnalysis,
  '/car-admin/faultCode-filter': FaultCodeFilter,
  '/car-admin/break-abnormal': BreakAbnormal,
  '/car-admin/overspeed': Overspeed,
  '/car-admin/speed-abnormal': SpeedAbnormal,
  '/car-admin/aeb-off': AebOff,
  '/car-admin/offline-day': OfflineDay,
  '/car-admin/alarm-count': AlarmCount,
  '/car-admin/user-manage': UserManage,
  '/car-admin/track-playback': TrackPlayback,
  '/car-admin/online-record': OnlineRecord,
  '/car-admin/alarm-search': AlarmSearch,
  '/car-admin/status-search': StatusSearch,
  '/car-admin/car-info': CarInfo,
  '/car-admin/car-location-query': CarLocationQuery,
  '/car-admin/online-day': OnlineDay,
  '/car-admin/car-alarm': CarAlarm,
  '/car-admin/online-no-alarm': OnlineNoAlarm,
  // '/car-admin/alarm-speed-abnormal': AlarmSpeedAbnormal,
  '/car-admin/car-diagnosis': CarDiagnose,
  // '/car-admin/car-diagnosis': CarDiagnosis,
  '/car-admin/maintenance-log': MaintenanceLogSearch,
  '/car-admin/accident-report': AccidentReport,
  '/car-admin/robot-push': RobotPushRecord,
  '/car-admin/abnormal-summary': AbnormalSummary,
  '/car-admin/tag-manage': TagManage,
  '/car-admin/accident-record': AccidentRecord,
  '/car-admin/car-archive': CarArchive,
  '/car-admin/drive-video': DriveVideo,
  '/car-admin/operate-log': OperateLog,
  '/car-admin/operation-group-manage': OperationGroupManage,
  '/car-admin/channel-assessment': ChannelAssessment,
  '/car-admin/datav': DataV,
  '/car-admin/fleet-risk': FleetRisk,
  '/car-admin/fleet-risk-push': FleetRiskPush,
  '/car-admin/car-group-move': CarGroupMove,
  '/car-admin/work-order': WorkOrder,
  '/car-admin/underwriting-record': UnderwritingRecord,
  '/car-admin/work-order-operation': WorkOrderOperation,
  '/car-admin/work-order-statistics': WorkOrderStatistics,
  '/car-admin/marketing-indicators': MarketingIndicators,
  '/car-admin/operation-group-assessment': OperationGroupAssessment,
  '/car-admin/signal-monitor': SignalMonitor,
  '/car-admin/call-record': CallRecord,
  '/car-admin/fatigue-drive': FatigueDrive,
  '/car-admin/channel-manage-cost': ChannelManageCost,
  '/car-admin/my-car': MyCar,
  '/car-admin/menu-manage': MenuManage,
  '/car-admin/role-manage': RoleManage,
  '/car-admin/organization-manage': OrganizationManage,
  '/car-admin/fatal-define': FatalDefine,

  '/car-admin/client-file': ClientFile,
  '/car-admin/client-face': ClientFace,
  '/car-admin/client-learn': ClientLearn,
  '/car-admin/real-monitoring': RealMonitoring,
  '/car-admin/adas-equipment-manage': AdasEquipmentManage,
  '/car-admin/adas-alarm-search': AdasAlarmSearch,
  '/car-admin/playback-monitoring': PlaybackMonitoring,
  '/car-admin/work-order-cost-record': WorkOrderCostRecord,
  '/car-admin/data-size': DataSize,
  '/car-admin/fleet-risk-month': FleetRiskMonth,
  '/car-admin/service-record': ServiceRecord,
  '/car-admin/car-interview': CarInterview,
  '/car-admin/service-task': ServiceTask,
  '/car-admin/service-task-dispose': ServiceTaskDispose,
  '/car-admin/service-manage': ServiceManage,
  '/car-admin/questionnaire-survey': QuestionnaireSurvey,
  '/car-admin/questionnaire-survey-total': QuestionnaireSurveyTotal,
  '/car-admin/data-department': DataDepartment,
  '/car-admin/car-score': CarScore,

  '/car-admin/service-count': ServiceCount,
  '/car-admin/monitoring-record': MonitoringRecord,
  '/car-admin/monitoring-client-record': MonitoringClientRecord,
  '/car-admin/safe-report/report': SafeReport,
  '/car-admin/video-invest': VideoInvest,
  '/car-admin/video-invest-client': VideoInvustClient,
  '/car-admin/equipment-manage': EquipmentManage,
  '/car-admin/car-device-charge': CarDeviceCharge,
  '/car-admin/offer-manage': OfferManage,
  '/car-admin/knowledge-bases': documentData,
  '/car-admin/offer-record': OfferRecord,
  '/car-admin/contracts-manager': ContractsManager,
  '/car-admin/ceshi': UnderwritingRecord,
  '/car-admin/carproceed-recode': CarProceed,
  '/car-admin/car-online': CarOnline,
  '/car-admin/channel-recode': ChannelRecode,
  '/car-admin/pay-recode': PayRecode,
  '/car-admin/surrender-recode': SurrenderRecode,
  '/car-admin/insure-pay-recode': InsurePayRecord,
  '/car-admin/drive-situation': CarSitution,
  '/car-admin/insurer-manage': InsureManage,
  '/car-admin/evaluation-list': CarEvaluationRecord,
  '/car-admin/product-insure-record': productInsureRecord,
  '/car-admin/car-manages': CarManages,
  '/car-admin/car-diagnose': CarDiagnose,
  '/car-admin/car-exploration': CarExploration,
  /**新迁移的页面start */
  '/car-admin/device-operation': DeviceOperation,
  '/car-admin/drive-track': DriveTrack,
  '/car-admin/guest-car': GuestCarManage,
  '/car-admin/video-turning': VideoTurning,

  '/car-admin/at-command': AtCommand,
  '/car-admin/car-can': CarCan,
  '/car-admin/recorder-flow-configuration': RecorderFlowConfiguration,
  '/car-admin/approve-search': ApproveSearch,
  '/car-admin/parameter-configuration': ParameterConfiguration,
  '/car-admin/device-manage': DeviceManage,
  '/car-admin/firmware-manage': FirmwareManage,

  '/car-admin/sensorProject-manage': ProjectManage,
  '/car-admin/sensorDeviceType-manage': SensorDeviceTypeManage,
  '/car-admin/sensorSupplier-manage': SensorSupplierManage,
  '/car-admin/sensorDevice-manage': SensorDeviceManage,
  '/car-admin/sensorUltrasonic-hardware': UltrasonicHardware,
  '/car-admin/sensorUltrasonic-install': UltrasonicInstall,
  '/car-admin/sensorConfig-manage': SensorConfigManage,

  '/car-admin/device-inspect': DeviceInspect,
  '/car-admin/route-mark': CrossMark,
  '/car-admin/808-virtual-device': Jt808VirtualDevice,
  '/car-admin/electron-fence': ElectronFence,

  '/car-admin/dvr-device': JtDevice,
  '/car-admin/dvr-alarm': JtDeviceAlarm,
  '/car-admin/dvr-alarm-compare': JtDeviceAlarmCompare,
  '/car-admin/dvr-monitor': RealTimeMonitor,
  '/car-admin/dvr-playback': JtDevicePlayback,
  '/car-admin/dvr-upload-rule': JtDeviceUploadRule,
  '/car-admin/dvr-upload-config': JtDeviceUploadConfig,
  '/car-admin/ding-talk-issues': DingTalkIssues,
  '/car-admin/offer-fail-record': OfferFailRecord
  /**新迁移的页面end */
}
const TabPages = (props: TabPagesProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);

  // 左侧menu的展开收起
  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  };

  // 切换tab选项
  const onTabChange = (key: string) => {
    dispatch({
      type: 'changeActive',
      payload: {key}
    })
  }

  // 删除Tab
  const onEdit = (target: any, action: string) => {
    if (action === 'remove') {
      dispatch({
        type: 'deleteTab',
        payload: {key: target}
      })
    }
  }

  return (
    <Layout className={styles.layout}>
      <MainHeader collapsed={collapsed} toggleCollapse={toggleCollapse}/>
      <Layout className={styles.layoutContent}>
        <LeftMenu
          {...props}
          collapsed={collapsed}
          toggleCollapsed={(value: boolean) => setCollapsed(value)}
        />
        <div className={styles.mainContentWrapper}>
          <Content className={styles.mainContent}>
            <Tabs
              activeKey={tabPagesStore.activeKey}
              onChange={onTabChange}
              onEdit={onEdit}
              type="editable-card"
              hideAdd
            >
              {tabPagesStore.tabPages.map((item: any, index: number) => {
                const MathCom = tabComMap[item.key]; // 当前tab匹配的组件
                return (
                  <TabPane tab={item.tab} key={item.key} closable={index !== 0}>
                    {MathCom ? <MathCom/> : null}
                  </TabPane>
                )
              })}
            </Tabs>
          </Content>
        </div>
      </Layout>
    </Layout>
  )
}

export default TabPages;
