// extracted by mini-css-extract-plugin
export default {"reportBox":"offerManage_reportBox__ifYqh","searchBox":"offerManage_searchBox__bu4nK","analysisMap":"offerManage_analysisMap__pAKvX","innerTitle":"offerManage_innerTitle__O6FYy","tableOperateColumn":"offerManage_tableOperateColumn__cjON3"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"reportBox\":\"offerManage_reportBox__ifYqh\",\"searchBox\":\"offerManage_searchBox__bu4nK\",\"analysisMap\":\"offerManage_analysisMap__pAKvX\",\"innerTitle\":\"offerManage_innerTitle__O6FYy\",\"tableOperateColumn\":\"offerManage_tableOperateColumn__cjON3\"}";
        // 1731660723638
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  