// extracted by mini-css-extract-plugin
export default {"reportBox":"accidentReport_reportBox__BKo7I","searchBox":"accidentReport_searchBox__gifVb","analysisMap":"accidentReport_analysisMap__IImUH","innerTitle":"accidentReport_innerTitle__Y9hXM","tableOperateColumn":"accidentReport_tableOperateColumn__QongG"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"reportBox\":\"accidentReport_reportBox__BKo7I\",\"searchBox\":\"accidentReport_searchBox__gifVb\",\"analysisMap\":\"accidentReport_analysisMap__IImUH\",\"innerTitle\":\"accidentReport_innerTitle__Y9hXM\",\"tableOperateColumn\":\"accidentReport_tableOperateColumn__QongG\"}";
        // 1731660699423
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  