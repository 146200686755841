// extracted by mini-css-extract-plugin
export default {"ultrasonicLocationBox":"sensorUltrasonicLocation_ultrasonicLocationBox__etLyZ","leftBox":"sensorUltrasonicLocation_leftBox__jUrQU","rightBox":"sensorUltrasonicLocation_rightBox__9ULO7","selectItemOne":"sensorUltrasonicLocation_selectItemOne__8lb5y","selectItemTwo":"sensorUltrasonicLocation_selectItemTwo__BP3Qt","selectItemThree":"sensorUltrasonicLocation_selectItemThree__Xtt4r","selectItemFour":"sensorUltrasonicLocation_selectItemFour__bmdfi","selectItemFive":"sensorUltrasonicLocation_selectItemFive__UYxij","selectItemSix":"sensorUltrasonicLocation_selectItemSix__e5buk","middleBox":"sensorUltrasonicLocation_middleBox__hFPj-","middleImage":"sensorUltrasonicLocation_middleImage__+KiQZ","middleTop":"sensorUltrasonicLocation_middleTop__rzraV","line":"sensorUltrasonicLocation_line__GtQ4d","middleBottom":"sensorUltrasonicLocation_middleBottom__49mSE","middleText":"sensorUltrasonicLocation_middleText__-QqZ4","selectItem":"sensorUltrasonicLocation_selectItem__5YslE","selectItemColumn":"sensorUltrasonicLocation_selectItemColumn__4I0ok","displaySelect":"sensorUltrasonicLocation_displaySelect__gQMiT","point":"sensorUltrasonicLocation_point__7bIZ7"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"ultrasonicLocationBox\":\"sensorUltrasonicLocation_ultrasonicLocationBox__etLyZ\",\"leftBox\":\"sensorUltrasonicLocation_leftBox__jUrQU\",\"rightBox\":\"sensorUltrasonicLocation_rightBox__9ULO7\",\"selectItemOne\":\"sensorUltrasonicLocation_selectItemOne__8lb5y\",\"selectItemTwo\":\"sensorUltrasonicLocation_selectItemTwo__BP3Qt\",\"selectItemThree\":\"sensorUltrasonicLocation_selectItemThree__Xtt4r\",\"selectItemFour\":\"sensorUltrasonicLocation_selectItemFour__bmdfi\",\"selectItemFive\":\"sensorUltrasonicLocation_selectItemFive__UYxij\",\"selectItemSix\":\"sensorUltrasonicLocation_selectItemSix__e5buk\",\"middleBox\":\"sensorUltrasonicLocation_middleBox__hFPj-\",\"middleImage\":\"sensorUltrasonicLocation_middleImage__+KiQZ\",\"middleTop\":\"sensorUltrasonicLocation_middleTop__rzraV\",\"line\":\"sensorUltrasonicLocation_line__GtQ4d\",\"middleBottom\":\"sensorUltrasonicLocation_middleBottom__49mSE\",\"middleText\":\"sensorUltrasonicLocation_middleText__-QqZ4\",\"selectItem\":\"sensorUltrasonicLocation_selectItem__5YslE\",\"selectItemColumn\":\"sensorUltrasonicLocation_selectItemColumn__4I0ok\",\"displaySelect\":\"sensorUltrasonicLocation_displaySelect__gQMiT\",\"point\":\"sensorUltrasonicLocation_point__7bIZ7\"}";
        // 1731660758041
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  