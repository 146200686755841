/**
 * 车辆情况
 */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Form, Row, Col, Button, Input, message } from "antd";
import dayjs from "dayjs";
import gcoord from "gcoord";
import { carDetailV2Request, calloutCarRecoed, customerRecordCountRequest, diagnosisCountRequest, companyCountRequest } from "@/service/carDiagnosis";
import { carListRequest } from "@/service/carManage";
import { operationGroupListRequest } from "@/service/operationGroupManage";
import DatePicker from "@/components/datePicker";
import CarListModal from "@/components/carListModal";
import { copyLocationLink } from "@/utils/location";
import { importMap } from "@/utils/map";
import { getInnerPermissionEnable } from "@/utils/permission";
import localStorageUtil from "@/utils/localStorageUtil";
import {useUserCateIdArr} from "@/utils/ownHooks";
import { isKlsAeb, isKlsV3Aeb } from '@/utils/device';
import CarDiagnosisTable from "@/pages/carDiagnose/components/carDiagnosisTable";
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;
const CarDiagnosis = () => {
  const cateIdArr = useUserCateIdArr();
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  let mapRef: any = useRef(null); // 存储地图实例
  let markerRef: any = useRef(null); // 存储车辆点位实例
  let timerRef: any = useRef(null); // 存储获取车辆位置的定时器
  let heartTimerRef: any = useRef(null); // 存储获取心跳的定时器
  const [carInfo, setCarInfo] = useState<any>({}); // 车辆信息
  const [hasSearch, setHasSearch] = useState(false); // 是否搜索过
  const [searchValues, setSearchValues] = useState<any>(null); // 搜索到多辆车时的搜索参数
  const [diagnosisSearchParams, setDiagnosisSearchParams] = useState<any>(null); // 获取车辆诊断表格数据的搜索参数
  const [operationGroupList, setOperationGroupList] = useState<any>([]); // 运维组列表数据
  const [CustomerRecordCount, setCustomerRecordCount] = useState<any>([]); // 客服记录次数
  const [DiagnosisCount, setDiagnosisCount] = useState<any>([]); // 客服记录次数
  const [CompanyRecordCount, setCompanyRecordCount] = useState<any>([]); // 走访次数

  const [form] = Form.useForm();
  let call1State = useRef(false);
  let call2State = useRef(false);
  let carCalling = useRef(null);
  let callId = useRef(null);

  // 是否是昆仑山设备
  const deviceType = carInfo?.device_type || carInfo?.deviceType;
  // 是否是昆仑山设备
  const isKls = isKlsAeb(carInfo?.deviceTypeCategory);
  // 是否是昆仑山3.0设备
  const isKlsV3 = isKlsV3Aeb(carInfo?.deviceTypeCategory);

  // 组件销毁时清楚定时器
  useEffect(() => {
    getOperationGroupList();
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
      heartTimerRef.current && clearTimeout(heartTimerRef.current);
    };
  }, []);

  // 监听跳转时携带参数的形式
  useEffect(() => {
    tabParamsSearch();
  }, [activeKey, tabParams]);

  // 获取运维组列表
  const getOperationGroupList = async () => {
    const res = await operationGroupListRequest();
    setOperationGroupList(res.data?.data || []);
  };

  // 跳转时携带参数时搜索
  const tabParamsSearch = async () => {
    let mapSuccess: any = false;
    if (window.BMap && mapRef.current) {
      mapSuccess = true;
    } else {
      mapSuccess = await importMap(initMapCallback);
    }
    if (mapSuccess && activeKey === "/car-admin/car-diagnosis" && tabParams) {
      const newTabParams = {
        plateNumber: tabParams.plateNumber,
        sn: "",
        frameNumber: "",
        time: [dayjs(tabParams.beginDate), dayjs(tabParams.endDate)],
      };
      form.setFieldsValue(newTabParams);
      form.submit();
    }
  };

  // 地图初始化成功的回调
  const initMapCallback = () => {
    mapRef.current = new window.BMap.Map("carMap");
    const top_left_control = new window.BMap.ScaleControl({
      anchor: window.BMAP_ANCHOR_TOP_LEFT,
    });
    const top_left_navigation = new window.BMap.NavigationControl({
      anchor: window.BMAP_ANCHOR_TOP_LEFT,
    });
    mapRef.current.addControl(top_left_control); // 左上角添加比例尺控件
    mapRef.current.addControl(top_left_navigation); // 左上角添加缩放控件
    mapRef.current.enableScrollWheelZoom(); // 开启鼠标滚轮缩放
    mapRef.current.addControl(
      new window.BMap.MapTypeControl({
        mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
      })
    ); // 添加卫星图层
    const mapCenter = new window.BMap.Point(116.404, 39.915);
    mapRef.current.centerAndZoom(mapCenter, 16);
  };

  // 获取匹配搜索的车辆
  const getMatchCar = async (params: any) => {
    const newParams = {
      page: 1,
      limit: 10,
      plate_number: params.plateNumber,
      sn: params.sn,
      frameNumber: params.frameNumber,
      cate_id: cateIdArr
    };
    const res = await carListRequest(newParams);
    const matchCar = res.data?.data || [];
    if (matchCar.length === 0) {
      message.warning("车辆不存在");
      setCarInfo({});
      setDiagnosisSearchParams(null);
      getCarLocation();
      timerRef.current && clearTimeout(timerRef.current);
      heartTimerRef.current && clearTimeout(heartTimerRef.current);
    } else if (matchCar.length === 1) {
      // 只匹配到一辆车时获取该车信息
      plateNumberClick(matchCar[0], params);
    } else {
      // 获取到多辆车时显示车辆列表进行选择
      setSearchValues(params);
    }
  };
  
  // 获取车辆位置信息
  const getCarLocation = async (id?: string) => {
    if (id) {
      const car_path =
        "M689.7 217.7c-7.2-5.6-14.3-10.2-20.3-13.2 0.4-5.1 0.7-10.2 1.1-15.3 0.1 0.3 0.1 0.6 0.2 0.9 0.3-4.9 0.7-9.8 1.1-14.7 3.2-35.8-24.1-80.1-61.5-90.3-65.2-17.1-130.4-17.1-195.7 0.2-37.4 10.2-64.6 54.5-61.5 90.3 0.4 4.9 0.8 9.8 1.1 14.7 0.1-0.3 0.1-0.6 0.2-0.9 0.4 5.1 0.8 10.2 1.1 15.3-6 3-13.1 7.5-20.3 13.2-16.6 13.1-27.4 27.1-24.1 31.3 3.3 4.2 19.5-3 36.1-16.1 3.5-2.8 6.7-5.6 9.7-8.3 4.6 70.5 7.1 141.1 7.5 211.6v2.2c0.1 1.3 0.6 4.3 4.4 4.6h286.5c0.2 0 0.4-0.1 0.6-0.1 3.5-0.3 4.3-2.9 4.4-4.3v-2.4c0.4-70.5 2.9-141.1 7.6-211.7 2.9 2.7 6.2 5.5 9.7 8.3 16.6 13.1 32.8 20.3 36.1 16.1 3.4-4.3-7.4-18.3-24-31.4z m-89.5-104.3c3.8-4.8 17.6-0.3 30.8 10.1s20.9 22.7 17.1 27.5c-3.8 4.8-17.6 0.3-30.8-10.1-13.3-10.4-20.9-22.7-17.1-27.5zM394 123.5c13.2-10.4 27-14.9 30.8-10.1 3.8 4.8-3.9 17.1-17.1 27.5-13.2 10.4-27 14.9-30.8 10.1-3.8-4.8 3.9-17.1 17.1-27.5z m-24.1 263.2v-0.4L363.8 231l2.4 4.2c12.1 21.1 17.1 46.6 21.1 67 5.3 27.7 8.2 61.8 9.2 107.4l0.1 2.8-26.7-25.7zM402 330l-9.7-99.9 0.6-0.5c10.4-8.8 21.7-16.4 33.5-22.8 26.4-14 56.2-21.4 86.1-21.4 17.9 0 35.6 2.6 52.6 7.7 14 4.2 27.5 10 40.2 17.5 1.1 0.6 2.1 1.2 3.2 1.9 8.3 5.1 16.3 10.8 23.7 17.1l0.6 0.5L623 330H402z m253.1 56.3v0.4l-26.7 25.7 0.1-2.8c1-45.6 3.9-79.7 9.2-107.4 4-20.5 9-45.9 21.1-67l2.4-4.2-6.1 155.3zM680.5 455.9h-334c-3.9 0-7.1 3.2-7.1 7.1v482.2c0 3.9 3.2 7.1 7.1 7.1h334.1c3.9 0 7.1-3.2 7.1-7.1V463c0-3.9-3.2-7.1-7.2-7.1zM421.8 910.1c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z m66.7 0c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z m66.8 0c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z m66.7 0c0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4v-412c0-4.6 3.8-8.4 8.4-8.4 4.6 0 8.4 3.8 8.4 8.4v412z";
      const car_online = new window.BMap.Symbol(car_path, {
        fillColor: "#22c306",
        fillOpacity: 1,
        scale: 0.036,
      });
      const res = await carDetailV2Request(id);
      const carLocationData = res.data?.data || {};
      if (carLocationData.lat && carLocationData.lon) {
        // 有车辆位置信息显示车辆位置信息
        const transformGcoord = gcoord.transform(
          [carLocationData.lat, carLocationData.lon],
          gcoord.WGS84,
          gcoord.BD09
        );
        const carPoint = new window.BMap.Point(
          transformGcoord[0],
          transformGcoord[1]
        );
        // 如果之前有marker则清除
        if (markerRef.current) {
          mapRef.current.removeOverlay(markerRef.current);
        }
        mapRef.current.centerAndZoom(carPoint, 16);
        markerRef.current = new window.BMap.Marker(carPoint, {
          title: carLocationData.plate_number,
          icon: car_online,
          offset: new window.BMap.Size(-15, -15),
        });
        mapRef.current.addOverlay(markerRef.current);
        markerRef.current.addEventListener("click", function () {
          markClick(carLocationData, transformGcoord, carPoint);
        });
        markClick(carLocationData, transformGcoord, carPoint);
      } else {
        // 没有车辆位置时的显示
        const carPoint = new window.BMap.Point(116.404, 39.915);
        mapRef.current.centerAndZoom(carPoint, 16);
      }
      // setTimeout(() => getCarAroundData(), 2000)
      // 轮询调用最新位置
      timerRef.current = setTimeout(() => {
        getCarLocation(id);
      }, 30000);
    } else {
      // 没有车辆位置时的显示
      const carPoint = new window.BMap.Point(116.404, 39.915);
      mapRef.current.centerAndZoom(carPoint, 16);
    }
  };

  // 根据匹配车辆的车牌号进行搜索
  const plateNumberClick = (data: any, searchData?: any) => {
    timerRef.current && clearTimeout(timerRef.current);
    heartTimerRef.current && clearTimeout(heartTimerRef.current);
    form.setFieldsValue({
      plateNumber: data.plate_number,
      sn: "",
      frameNumber: "",
    });
    const params = {
      beginDate: (searchData || searchValues).beginDate,
      endDate: (searchData || searchValues).endDate,
      plateNumber: data.plate_number,
    };
    setDiagnosisSearchParams(params);
    setCarInfo(data);
    getCarLocation(data?.id);
    setHasSearch(true);
    getCustomerRecordCount(params)
  };

  // 获取次数
  const getCustomerRecordCount = async (params:any) => {
    const res = await customerRecordCountRequest(params);
    const ress = await diagnosisCountRequest(params);
    const resss = await companyCountRequest(params);
    if (res.data?.code === 0) {
      setCustomerRecordCount(res.data.data);
    }
    if (ress.data?.code === 0) {
      setDiagnosisCount(ress.data.data);
    }
    if (resss.data?.code === 0) {
     setCompanyRecordCount(resss.data.data);
    }
  }
  
  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    delete newParams.time;
    delete newParams.plateNumber; // 出现连续两次请求，保留以前的车牌号的情况
    newParams.beginDate = dayjs(params.time[0]).format("YYYY-MM-DD");
    newParams.endDate = dayjs(params.time[1]).format("YYYY-MM-DD");
    newParams.plateNumber = (params.plateNumber || "").trim();
    //发送请求
    
    if (isExport) {
      newParams.export = true;
    }
    if (newParams.plateNumber || newParams.sn || newParams.frameNumber) {
      return newParams;
    }
  };

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams = operateSearchParams(values);
    if (newSearchParams) {
      getMatchCar(newSearchParams);
    } else {
      message.warning("请输入车牌号或设备SN或车架号进行搜索");
    }
  };

  // 点击车辆图标显示信息
  const markClick = (
    carLocationData: any,
    transformGcoord: any,
    carPoint: any
  ) => {
    const { plate_number, speed, gpsTime } = carLocationData;
    const carInfoWindow = new window.BMap.InfoWindow("", {
      title: "车辆位置信息",
      width: 400,
    });
    const locationLink = `http://api.map.baidu.com/marker?location=${transformGcoord[1]},${transformGcoord[0]}&title=${plate_number}&content=${plate_number}&output=html`;
    const content = `车牌号：${plate_number}<br>GPS速度: ${speed}<br>上报时间: ${
      gpsTime ? dayjs(gpsTime).format("YYYY-MM-DD HH:mm:ss") : gpsTime
    }<br>地址链接: <a target='_blank' href="${locationLink}">${locationLink}</a><button id='copyLinkButton'>复制</button>`;
    carInfoWindow.setContent(content);
    mapRef.current.openInfoWindow(carInfoWindow, carPoint);
    copyLocationLink(locationLink, carInfoWindow);
  };

  // 渲染车辆信息
  const renderCarInfo = (key: string) => {
    const data = carInfo;
    const value = data[key];
    // 渲染运维组
    if (key === "operateGroup") {
      const matchGroup = operationGroupList.find((item: any) =>
        (item.cateId || []).includes(carInfo.cate_id)
      );
      return matchGroup?.title || "-";
    }
    return value;
  };

  // 是否显示运维组信息
  const showOperationGroup = getInnerPermissionEnable(
    "/car-admin/car-diagnosis/show-operation-group"
  );

  // 要展示的车辆信息
  const showCarInfo = [
    { title: "车牌号", key: "plate_number", span: 24 },
    {title: "分组",key: "cate_path_name",span: 24},
    { title: "车架号", key: "frame_number", span: 24 },
    { title: "安装日期", key: "installTime", span: 24 },
    { title: "客服联系次数", key: "customerRecordCount", span: 24 },
    { title: "线下运维次数", key: "diagnosisCount", span: 25 },
    { title: "线下走访次数", key: "recordCount", span: 24 },
  ];

  //@ts-ignore
  let AppDataProviderBase = UdeskCCPaasApiClass.AppDataProvider;
  class AppDataProvider extends AppDataProviderBase {
    GetToken() {
      return new Promise((resolve, reject) => {
        //发送请求获取鉴权信息
        $.ajax({
          url: "https://iov.anchezhixing.com/api/v2/callRecord/token", //客户提供相应后端接口;负责生成token给前端
          type: "GET",
          headers: {
            access_token: localStorageUtil.getItem("access_token"),
          },
        }).then((response: any) => {
          resolve(response.data);
        });
      });
    }
  }
  // s4环境示例
  //@ts-ignore
  let UdeskCCPaasApiClassInstance = new UdeskCCPaasApiClass({
    AgentId: localStorage?.getItem("code"),
    AppId: "fc0e16c8-f7ac-4f29-7315-40fa6e1cf116",
    AppDataProvider: new AppDataProvider(),
    Domain: "wss://tower-xcc1.s4.udesk.cn:443/ws",
    HttpDomain: "https://apigw-xcc1.s4.udesk.cn:443/backend",
    Log: function (message: any, type: any) {},
  });
  UdeskCCPaasApiClassInstance.Init();
  UdeskCCPaasApiClassInstance.ConnectAsync().then((resp: any) => {});
  UdeskCCPaasApiClassInstance.on("Originated", function (resp: any) {
    if (resp.Data.CallID != null) {
      callId.current = resp.Data.CallID;
    }
  });
  UdeskCCPaasApiClassInstance.on("ConnectionCleared", function (resp: any) {
    if (
      !(typeof carCalling.current == undefined || carCalling.current == null)
    ) {
      const params = {
        carId: carCalling.current,
        callId: resp.Data.CallID,
        flag: true,
      };
      console.log("提交的关联参数", params);
      calloutCarRecoed(params);
    }
    carCalling.current = null;
    // 设置按钮状态....
    call1State.current = false;
    call2State.current = false;
  });

  return (
    <Card bordered={false} className={styles.carDiagnosis}>
      <Form
        onFinish={onSearch}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={24} wrap>
          <Col lg={5} xs={14}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={14}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[
                dayjs().subtract(7, "day"),
                dayjs().subtract(1, "day"),
              ]}
            >
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ visibility: hasSearch ? "visible" : "hidden" }}>
        <Row>
          <Col span={10} className={styles.carInfoBox}>
            <Row wrap gutter={12}>
              <Col span={showCarInfo[0].span} key={showCarInfo[0].title}>
                <div className={styles.carInfoTitle}>
                  {showCarInfo[0].title}
                </div>
                <div className={styles.carInfoContent}>
                  {renderCarInfo(showCarInfo[0].key)}
                </div>
              </Col>
              {showCarInfo[1].key == "operateGroup" &&
              !showOperationGroup ? null : (
                <Col span={showCarInfo[1].span} key={showCarInfo[1].title}>
                  <div className={styles.carInfoTitle}>
                    {showCarInfo[1].title}
                  </div>
                  <div className={styles.carInfoContent}>
                    {renderCarInfo(showCarInfo[1].key)}
                  </div>
                </Col>
              )}
              <Col span={showCarInfo[2].span} key={showCarInfo[2].title}>
                <div className={styles.carInfoTitle}>
                  {showCarInfo[2].title}
                </div>
                <div className={styles.carInfoContent}>
                  {renderCarInfo(showCarInfo[2].key)}
                </div>
              </Col>
              <Col span={showCarInfo[3].span} key={showCarInfo[3].title}>
                <div className={styles.carInfoTitle}>
                  {showCarInfo[3].title}
                </div>
                <div className={styles.carInfoContent}>
                  {renderCarInfo(showCarInfo[3].key)}
                </div>
              </Col>
              <Col span={showCarInfo[4].span} key={showCarInfo[4].title}>
                <div className={styles.carInfoTitle}>
                  {showCarInfo[4].title}
                </div>
                <div className={styles.carInfoContent}>
                    {CustomerRecordCount}
                </div>
              </Col>
              <Col span={showCarInfo[5].span} key={showCarInfo[5].title}>
                <div className={styles.carInfoTitle}>
                  {showCarInfo[5].title}
                </div>
                <div className={styles.carInfoContent}>
                    {DiagnosisCount}
                </div>
              </Col>
              <Col span={showCarInfo[6].span} key={showCarInfo[6].title}>
                <div className={styles.carInfoTitle}>
                  {showCarInfo[6].title}
                </div>
                <div className={styles.carInfoContent}>
                   {CompanyRecordCount}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={14}>
            <div id="carMap" className={styles.carMap}></div>
          </Col>
        </Row>
        <CarDiagnosisTable
          isKls={isKls}
          isKlsV3={isKlsV3}
          diagnosisSearchParams={diagnosisSearchParams}
          hasModem={Boolean(carInfo?.hasModem)}
          hasJtDevice={Boolean(carInfo?.jt808DeviceId)}
        />
      </div>
      {searchValues && (
        <CarListModal
          desc="当前搜索匹配到多辆车辆，点击车牌可进行精准搜索"
          closeModal={() => setSearchValues(null)}
          searchValues={searchValues}
          plateNumberClick={(data: any) => plateNumberClick(data)}
        />
      )}
    </Card>
  );
};

export default CarDiagnosis;
