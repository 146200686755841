import React, { useEffect } from "react";
import qs from "qs";
import { ftpResourceRequestSingle } from "@/service/jtDevicePlayback";
import { streamTransfer } from "../jtDevicePlayback/components/ftpResourceTable";

export const PlayVideo = () => {
	const params = qs.parse(window.location.search.split('?')[1])
	console.log(params)

	ftpResourceRequestSingle({...params}).then((res) => {
		const data = res.data
		if (data.code == 0) {
			if (data?.data?.first == '4') {
				fetch(data?.data?.second)
				.then((res) => {
					if (!res.body) {
						throw new Error("没有视频");
					}
					// return res.body;
					return res.arrayBuffer()
				})
				.then((stream) => {
					console.log(stream, 'streamstreamstream')
					streamTransfer(stream, 'playbackVideo')
				});
			} else {
				const videoElement = document.getElementById(
					'playbackVideo'
				) as HTMLMediaElement;
				videoElement.src = data?.data?.second as any as string || '';
				videoElement?.play();
			}
		}
	})

  return (
    <>
      <div style={{ height: "calc(100vh - 20px)", width: "100vw" }}>
        <video id="playbackVideo" src="" width="100%" height="100%" controls></video>
      </div>
    </>
  );
};
