/**
 * 地图监控区域
 */
import React, {useEffect, useRef, useState} from 'react';
import gcoord from 'gcoord';
import carIcon from '@/assets/image/carIcon.png';
import onlineCarIcon from '@/assets/image/onlineCarIcon.png';
import abnormalCarIcon from '@/assets/image/abnormalCarIcon.png';
import settingPng from '@/assets/image/setting.png';
import interphonePng from '@/assets/image/interphone.png';
import ttsPng from '@/assets/image/tts.png';
import {importAliMap, initMap} from '@/utils/map';
import {registerClipboard} from '@/utils/common';
import {formatTime} from '@/utils/formatTime';
import {useNotFirstRenderEffect} from '@/utils/ownHooks';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';
import {getInnerPermissionEnable} from '@/utils/permission';
import {formatGpsSpeed} from '@/utils/gps';
import {carDetailRequest, carLastLocationRequest} from '@/service/carManage';
import ResizableWrapper from '@/components/resizableWrapper';
import InterphoneModal from '../interphoneModal';
import TtsModal from '../ttsModal';
import ParamsConfigModal from '@/pages/jtDevice/components/paramsConfigModal';
import {Switch} from "antd";
import styles from "@/pages/carDiagnose/index.module.scss";

interface MapMonitorProps {
  carId: number | null;
  carData?: {[key: string]: any} | null | undefined
}

const MapMonitor = (props: MapMonitorProps) => {
  const {carId, carData = {}} = props;
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 存储地图实例
  const markerRef: any = useRef(null); // 存储车辆点位实例
  const timerRef: any = useRef(null); // 存储定时获取车辆位置的定时器
  const [operateParams, setOperateParams] = useState<any>({}); // 操作时对应的数据
  const [isMultipleDevices, setIsMultipleDevices] = useState(false); // 是否多选
  const [isAdasMap, setIsAdasMap] = useState(false); // 是否ADAS地图
  // 清空定时获取车辆位置的定时器
  const clearTimeRef = () => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  useEffect(() => {
    importAliMap(() => initMap(mapDomRef, mapRef));
    return () => {
      clearTimeRef();
    };
  }, []);

  useNotFirstRenderEffect(() => {
    timerRef.current && clearInterval(timerRef.current);
    if (carId) {
      getCarLocation(carId);
    } else {
      mapRef.current.clearMap();
    }
  }, [carId,isAdasMap]);


  // 点击车辆图标展示信息窗体
  const openInfo = (
    carLocationData: any,
    transformGcoord: any,
    marker: any,
    showDriverInfo: boolean
  ) => {
    let {
      acc,
      plate_number,
      speed,
      reportTime:gpsTime,
      frame_number,
      cate_path_name,
      car_principal,
      car_principal_phone,
      car_driver,
      car_driver_phone,
      terminalPhone,
      jt808DeviceId,
      version,
      jt808IsOnline,
      channel
    } = carLocationData;
    version = carData?.version || version
    terminalPhone = carData?.terminalPhone || terminalPhone
    const locationLink = `https://uri.amap.com/marker?position=${transformGcoord[0]},${transformGcoord[1]}&name=${plate_number}`;
    let content = `
      <div style="width: 220px; word-break: break-word; font-size: 12px">
        <b>${plate_number}</b>
        <div>GPS速度: ${formatGpsSpeed(speed)}</div>
        <div>上报时间: ${formatTime(gpsTime)}</div>
        <div>设备状态信息：ACC:${acc ? '开' : '关'}</div>
        <div>车架号: ${frame_number}</div>
        <div>分组: ${channel}</div>`;
    if (showDriverInfo) {
      // 展示司机详细信息
      if (car_principal) {
        content += `<div>负责人: ${car_principal}</div>`;
      }
      if (car_principal_phone) {
        content += `<div>负责人电话: ${car_principal_phone}</div>`;
      }
      if (car_driver) {
        content += `<div>司机: ${car_driver}</div>`;
      }
      if (car_driver_phone) {
        content += `<div>司机电话: ${car_driver_phone}</div>`;
      }
    }
    content += `<div>
      地址链接: <a target='_blank' href="${locationLink}">跳转到地址链接</a>
      <button data-clipboard-text=${locationLink} id="realTimeMonitorCopyButton">复制链接</button>
    </div>`;
    // 安装部标机设备且部标机在线时显示部标机相关操作
    if (jt808DeviceId && jt808IsOnline) {
      // 部标机相关操作的方法
      window.realTimeMonitorMapClick = (type: string) => {
        setOperateParams({
          type,
          data: {phone: terminalPhone, jt808DeviceId, version}
        });
      };
      content += `<div>`;
      if (
        getInnerPermissionEnable('/car-admin/realTime-monitor/configuration') ||
        getInnerPermissionEnable('/car-admin/dvr-monitor/configuration')
      ) {
        content += `<img src=${settingPng} style="cursor: pointer" title="参数配置" onclick="realTimeMonitorMapClick('configuration')" />&nbsp;`;
      }
      if (
        getInnerPermissionEnable('/car-admin/realTime-monitor/interphone') ||
        getInnerPermissionEnable('/car-admin/dvr-monitor/interphone')
      ) {
        content += `<img src=${interphonePng} style="cursor: pointer" title="双向对讲" onclick="realTimeMonitorMapClick('interphone')" />&nbsp;&nbsp;`;
      }
      if (
        getInnerPermissionEnable('/car-admin/realTime-monitor/tts') ||
        getInnerPermissionEnable('/car-admin/dvr-monitor/tts')
      ) {
        content += `<img src=${ttsPng} style="cursor: pointer" title="TTS语音" onclick="realTimeMonitorMapClick('tts')" />&nbsp;`;
      }
      content += '</div>';
    }
    content += '</div>';
    const mapInfoWindow = new window.AMap.InfoWindow({
      content,
      offset: [16, 0]
    });
    mapInfoWindow.open(mapRef.current, marker.getPosition());
    registerClipboard('realTimeMonitorCopyButton');
  };

  // // 获取车辆位置信息原函数，后续版本删除
  // const getCarLocation1 = async (id: number) => {
  //   const res = await carDetailRequest(id);
  //   const resData = res.data?.data || {};
  //   const isMultipleDevices = !!(resData.deviceId && resData.jt808DeviceId)
  //   setIsMultipleDevices(isMultipleDevices);
  //
  //   //新接口获取车辆位置
  //
  //   const carLocationData = res.data?.data || {};
  //   const {lat, lon, online, jt808DeviceId, jt808IsOnline, acc} =
  //     carLocationData;
  //   // 如果之前有marker则清除
  //   if (markerRef.current) {
  //     mapRef.current.remove(markerRef.current);
  //   }
  //   // 实时监控页面参数设置
  //   const {positionInterval = 30, showDriverInfo = false} =
  //     localStorageUtil.getItem(
  //       LocalStorageKeyEnum.real_time_monitor_setting,
  //       {}
  //     );
  //   if (lat && lon) {
  //     // 有车辆位置信息显示车辆位置信息
  //     const transformGcoord = gcoord.transform(
  //       [lat, lon],
  //       gcoord.WGS84,
  //       gcoord.AMap
  //     );
  //     const markerPosition = [transformGcoord[0], transformGcoord[1]];
  //     // 地图中心移动到marker位置
  //     mapRef.current.setCenter(markerPosition);
  //     // 车辆图标
  //     let matchIcon = carIcon;
  //     if (jt808DeviceId) {
  //       // 安装部标机车辆
  //       if (jt808IsOnline) {
  //         if (acc) {
  //           // 在线且视频可播放
  //           matchIcon = onlineCarIcon;
  //         } else {
  //           // 在线但视频可播放
  //           matchIcon = abnormalCarIcon;
  //         }
  //       }
  //     } else if (online === '是') {
  //       // 没有安装部标机车辆在线
  //       matchIcon = onlineCarIcon;
  //     }
  //     const mapCarIcon = new window.AMap.Icon({
  //       image: matchIcon,
  //       imageSize: [32, 32]
  //     });
  //     markerRef.current = new window.AMap.Marker({
  //       map: mapRef.current,
  //       position: markerPosition,
  //       icon: mapCarIcon
  //     });
  //     markerRef.current.on('click', () =>
  //       openInfo(
  //         carLocationData,
  //         transformGcoord,
  //         markerRef.current,
  //         showDriverInfo
  //       )
  //     );
  //     openInfo(
  //       carLocationData,
  //       transformGcoord,
  //       markerRef.current,
  //       showDriverInfo
  //     );
  //   }
  //   // 轮询调用最新位置
  //   timerRef.current = setTimeout(() => {
  //     getCarLocation(id);
  //   }, positionInterval * 1000);
  // };

  // 获取车辆位置信息
  const getCarLocation = async (id: number) => {
    const res = await carDetailRequest(id);
    const resData = res.data?.data || {};

    const isMultipleDevices = !!(resData.deviceId && resData.jt808DeviceId)
    setIsMultipleDevices(isMultipleDevices);
    //新接口获取车辆位置
    const lastLocationParams = {
      carId: resData.id,
      plateNumber: resData.plate_number,
      //是不是昆仑山这个字段判断依据：
      // 如果是既有aeb设备又有adas设备，那就根据切换开关的值来确定，
      // 否则就是有设备id的情况下视为kls设备（即aeb）
      isKls: isMultipleDevices ? !isAdasMap : !!(resData.deviceId),
    };
    const locationRes = await carLastLocationRequest(lastLocationParams);
    const locationData = locationRes.data?.data || {};


    //把数据拼回原来的对象里，兼容之前的代码
    const carLocationData = res.data?.data || {};
    const {
      // lat, lon,
      online, jt808DeviceId, jt808IsOnline, acc
    } = carLocationData;
    const {lat, lon,reportTime} = locationData
    const totalLocationData = {
      ...carLocationData,
      lat,
      lon,
       reportTime,
    }


    // 如果之前有marker则清除
    if (markerRef.current) {
      mapRef.current.remove(markerRef.current);
    }
    // 实时监控页面参数设置
    const {positionInterval = 30, showDriverInfo = false} =
      localStorageUtil.getItem(
        LocalStorageKeyEnum.real_time_monitor_setting,
        {}
      );
    if (lat && lon) {
      // 有车辆位置信息显示车辆位置信息
      const transformGcoord = gcoord.transform(
        [lat, lon],
        gcoord.WGS84,
        gcoord.AMap
      );
      const markerPosition = [transformGcoord[0], transformGcoord[1]];
      // 地图中心移动到marker位置
      mapRef.current.setCenter(markerPosition);
      // 车辆图标
      let matchIcon = carIcon;
      if (jt808DeviceId) {
        // 安装部标机车辆
        if (jt808IsOnline) {
          if (acc) {
            // 在线且视频可播放
            matchIcon = onlineCarIcon;
          } else {
            // 在线但视频可播放
            matchIcon = abnormalCarIcon;
          }
        }
      } else if (online === '是') {
        // 没有安装部标机车辆在线
        matchIcon = onlineCarIcon;
      }
      const mapCarIcon = new window.AMap.Icon({
        image: matchIcon,
        imageSize: [32, 32]
      });
      markerRef.current = new window.AMap.Marker({
        map: mapRef.current,
        position: markerPosition,
        icon: mapCarIcon
      });
      markerRef.current.on('click', () =>
        openInfo(
          totalLocationData,
          // carLocationData,
          transformGcoord,
          markerRef.current,
          showDriverInfo
        )
      );
      openInfo(
        totalLocationData,
        // carLocationData,
        transformGcoord,
        markerRef.current,
        showDriverInfo
      );
    }
    // 轮询调用最新位置
    timerRef.current = setTimeout(() => {
      getCarLocation(id);
    }, positionInterval * 1000);
  };



  return (
    <ResizableWrapper
      resizeHandleDirection="left"
      defaultSizeStyle={{width: 300, height: '100%'}}
    >
      <div>
        {
          isMultipleDevices && (
            <div style={{display: 'flex', padding: '12px'}}>
              <span style={{marginRight: '20px'}}>地图</span>
              <Switch className={styles.switchTitle} checkedChildren="ADAS" unCheckedChildren="AEB" onChange={(value)=>setIsAdasMap(value)}/>
            </div>
          )
        }
        <div ref={mapDomRef}
             style={{
               height: isMultipleDevices ? '95%' : '100%',
               width: '100%',
               flexShrink: 0
             }}>
          {/* 部标机参数配置 */}
          {operateParams.type === 'configuration' && (
            <ParamsConfigModal
              phone={operateParams.data.phone}
              closeModal={() => setOperateParams({})}
            />
          )}
          {/* 双向对讲 */}
          {operateParams.type === 'interphone' && (
            <InterphoneModal
              data={operateParams.data}
              closeModal={() => setOperateParams({})}
            />
          )}
          {/* tts语音播报 */}
          {operateParams.type === 'tts' && (
            <TtsModal
              jtDeviceId={operateParams.data.jt808DeviceId}
              closeModal={() => setOperateParams({})}
            />
          )}
        </div>
      </div>

    </ResizableWrapper>
  );
};

export default MapMonitor;
