/**
 * 概况
 */
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Tag, Button, Form } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
  MarkPointComponent
} from 'echarts/components';
import { PieChart, BarChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { statisticsCountRequest, alarmDistributionRequest, operateTimeRequest, effectiveAlarmRequest, carOnlineRequest, alarmCompareRequest, carUpdateRequest } from '@/service/home';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { getInnerPermissionEnable } from '@/utils/permission';
import StatisticsCard from './components/statisticsCard';
import styles from './index.module.scss';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  BarChart,
  CanvasRenderer,
  LabelLayout,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
  MarkPointComponent
]);
const { RangePicker } = DatePicker;
interface CateIdParams {
  cateId: Array<string>;
}
const Home = () => {
  let titleWidth = 4
  
  // 显示预警信息对比图表
  const showAlarmCompareChart = getInnerPermissionEnable('/car-admin/home/show-alarm-compare');
  // 显示车辆更新情况柱状图
  const showUpdateChart = getInnerPermissionEnable('/car-admin/home/show-update')
  // 显示今日新增车辆
  const todayAddCar = getInnerPermissionEnable('/car-admin/home/today-add-car')
  // 显示今日维保车辆
  const todayMaintenanceCar = getInnerPermissionEnable('/car-admin/home/today-maintenance-car')
  // 显示今日升级车辆
  const todayUpgradeCar = getInnerPermissionEnable('/car-admin/home/today-upgrade-car')

  if (!todayAddCar && !todayMaintenanceCar && !todayUpgradeCar) {
    titleWidth = 8
  }

  const cateIdArr = useUserCateIdArr();
  const [statisticsCount, setStatisticsCount] = useState<any>({}); // 统计数据
  const [chartLoading, setChartLoading] = useState<any>({}); // 图表的loading效果
  const [carOnlineTimeRange, setCarOnlineTimeRange] = useState<string[]>([dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]); // 运营车辆在线情况
  const [carUpdateTimeRange, setCarUpdateTimeRange] = useState<string[]>([dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]); // 车辆更新情况
  const [searchCateId, setSearchCateId] = useState<string[]>([]); // 搜索的分类数据
  const dispatch = useDispatch();
  const alarmDistributionChart: any = useRef(null); // 车辆报警分布图表实例
  const onlineChart: any = useRef(null); // 运营车辆在线情况图表实例
  const timeChart: any = useRef(null); // 车辆运行工时图表实例
  const effectiveAlarmChart: any = useRef(null); // 有效预警数量图表实例
  const updateChart: any = useRef(null); // 车辆更新情况图表实例
  const alarmCompareChart: any = useRef(null); // 预警信息对比图表实例

  // 车辆报警分布图表自适应
  const alarmDistributionChartResize = () => {
    alarmDistributionChart.current?.resize();
  }

  // 运营车辆在线情况图表自适应
  const onlineChartResize = () => {
    onlineChart.current?.resize();
  }

  // 车辆运行工时图表自适应
  const timeChartResize = () => {
    timeChart.current?.resize();
  }

  // 有效预警数量图表自适应
  const effectiveAlarmChartResize = () => {
    effectiveAlarmChart.current?.resize();
  }

  // 车辆更新情况图表自适应
  const updateChartResize = () => {
    updateChart.current?.resize();
  }

  // 预警信息对比图表自适应
  const alarmCompareChartResize = () => {
    alarmCompareChart.current?.resize();
  }

  useEffect(() => {
    onSearch({ cateId: cateIdArr });
    // 组件销毁时将图表实例销毁
    return () => {
      alarmDistributionChart.current?.dispose();
      window.removeEventListener('resize', alarmDistributionChartResize);
      onlineChart.current?.dispose();
      window.removeEventListener('resize', onlineChartResize);
      timeChart.current?.dispose();
      window.removeEventListener('resize', timeChartResize);
      effectiveAlarmChart.current?.dispose();
      window.removeEventListener('resize', effectiveAlarmChartResize);
      updateChart.current?.dispose();
      window.removeEventListener('resize', updateChartResize);
      alarmCompareChart.current?.dispose();
      window.removeEventListener('resize', alarmCompareChartResize);
    }
  }, []);

  // 获取统计数量
  const getStatisticsCount = async (values?: CateIdParams) => {
    const res = await statisticsCountRequest(values);
    setStatisticsCount(res.data?.data || {})
  }

  // 获取车辆报警分布
  const getAlarmDistribution = async (values?: CateIdParams) => {
    try {
      setChartLoading((prevState: any) => ({ ...prevState, alarmDistributionChart: true }));
      const res = await alarmDistributionRequest(values);
      const alarmDistributionData = res.data?.data?.seriesData || [];
      // 车辆报警分布图表的配置
      const pieConfig = {
        tooltip: { trigger: 'item' },
        legend: { orient: 'vertical', left: 'left' },
        series: [
          {
            name: '报警类型',
            type: 'pie',
            radius: '50%',
            data: alarmDistributionData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      // 只有第一次时初始化图表实例
      if (!alarmDistributionChart.current) {
        alarmDistributionChart.current = echarts.init(document.getElementById('alarmDistributionChart') as HTMLElement);
        window.addEventListener('resize', alarmDistributionChartResize);
      }
      alarmDistributionChart.current.setOption(pieConfig, true);
      setChartLoading((prevState: any) => ({ ...prevState, alarmDistributionChart: false }));
    } catch (error) {
      setChartLoading((prevState: any) => ({ ...prevState, alarmDistributionChart: false }));
    }
  }

  // 获取运营车辆在线情况
  const getCarOnline = async (startTime: string, endTime: string, values?: CateIdParams) => {
    try {
      setChartLoading((prevState: any) => ({ ...prevState, onlineChart: true }));
      const res = await carOnlineRequest({ beginDate: startTime, endDate: endTime, ...values });
      const carOnlineData = res.data?.data || {};
      const xData = carOnlineData.xData;
      const dataZoom = xData.length > 10? [
        {
          type: 'slider',
          startValue: xData.length - 11,
          endValue: xData.length - 1,
        },
        {}
      ] : [];
      const onlineChartOption = {
        tooltip: { trigger: 'axis' },
        toolbox: {
          show: true,
          feature: {
            dataView: { 
              show: true,
              readOnly: false,
              title: '数据视图',
              lang:['', '关闭', '刷新']
            },
            magicType: {
              show: true,
              type: ['line', 'bar'],
              title:{ line:'切换为折线图', bar:'切换为柱状图' },
            },
            restore: { show: true, title: '还原' },
            saveAsImage: { show: true, title: '保存为图' }
          }
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          data: xData,
          name: '时间'
        }],
        yAxis: [{
          type: 'value',
          name: '数量'
        }],
        dataZoom: dataZoom,
        series: [{
          name: '总在线数',
          type: 'bar',
          stack: 'cate',
          data: carOnlineData.yData,
          markPoint: {
            data: [
              { type: 'max', name: '最大值' },
              { type: 'min', name: '最小值' }
            ]
          },
          markLine: {
            data: [{ type: 'average', name: '平均值' }]
          }
        }]
      };
      if (!onlineChart.current) {
        onlineChart.current = echarts.init(document.getElementById('onlineChart') as HTMLElement);
        window.addEventListener('resize', onlineChartResize);
      }
      onlineChart.current.setOption(onlineChartOption, true);
      setChartLoading((prevState: any) => ({ ...prevState, onlineChart: false }));
    } catch (error) {
      setChartLoading((prevState: any) => ({ ...prevState, onlineChart: false }));
    }
  }

  // 获取车辆运行工时
  const getOperateTime = async (values?: CateIdParams) => {
    try {
      setChartLoading((prevState: any) => ({ ...prevState, timeChart: true }));
      const res = await operateTimeRequest(values);
      const operateTimeData = res.data || [];
      const timeData: any = [];
      const hour4Data: any = [];
      const hour8Data: any = [];
      const hour12Data: any = [];
      const hour16Data: any = [];
      const moreHour16Data: any = [];
      operateTimeData.forEach((item: any) => {
        if (item.s === '小于4小时') {
          timeData.push(item.x);
          hour4Data.push(item.y);
        }
        if (item.s === '小于8小时') {
          hour8Data.push(item.y);
        }
        if (item.s === '小于12小时') {
          hour12Data.push(item.y);
        }
        if (item.s === '小于16小时') {
          hour16Data.push(item.y);
        }
        if (item.s === '大于16小时') {
          moreHour16Data.push(item.y);
        }
      })
      const operateTimeConfig = {
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          data: timeData,
        },
        yAxis: [{
          type: 'value'
        }],
        legend: { data: ['小于4小时', '小于8小时', '小于12小时', '小于16小时', '大于16小时'] },
        series: [
          {
            name: '小于4小时',
            type: 'bar',
            stack: 'time',
            data: hour4Data
          },
          {
            name: '小于8小时',
            type: 'bar',
            stack: 'time',
            data: hour8Data
          },
          {
            name: '小于12小时',
            type: 'bar',
            stack: 'time',
            data: hour12Data
          },
          {
            name: '小于16小时',
            type: 'bar',
            stack: 'time',
            data: hour16Data
          },
          {
            name: '大于16小时',
            type: 'bar',
            stack: 'time',
            data: moreHour16Data
          },
        ]
      }
      if (!timeChart.current) {
        timeChart.current = echarts.init(document.getElementById('timeChart') as HTMLElement);
        window.addEventListener('resize', timeChartResize);
      }
      timeChart.current.setOption(operateTimeConfig, true);
      setChartLoading((prevState: any) => ({ ...prevState, timeChart: false }));
    } catch (error) {
      setChartLoading((prevState: any) => ({ ...prevState, timeChart: false }));
    }
  }

  // 获取有效预警数量
  const getEffectiveAlarm = async (values?: CateIdParams) => {
    try {
      setChartLoading((prevState: any) => ({ ...prevState, effectiveAlarmChart: true }));
      const res = await effectiveAlarmRequest(values);
      const effectiveAlarmData = res.data || [];
      const xData: any = [];
      const yData: any = [];
      effectiveAlarmData.forEach((item: any) => {
        xData.push(item.x);
        yData.push(item.y);
      })
      const effectiveAlarmConfig = {
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          data: xData
        },
        yAxis: [{
          type: 'value'
        }],
        series: [{
          data: yData,
          type: 'bar'
        }]
      }
      if (!effectiveAlarmChart.current) {
        effectiveAlarmChart.current = echarts.init(document.getElementById('effectiveAlarmChart') as HTMLElement);
        window.addEventListener('resize', effectiveAlarmChartResize);
      }
      effectiveAlarmChart.current.setOption(effectiveAlarmConfig, true);
      setChartLoading((prevState: any) => ({ ...prevState, effectiveAlarmChart: false }));
    } catch (error) {
      setChartLoading((prevState: any) => ({ ...prevState, effectiveAlarmChart: false }));
    }
  }

  // 获取车辆更新情况
  const getCarUpdate = async (startTime: string, endTime: string, values?: CateIdParams) => {
    try {
      setChartLoading((prevState: any) => ({ ...prevState, updateChart: true }));
      const res = await carUpdateRequest({ beginDate: startTime, endDate: endTime, ...values });
      const carUpdateData = res.data?.data || {};
      const xData = carUpdateData.xData || [];
      const yData = carUpdateData.yData || [];
      const addData: any = [];
      const maintenanceData: any = [];
      const versionUpdateData: any = [];
      yData.forEach((item: any) => {
        addData.push({
          value: item.carAddonCount,
          carIds: item.carAddonIds
        });
        maintenanceData.push({
          value: item.maintenanceAddonCount,
          carIds: item.maintenanceAddonIds,
        });
        versionUpdateData.push({
          value: item.versionUpdatedCount,
          carIds: item.versionUpdatedIds,
        });
      });
      const commonConfig = {
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' }
          ]
        },
        markLine: {
          data: [{ type: 'average', name: '平均值' }]
        }
      };
      const dataZoom = xData.length > 15 ? [
        {
          type: 'slider',
          startValue: xData.length - 16,
          endValue: xData.length - 1,
        },
        {}
      ] : [];
      const onlineChartOption = {
        tooltip: { trigger: 'axis' },
        legend: { data: ['新增车辆', '维保车辆', '升级车辆'] },
        toolbox: {
          show: true,
          feature: {
            dataView: { 
              show: true,
              readOnly: false,
              title: '数据视图',
              lang:['', '关闭', '刷新']
            },
            magicType: {
              show: true,
              type: ['line', 'bar'],
              title:{ line:'切换为折线图', bar:'切换为柱状图' },
            },
            restore: { show: true, title: '还原' },
            saveAsImage: { show: true, title: '保存为图' }
          }
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          data: xData,
          name: '时间'
        }],
        yAxis: [{
          type: 'value',
          name: '数量'
        }],
        dataZoom: dataZoom,
        series: [
          {
            name: '新增车辆',
            type: 'bar',
            data: addData,
            ...commonConfig,
          },
          {
            name: '维保车辆',
            type: 'bar',
            data: maintenanceData,
            ...commonConfig,
          },
          {
            name: '升级车辆',
            type: 'bar',
            data: versionUpdateData,
            ...commonConfig,
          },
        ]
      };
      if (!updateChart.current) {
        updateChart.current = echarts.init(document.getElementById('updateChart') as HTMLElement);
        window.addEventListener('resize', updateChartResize);
      }
      updateChart.current.setOption(onlineChartOption, true);
      updateChart.current.on('click', (params: any) => {
        const clickCarIds = params.data?.carIds || [];
        // 点击右车辆信息时才跳转
        if (clickCarIds?.length) {
          dispatch({
            type: 'addTab',
            payload: {
              tab: '车辆管理',
              key: '/car-admin/car-manage',
              params: { carIds: clickCarIds, cate_id: values?.cateId }
            }
          })
        }
      })
      setChartLoading((prevState: any) => ({ ...prevState, updateChart: false }));
    } catch (error) {
      setChartLoading((prevState: any) => ({ ...prevState, updateChart: false }));
    }
  }

  // 获取预警信息对比
  const getAlarmCompare = async (values?: CateIdParams) => {
    try {
      setChartLoading((prevState: any) => ({ ...prevState, alarmCompareChart: true }));
      const res = await alarmCompareRequest(values);
      const alarmCompareData =  res.data?.data || {};
      const barConfig = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          },
          confine: true,
        },
        grid: { top: '20%' },
        legend: { data: alarmCompareData.legendData },
        calculable: true,
        yAxis: [{
          type: 'category',
          data: alarmCompareData.radiusAxisData,
          name: '时间'
        }],
        xAxis: [{
          type: 'value',
          name: '数量'
        }],
        series: alarmCompareData.series
      };
      if (!alarmCompareChart.current) {
        alarmCompareChart.current = echarts.init(document.getElementById('alarmCompareChart') as HTMLElement);
        window.addEventListener('resize', alarmCompareChartResize);
      }
      alarmCompareChart.current.setOption(barConfig, true);
      setChartLoading((prevState: any) => ({ ...prevState, alarmCompareChart: false }));
    } catch (error) {
      setChartLoading((prevState: any) => ({ ...prevState, alarmCompareChart: false }));
    }
  }

  // 搜索车辆在线情况的日期区间
  const onSearchCarline = (values: any) => {
    const firstTime = dayjs(values.time[0]).format('YYYY-MM-DD');
    const endTime = dayjs(values.time[1]).format('YYYY-MM-DD');
    getCarOnline(firstTime, endTime, { cateId: searchCateId });
    setCarOnlineTimeRange([firstTime, endTime]);
  }

  // 搜索车辆更新情况的日期区间
  const onSearchCarUpdate = (values: any) => {
    const firstTime = dayjs(values.time[0]).format('YYYY-MM-DD');
    const endTime = dayjs(values.time[1]).format('YYYY-MM-DD');
    getCarUpdate(firstTime, endTime, { cateId: searchCateId });
    setCarUpdateTimeRange([firstTime, endTime]);
  }

  // 搜索
  const onSearch = (values: any) => {
    if (!values?.cateId) {
      values.cateId = cateIdArr;
    }
    setSearchCateId(values.cateId);
    getStatisticsCount(values);
    getAlarmDistribution(values);
    getCarOnline(carOnlineTimeRange[0], carOnlineTimeRange[1], values);
    getOperateTime(values);
    getEffectiveAlarm(values);
    // 显示预警信息对比图表时才请求数据
    if (showAlarmCompareChart) {
      getAlarmCompare(values);
    }
    // 显示车辆更新情况时才请求数据
    if (showUpdateChart) {
      getCarUpdate(carUpdateTimeRange[0], carUpdateTimeRange[1], values);
    }
  };

  // 车辆指标的跳转
  const statisticsCardClick = (filterType: number) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆管理',
        key: '/car-admin/car-manage',
        params: { filterType, cate_id: searchCateId }
      }
    })
  }

  return (
    <div className={styles.home}>
      <Form onFinish={onSearch}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="分类选择" name="cateId" initialValue={cateIdArr}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">搜索</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={24}>
        <Col span={titleWidth}>
          <StatisticsCard
            title="车辆总数"
            tag="全部"
            count={statisticsCount.busAllCount}
            clickFunc={() => statisticsCardClick(0)}
          />
        </Col>
        <Col span={titleWidth}>
          <StatisticsCard
            title="今日运营车辆"
            tag="天"
            count={statisticsCount.dayOnline}
            clickFunc={() => statisticsCardClick(1)}
          />
        </Col>
        <Col span={titleWidth}>
          <StatisticsCard
            title="当前在线车辆"
            tag="天"
            count={statisticsCount.currentOnline}
            clickFunc={() => statisticsCardClick(2)}
          />
        </Col>
        {todayAddCar &&
          <Col span={titleWidth}>
            <StatisticsCard
              title="今日新增车辆"
              tag="天"
              count={statisticsCount.carAddonCount}
              clickFunc={() => statisticsCardClick(3)}
            />
          </Col>  
        }
        {todayMaintenanceCar &&
          <Col span={titleWidth}>
            <StatisticsCard
              title="今日维保车辆"
              tag="天"
              count={statisticsCount.maintenanceAddonCount}
              clickFunc={() => statisticsCardClick(4)}
            />
          </Col>
        }
        {todayUpgradeCar &&
          <Col span={titleWidth}>
            <StatisticsCard
              title="今日升级车辆"
              tag="天"
              count={statisticsCount.versionUpdatedCount}
              clickFunc={() => statisticsCardClick(5)}
            />
          </Col>
        }
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <div className={styles.chartCard}>
            <div className={styles.title}>车辆报警分布</div>
            <div className={styles.tag}>
              <Tag color="#1890ff">天</Tag>
            </div>
            <LoadingWrapper spinning={chartLoading.alarmDistributionChart} autoHeight>
              <div className={styles.chart} id="alarmDistributionChart" />
            </LoadingWrapper>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.chartCard}>
            <div className={styles.title}>运营车辆在线情况</div>
            <div className={styles.searchBox}>
              <Form onFinish={onSearchCarline} layout="vertical">
                <Row gutter={24}>
                  <Col span={18}>
                    <Form.Item label="日期范围" name="time" initialValue={[dayjs().subtract(1, 'month'), dayjs()]}>
                      <RangePicker
                        allowClear={false}
                        disabledDate={current => current > dayjs()}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" className={styles.searchButton}>搜索</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className={styles.innerTitle}>车辆在线情况</div>
            </div>
            <LoadingWrapper spinning={chartLoading.onlineChart} autoHeight>
              <div className={styles.chart} id="onlineChart" />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <div className={styles.chartCard}>
            <div className={styles.title}>车辆运行工时</div>
            <LoadingWrapper spinning={chartLoading.timeChart} autoHeight>
              <div className={styles.chart} id="timeChart" />
            </LoadingWrapper>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.chartCard}>
            <div className={styles.title}>有效预警数量</div>
            <LoadingWrapper spinning={chartLoading.effectiveAlarmChart} autoHeight>
              <div className={styles.chart} id="effectiveAlarmChart" />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>
      {/* 需要隐藏时隐藏此图标 */}
      {showUpdateChart &&
        <div className={styles.chartCard}>
        <div className={styles.title}>车辆更新情况</div>
        <div className={styles.searchBox}>
          <Form onFinish={onSearchCarUpdate} layout="vertical">
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="日期范围" name="time" initialValue={[dayjs().subtract(1, 'month'), dayjs()]}>
                  <RangePicker
                    allowClear={false}
                    disabledDate={current => current > dayjs()}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className={styles.searchButton}>搜索</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <LoadingWrapper spinning={chartLoading.updateChart} autoHeight>
          <div className={styles.chart} id="updateChart" />
        </LoadingWrapper>
      </div>
      }
      {/* 需要隐藏时隐藏此图标 */}
      {showAlarmCompareChart &&
        <div className={styles.chartCard}>
          <div className={styles.title}>预警信息对比</div>
          <div className={styles.tag}>
            <Tag color="#1890ff">7天</Tag>
          </div>
          <LoadingWrapper spinning={chartLoading.alarmCompareChart} autoHeight>
            <div className={styles.chart} id="alarmCompareChart" />
          </LoadingWrapper>
        </div>
      }
    </div>
  )
}

export default Home;