/**
 * 运维组选择框
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps, Col, Form } from 'antd';
import { getInnerPermissionEnable } from '@/utils/permission';
import { operationGroupListRequest } from '@/service/operationGroupManage';

interface OperationGroupSelectProps extends SelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  span?: number; // 表单项的栅格占位数
  inForm?: boolean; // 是否在表单中使用
  operationGroupPermissionKey: string; // 是否显示运维组的权限key
}
const OperationGroupSelect = (props: OperationGroupSelectProps) => {
  const { value, onChange, span, inForm = true, operationGroupPermissionKey } = props;
  const [operationGroupList, setOperationGroupList] = useState<any>([]); // 运维组列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>();

  useEffect(() => {
    getOperationGroupList();
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 获取运维组列表
  const getOperationGroupList = async() => {
    const res = await operationGroupListRequest();
    setOperationGroupList(res.data?.data || []);
  }

  // 选择运维组
  const groupChange = (value: string, options: any) => {
    setSelectValue(value);
    onChange && onChange(value, options)
  }

  // Select选择器
  const FormInnerSelect = () => (
    <Select
      placeholder="请选择运维组"
      showSearch
      optionFilterProp='title'
      options={operationGroupList}
      onChange={groupChange}
      value={selectValue}
      allowClear
      fieldNames={{
        label: 'title',
        value: 'id',
      }}
    />
  )


  // 判断是否有显示运维组的权限
  if (getInnerPermissionEnable(operationGroupPermissionKey)) {
    if (inForm) {
      return (
        <Col span={span}>
          <Form.Item label="运维组">
            <FormInnerSelect />
          </Form.Item>
        </Col>
      )
    } else {
      return (
        <FormInnerSelect />
      )
    }
  } else {
    return null;
  }
}

export default OperationGroupSelect;
  