/**
 * 当前疲劳驾驶车辆
 */
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Input, InputNumber } from 'antd';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import OperationGroupSelect from '@/components/operationGroupSelect';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import ExportButton from '@/components/exportButton';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { formatTime } from '@/utils/formatTime';
import {
  currentFatiguesRequest,
  currentFatiguesRequestPath,
  CurrentFatiguesRequestParams
} from '@/service/fatigueDrive';
import { snColumn } from '@/columns/carDetail';
import { formSearchGutter } from '@/constants/common';
import styles from '@/styles/pageContent.module.scss';

const CurrentFatigue = () => {
  const cateIdArr = useUserCateIdArr();
  const [loading, setLoading] = useState(false); // 数据是否请求中
  const [data, setData] = useState<any>([]); // 表格数据
  const [form] = Form.useForm();

  useEffect(() => {
    form.submit();
  }, []);

  // 获取当前疲劳驾驶的车辆
  const getCurrentFatigues = async (params: CurrentFatiguesRequestParams) => {
    try {
      setLoading(true);
      const res = await currentFatiguesRequest(params);
      setData(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // 处理搜索参数
  const operateSearchParams = (values: any, isExport?: boolean) => {
    // 存在校验不通过的信息时不触发请求
    if (
      (form.getFieldsError() || []).find((item: any) => item.errors?.length > 0)
    ) {
      return;
    }
    const {
      cateId,
      plateNumber,
      fatigueThresholdHour,
      fatigueThresholdMintue,
      restThresholdHour,
      restThresholdMintue
    } = values;
    const params: any = { cateId, plateNumber };
    params.fatigueThreshold =
      fatigueThresholdHour * 60 + fatigueThresholdMintue;
    params.restThreshold = restThresholdHour * 60 + restThresholdMintue;
    params.export = Boolean(isExport);
    return params;
  };

  // 搜索
  const onSearch = (values: any) => {
    const params = operateSearchParams(values);
    if (params) {
      getCurrentFatigues(params);
    }
  };

  // 生成导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  };

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId
      });
    }
  };

  // 校验疲劳驾驶时长阈值
  const validatorFatigueThreshold = () => {
    const { getFieldsValue, setFields } = form;
    const { fatigueThresholdHour, fatigueThresholdMintue } = getFieldsValue();
    if (fatigueThresholdHour || fatigueThresholdMintue) {
      setFields([{ name: 'fatigueThreshold', errors: [], value: '' }]);
    } else {
      setFields([
        {
          name: 'fatigueThreshold',
          errors: ['请输入疲劳驾驶时长阈值'],
          value: ''
        }
      ]);
    }
  };

  // 校验退出疲劳驾驶时长阈值
  const validatorRestThreshold = () => {
    const { getFieldsValue, setFields } = form;
    const { restThresholdHour, restThresholdMintue } = getFieldsValue();
    if (restThresholdHour || restThresholdMintue) {
      setFields([{ name: 'restThreshold', errors: [], value: '' }]);
    } else {
      setFields([
        {
          name: 'restThreshold',
          errors: ['请输入退出疲劳驾驶时长阈值'],
          value: ''
        }
      ]);
    }
  };

  // 额外的展开行的渲染
  const expandedRowRender = (record: any) => {
    const innerColumns = [
      {
        title: '疲劳开始时间',
        dataIndex: 'beginTime',
        width: 150,
        render: (value: string) => formatTime(value)
      },
      {
        title: '疲劳结束时间',
        dataIndex: 'endTime',
        width: 150,
        render: (value: string) => formatTime(value)
      },
      {
        title: '连续驾驶时长(分钟)',
        dataIndex: 'minute',
        width: 160
      }
    ];
    return (
      <CommonTable
        rowKey="id"
        columnFilter={false}
        columns={innerColumns}
        dataSource={record.records || []}
        pagination={false}
        bordered
        scroll={{ y: 300 }}
        size="small"
        isNestTable
      />
    );
  };

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as const
    },
    snColumn,
    {
      title: '连续驾驶时长(分钟)',
      dataIndex: 'totalMinute',
      width: 160
    },
    {
      title: '疲劳开始时间',
      dataIndex: 'beginTime',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '疲劳结束时间',
      dataIndex: 'endTime',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '最后在线时间',
      dataIndex: 'onlineAt',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '速度',
      dataIndex: 'speed',
      width: 90
    }
  ];

  return (
    <div className={styles.commonTableWrapper}>
      <Form
        onFinish={onSearch}
        form={form}
        style={{ marginTop: '16px' }}
        autoComplete="off"
      >
        <Row gutter={formSearchGutter} wrap>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey="/car-admin/fatigue-drive/show-operation-group"
            span={8}
          />
          <Col span={8}>
            <Form.Item
              label="分组选择"
              name="cateId"
              initialValue={cateIdArr}
              rules={[{ required: true, message: '请选择分组' }]}
            >
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="疲劳驾驶阈值" required name="fatigueThreshold">
              <Input.Group compact>
                <Form.Item noStyle name="fatigueThresholdHour" initialValue={5}>
                  <InputNumber
                    addonAfter="小时"
                    precision={0}
                    min={0}
                    style={{ width: '50%' }}
                    onChange={validatorFatigueThreshold}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  name="fatigueThresholdMintue"
                  initialValue={0}
                >
                  <InputNumber
                    addonAfter="分钟"
                    precision={0}
                    min={0}
                    max={60}
                    style={{ width: '50%' }}
                    onChange={validatorFatigueThreshold}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="退出疲劳驾驶阈值" name="restThreshold" required>
              <Input.Group compact>
                <Form.Item noStyle name="restThresholdHour" initialValue={0}>
                  <InputNumber
                    addonAfter="小时"
                    precision={0}
                    min={0}
                    style={{ width: '50%' }}
                    onChange={validatorRestThreshold}
                  />
                </Form.Item>
                <Form.Item noStyle name="restThresholdMintue" initialValue={30}>
                  <InputNumber
                    addonAfter="分钟"
                    precision={0}
                    min={0}
                    style={{ width: '50%' }}
                    onChange={validatorRestThreshold}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <ExportButton
                  url={currentFatiguesRequestPath}
                  fileName="疲劳驾驶数据导出"
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey="/car-admin/fatigue-drive/current-export"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="carId"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
        expandable={{
          expandedRowRender
        }}
      />
    </div>
  );
};

export default CurrentFatigue;
