/**
 * 视频轮巡
 */
import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Select, Form, Row, Col } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import { initCarTreeData, removeCarTreeData } from '@/store/carTree/actions';
import MultipleWindowLayout from '@/components/multipleWindowLayout';
import LiveVideo from '@/components/liveVideo';
import { filterMultipleCateData } from '@/utils/cate';
import { formSearchGutter } from '@/constants/common';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';

const VideoTurning = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: any) => state.userStore);
  const { carTreeData } = useSelector((state: any) => state.carTreeStore);
  const videoRef = useRef<any>(); // 视频区域DOM
  const switchSecondRef = useRef<number>(30); // 视频切换间隔(秒)
  const windowCountRef = useRef<number>(16); // 视频播放窗口数量
  const timerRef = useRef<any>(null); // 视频定时切换定时器
  const playStartIndexRef = useRef<number>(0); // 页面中视频播放开始的索引
  const allChannelDataRef = useRef<any>([]); // 所有在线的通道数据
  const selectChannelDataRef = useRef<any>([]); // 选择的在线通道数据
  const isUseSelectDataRef = useRef<boolean>(false); // 是否使用选择的数据
  const liveChannelDataRef = useRef<any>([]); // 播放的通道数据
  const [windowCount, setWindowCount] = useState(16); // 视频播放窗口数量
  const [liveChannelData, setLiveChannelData] = useState([]); // 播放的通道数据
  const [isPause, setIsPause] = useState(false); // 是否在暂停轮巡
  const [allCarList, setAllCarList] = useState([]); // 可供选择的车辆数据

  useEffect(() => {
    if (carTreeData.length === 0) {
      dispatch(initCarTreeData(false, true) as any);
    } else {
      dispatch({
        type: 'addCarTree'
      });
    }
    return () => {
      dispatch(removeCarTreeData() as any);
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = null;
    };
  }, []);

  // 获取可以播放视频的通道数据
  const getOnlineChannelData = (treeData: any) => {
    if (Array.isArray(treeData) && treeData.length > 0) {
      const allCarData: any = [];
      const allOnlineData: any = [];
      const deep = (innerData: any) => {
        innerData.forEach((item: any) => {
          const {
            type,
            title,
            jt808IsOnline,
            acc,
            channelNoList,
            jt808DeviceId,
            terminalPhone,
            version,
            jt808OnlineFlag
          } = item;
          if (type === 'car' && jt808DeviceId && (jt808OnlineFlag || jt808IsOnline) && acc) {
            (channelNoList || []).forEach((channel: number) => {
              allOnlineData.push({
                channel,
                jt808DeviceId,
                terminalPhone,
                version,
                plateNumber: title
              });
            });
          }
          if (type === 'car' && jt808DeviceId) {
            allCarData.push({
              channel: channelNoList,
              jt808DeviceId,
              terminalPhone,
              version,
              plateNumber: title,
              disabled: !(jt808OnlineFlag || jt808IsOnline) || !acc
            });
          }
          if (Array.isArray(item.children) && item.children.length > 0) {
            deep(item.children);
          }
        });
      };
      deep(treeData);
      return { allOnlineData, allCarData };
    } else {
      return {};
    }
  };

  useEffect(() => {
    let newCarTreeData = carTreeData;
    // 多分组用户车辆树形数据特殊处理(兼容选择多分组形式但只有一个分组的形式)
    if (userDetail.multiCate && JSON.parse(userDetail.multiCate)?.length > 1) {
      newCarTreeData = filterMultipleCateData(
        carTreeData,
        JSON.parse(userDetail.multiCate)
      );
    }
    const { allOnlineData = [], allCarData = [] } =
      getOnlineChannelData(newCarTreeData);
    setAllCarList(allCarData);
    allChannelDataRef.current = allOnlineData;
    if (allOnlineData.length && !timerRef.current && !isPause) {
      onPlay();
    }
  }, [carTreeData]);

  // 全屏
  const fullScreen = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen();
    }
  };

  // 播放
  const onPlay = () => {
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef; // 区分使用全量数据还是选择的数据
    const playEndIndex = playStartIndexRef.current + windowCountRef.current;
    const channelDataLength = matchData.current.length;
    const newLiveChannelData = matchData.current.slice(
      playStartIndexRef.current,
      playEndIndex
    );
    // 兼容循环结束的情况
    if (playEndIndex > channelDataLength && channelDataLength >= windowCountRef.current) {
      newLiveChannelData.push(
        ...matchData.current.slice(0, playEndIndex - channelDataLength)
      );
    }
    liveChannelDataRef.current = newLiveChannelData;
    setLiveChannelData(newLiveChannelData);
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    // 没有暂停轮巡时定时切换
    if (!isPause) {
      timerRef.current = setTimeout(() => {
        onNext();
      }, switchSecondRef.current * 1000);
    }
  };

  // 上一页
  const onPrev = () => {
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef; // 区分使用全量数据还是选择的数据
    const channelDataLength = matchData.current.length;
    const startLiveChannel: any = liveChannelDataRef.current[0]; // 正在播放的第一条数据
    if (matchData.current.length && startLiveChannel) {
      // 所有数据中找到当前播放的第一条数据索引时，新的开始索设置为该索引
      const endLiveChannelIndex = matchData.current.findIndex(
        (item: any) =>
          item.channel === startLiveChannel.channel &&
          item.terminalPhone === startLiveChannel.terminalPhone
      );
      if (endLiveChannelIndex >= 0) {
        playStartIndexRef.current = endLiveChannelIndex;
      }
    }
    if (playStartIndexRef.current - windowCountRef.current < 0) {
      playStartIndexRef.current =
        channelDataLength +
        (playStartIndexRef.current - windowCountRef.current);
    } else {
      playStartIndexRef.current =
        playStartIndexRef.current - windowCountRef.current;
    }
    onPlay();
  };

  // 下一页
  const onNext = () => {
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef; // 区分使用全量数据还是选择的数据
    const channelDataLength = matchData.current.length;
    const endLiveChannel: any =
      liveChannelDataRef.current[liveChannelDataRef.current.length - 1]; // 正在播放的最后一条数据
    let newPlayStartIndex = playStartIndexRef.current + windowCountRef.current; // 新播放数据的起始索引
    if (matchData.current.length && endLiveChannel) {
      // 所有数据中找到当前播放的最后一条数据索引时，新的开始索引为结束索引加1
      const endLiveChannelIndex = matchData.current.findIndex(
        (item: any) =>
          item.channel === endLiveChannel.channel &&
          item.terminalPhone === endLiveChannel.terminalPhone
      );
      if (endLiveChannelIndex >= 0) {
        newPlayStartIndex = endLiveChannelIndex + 1;
      }
    }
    if (newPlayStartIndex >= channelDataLength) {
      playStartIndexRef.current = newPlayStartIndex - channelDataLength;
    } else {
      playStartIndexRef.current = newPlayStartIndex;
    }
    onPlay();
  };

  // 改变轮巡状态
  const changePauseState = () => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    if (isPause) {
      // 继续轮巡时重新设置定时器
      timerRef.current = setTimeout(() => {
        onNext();
      }, switchSecondRef.current * 1000);
    }
    setIsPause(!isPause);
  };

  // 选择车辆改变
  const onSelectCarChange = (value: any, data: any) => {
    const selectChannel: any = [];
    data.forEach((car: any) => {
      car.channel.forEach((channel: any) => {
        selectChannel.push({
          ...car,
          channel
        });
      });
    });
    selectChannelDataRef.current = selectChannel;
  };

  // 确定选择的车辆
  const onConfirmSelectCar = () => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    isUseSelectDataRef.current = true;
    playStartIndexRef.current = 0;
    onPlay();
  };

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={styles.spaceBetweenFlex}>
        <Row gutter={formSearchGutter}>
          <Col>
            <Form.Item label="轮巡间隔">
              <Select
                options={[
                  { label: '30秒', value: 30 },
                  { label: '1分钟', value: 60 },
                  { label: '2分钟', value: 120 }
                ]}
                defaultValue={30}
                onChange={(value: number) => (switchSecondRef.current = value)}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="窗口数量">
              <Select
                options={[
                  { label: '4', value: 4 },
                  { label: '9', value: 9 },
                  { label: '16', value: 16 }
                ]}
                defaultValue={windowCount}
                onChange={(value: number) => {
                  windowCountRef.current = value;
                  setWindowCount(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="轮巡车辆">
              <Select
                options={allCarList}
                maxTagCount="responsive"
                fieldNames={{ label: 'plateNumber', value: 'jt808DeviceId' }}
                placeholder="请选择轮巡车辆"
                showSearch
                optionFilterProp="plateNumber"
                onChange={onSelectCarChange}
                mode="multiple"
                style={{ width: '180px' }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="">
              <Button type="primary" onClick={onConfirmSelectCar}>
                确定轮巡车辆
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.operateBox}>
          <Button type="primary" onClick={changePauseState}>
            {isPause ? '继续轮巡' : '暂停轮巡'}
          </Button>
          <Button type="primary" onClick={onPrev}>
            上一页
          </Button>
          <Button type="primary" onClick={onNext}>
            下一页
          </Button>
          <Button icon={<FullscreenOutlined />} onClick={fullScreen}>
            全屏
          </Button>
        </div>
      </div>
      <div className={ownStyles.videoBox} ref={videoRef}>
        <MultipleWindowLayout
          windowCount={windowCount}
          windowAllData={liveChannelData}
          gap={4}
          className={ownStyles.multipleWindowLayout}
        >
          <LiveVideo />
        </MultipleWindowLayout>
      </div>
    </Card>
  );
};

export default VideoTurning;
