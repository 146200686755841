/**
 * 设备相关请求
 */
import request from "@/utils/request";

export interface DeviceListRequestParams {
  page: number,
  limit: number;
  sn?: string;
  cameraSn?: string;
  sim?: string;
  plateNumber?: string;
}

// 获取设备列表
export const deviceListRequestPath = '/api/v2/device/list';
export const deviceListRequest = (params: DeviceListRequestParams) => {
  return request.get(deviceListRequestPath, { params })
}

// 获取adas设备列表
export const adasListRequestPath = '/api/v2/adasDevice/list';
export const adasListRequest = (params: DeviceListRequestParams) => {
  return request.get(adasListRequestPath, { params })
}

export interface UpsertDeviceRequestParams {
  id?: number;
  sn: string;
  cameraSn: string;
  sim: string;
  deviceType: string;
}

// 更新或新增设备
export const upsertDeviceRequest = (data: UpsertDeviceRequestParams) => {
  return request.post('/api/v2/device/upsert', data)
}

// 设备详情
export const deviceDetailRequest = (id: number) => {
  return request.get(`/api/v2/device/detail/${id}`)
}

// 设备详情(通过sn查询)
export const deviceDetailBySnRequest = (sn: string) => {
  return request.get(`/api/v2/device/detailBySn/${sn}`)
}

// 设备状态
export const deviceStateRequest = (id: number) => {
  return request.get(`/api/v2/device/state/${id}`)
}

// 删除设备
export const deleteDeviceRequest = (id: number) => {
  return request.delete(`/api/v2/device/${id}`)
}
// 解绑设备
export const unbindRequest = (id: number) => {
  return request.delete(`/api/v2/device/unbind/${id}`)
}

// 批量导入设备的请求路径
export const deviceImportRequestPath = '/api/v2/device/import';

// 获取设备版本信息
export const getVersionInfoRequest = (sn: string) => {
  return request.get(`/api/v2/deviceInfo/versionInfo/${sn}`)
}

// 获取设备参数信息
export const getAebConfigInfoRequest = (sn: string) => {
  return request.get(`/api/v2/deviceInfo/aebConfig/${sn}`)
}
