/**
 * 参数配置
 */
import React, { Fragment, useState } from 'react';
import { Card, Form, Row, Col, Button, Input } from 'antd';
import { paramListRequest } from '@/service/parameterConfiguration';
import CommonTable from '@/components/commonTable';
import PositionModal from '@/components/positionModal';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { displayDeviceTypeCategory, isKlsV3Aeb } from '@/utils/device';
import { snColumn } from '@/columns/carDetail';
import { formSearchGutter } from '@/constants/common';
import CarTypeModal from './components/carTypeModal';
import GpsFrequencyModal from './components/gpsFrequencyModal';
import WareInfoModal from './components/wareInfoModal';
import AebParamsModal from './components/aebParamsModal';
import JtKlsAebParamsModal from './components/jtKlsAebParamsModal';
import RecorderModal from './components/recorderModal';
import SensorModal from './components/sensorModal';
import ShootHistory from './components/shootHistory';
import RecorderLogModal from './components/recorderLogModal';
import CameraModal from './components/cameraModal';
import CableMotorModal from './components/cableMotorModal';
import styles from '@/styles/pageContent.module.scss';

const ParameterConfiguration = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: paramListRequest,
    initPageSize: 40,
    searchParams
  });

  // 处理搜索的参数
  const operateSearchParams = (params: any) => {
    const newParams = { ...params };
    newParams.sn = (params.sn || '').trim();
    newParams.plateNumber = (params.plateNumber || '').trim();
    newParams.cameraSn = (params.cameraSn || '').trim();
    newParams.sim = (params.sim || '').trim();
    return newParams;
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 表格列显示
  const columns = [
    snColumn,
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110
    },
    {
      title: '相机SN',
      dataIndex: 'cameraSn',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: 'SIM卡ICCID',
      dataIndex: 'sim',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '设备类型大类',
      dataIndex: 'deviceTypeCategory',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: number) => displayDeviceTypeCategory(value)
    },
    {
      title: '是否在线',
      dataIndex: 'isOnline',
      width: 90,
      render: (value: boolean) => (value ? '是' : '否')
    },
    {
      title: '最近在线时间',
      dataIndex: 'onlineAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '最后更改时间',
      dataIndex: 'updatedAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 440,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        const isKlsV3 = isKlsV3Aeb(item.deviceTypeCategory); // 是否为昆仑山3.0设备
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/parameter-configuration/aeb-params-view"
              type="link"
              onClick={() =>
                setOperateInfo({
                  type: isKlsV3 ? 'jtKlsAeb' : 'aeb',
                  data: item
                })
              }
            >
              AEB参数
            </PermissionButton>
            <PermissionButton
              operateType="/car-admin/parameter-configuration/car-type-view"
              type="link"
              onClick={() =>
                setOperateInfo({
                  type: 'carType',
                  data: { ...item, isKlsV3 }
                })
              }
            >
              车身类型
            </PermissionButton>
            <PermissionButton
              operateType="/car-admin/parameter-configuration/ware-info-view"
              type="link"
              onClick={() =>
                setOperateInfo({
                  type: 'wareInfo',
                  data: { ...item, isKlsV3 }
                })
              }
            >
              软硬件信息
            </PermissionButton>
            <PermissionButton
              operateType="/car-admin/parameter-configuration/sensor-configuration-view"
              type="link"
              onClick={() =>
                setOperateInfo({ type: 'sensor', data: { ...item, isKlsV3 } })
              }
            >
              传感器配置
            </PermissionButton>
            <PermissionButton
              operateType="/car-admin/parameter-configuration/camera-configuration-view"
              type="link"
              onClick={() =>
                setOperateInfo({ type: 'camera', data: { ...item, isKlsV3 } })
              }
            >
              相机参数配置
            </PermissionButton>
            {/* 昆仑山3.0设备不显示以下配置 */}
            {isKlsV3 || (
              <Fragment>
                <PermissionButton
                  operateType="/car-admin/parameter-configuration/gps-config"
                  type="link"
                  onClick={() => setOperateInfo({ type: 'gps', data: item })}
                >
                  GPS上报频率
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/parameter-configuration/record-configuration-view"
                  type="link"
                  onClick={() =>
                    setOperateInfo({ type: 'recorder', data: item })
                  }
                >
                  记录仪配置
                </PermissionButton>
              </Fragment>
            )}
            <PermissionButton
              operateType="/car-admin/parameter-configuration/cableMotor-configuration-view"
              type="link"
              onClick={() =>
                setOperateInfo({
                  type: 'cableMotor',
                  data: { ...item, isKlsV3 }
                })
              }
            >
              拉线电机配置
            </PermissionButton>
            {isKlsV3 || (
              <Fragment>
                <PermissionButton
                  operateType="/car-admin/parameter-configuration/shoot-view"
                  type="link"
                  onClick={() => setOperateInfo({ type: 'shoot', data: item })}
                >
                  视频获取
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/parameter-configuration/shoot-log-view"
                  type="link"
                  onClick={() => setOperateInfo({ type: 'log', data: item })}
                >
                  视频日志获取
                </PermissionButton>
              </Fragment>
            )}
            <Button
              type="link"
              onClick={() => setOperateInfo({ type: 'position', data: item })}
            >
              最近位置
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form
        onFinish={onSearch}
        // layout="vertical"
        autoComplete="off"
        form={form}
      >
        <Row gutter={formSearchGutter}>
          <Col span={5}>
            <Form.Item label="设备SN" name="sn">
              <Input placeholder="请输入设备SN" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="相机SN" name="cameraSn">
              <Input placeholder="请输入相机SN" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="SIM" name="sim">
              <Input placeholder="请输入SIM" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'position' && (
        <PositionModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'carType' && (
        <CarTypeModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'gps' && (
        <GpsFrequencyModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'wareInfo' && (
        <WareInfoModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'aeb' && (
        <AebParamsModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'jtKlsAeb' && (
        <JtKlsAebParamsModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'recorder' && (
        <RecorderModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'sensor' && (
        <SensorModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'cableMotor' && (
        <CableMotorModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'shoot' && (
        <ShootHistory
          data={operateInfo.data}
          onClose={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'log' && (
        <RecorderLogModal
          data={operateInfo.data}
          onClose={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'camera' && (
        <CameraModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
    </Card>
  );
};

export default ParameterConfiguration;
