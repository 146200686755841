/**
 * 操作车队档案
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, Radio } from 'antd';
import { cateAllCompanyRequest } from '@/service/fleetRisk';
import { upsertCompany } from '@/service/safeReport';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择

  useEffect(() => {
    carInterviewEdit()
    getCateAllCompanyData()
  }, []);


  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data 
    const aa: any = []
    data.forEach((item: any) => {
      aa.push({ label: item.title, value: item.title })
    })
    setAllCompany(aa)
  }


  // 编辑的时候赋值
  const carInterviewEdit = async () => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
  }

  //车队评级
  const safeGrade = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
  ]

  //车队状态
  const safeState = [
    { label: '启用', value: '启用' },
    { label: '停用', value: '停用' },
  ]

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertCompany(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }


  return (
    <Modal
      title={type === 'add' ? '添加车队档案' : '编辑车队档案'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError>
        <Form.Item
          label="车队选择"
          name="name"
          rules={[{ required: true, message: '请选择车队' }]}>
          {/* <Select
            showSearch
            placeholder="请选择"
            style={{ width: '100%' }}
            options={allCompany}
          /> */}
          <Input placeholder='请输入'></Input>
        </Form.Item>
        <Form.Item
          name="head"
          label="负责人"
          rules={[{ required: true, message: '请输入负责人' }]}
        >
          <Input placeholder="请输入负责人" />
        </Form.Item>
        <Form.Item
          name="tel"
          label="联系方式"
          rules={[{ required: true, message: '请输入联系方式' }]}
        >
          <Input placeholder="请输入联系方式" />
        </Form.Item>
        <Form.Item
          label="车队评级"
          name="grade"
          rules={[{ required: true, message: '请选择车队评级' }]}
        >
          <Select
            placeholder="请选择车队评级"
            options={safeGrade}
            allowClear
            showArrow
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="车队状态"
          name="state"
          rules={[{ required: true, message: '请选择车队状态' }]}
        >
          <Select
            placeholder="请选择车队状态"
            options={safeState}
            allowClear
            showArrow
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item name="buildHome" label="是否建群">
          <Radio.Group>
            <Radio value="是">是</Radio>
            <Radio value="否">否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          rules={[{ required: true, message: '请输入备注' }]}
        >
          <Input.TextArea showCount placeholder="请输入备注" style={{ height: 120 }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
