// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","alarmMonitor":"alarmMonitor_alarmMonitor__IwS0l","alarmTabs":"alarmMonitor_alarmTabs__7hBp0","alarmTabItemBox":"alarmMonitor_alarmTabItemBox__AYH9O","alarmTabItem":"alarmMonitor_alarmTabItem__iEESE","macthActiveAlarmTabItem":"alarmMonitor_macthActiveAlarmTabItem__KJNyA","macthAlarmTabItem":"alarmMonitor_macthAlarmTabItem__u1g-o","activeAlarmTabItem":"alarmMonitor_activeAlarmTabItem__ahWZg","iconBox":"alarmMonitor_iconBox__CIz-9"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"alarmMonitor\":\"alarmMonitor_alarmMonitor__IwS0l\",\"alarmTabs\":\"alarmMonitor_alarmTabs__7hBp0\",\"alarmTabItemBox\":\"alarmMonitor_alarmTabItemBox__AYH9O\",\"alarmTabItem\":\"alarmMonitor_alarmTabItem__iEESE\",\"macthActiveAlarmTabItem\":\"alarmMonitor_macthActiveAlarmTabItem__KJNyA\",\"macthAlarmTabItem\":\"alarmMonitor_macthAlarmTabItem__u1g-o\",\"activeAlarmTabItem\":\"alarmMonitor_activeAlarmTabItem__ahWZg\",\"iconBox\":\"alarmMonitor_iconBox__CIz-9\"}";
        // 1731660741939
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  