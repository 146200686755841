// extracted by mini-css-extract-plugin
export default {"dataBox":"dataSizeDetails_dataBox__Rhivk","dataBoxLittleTitle":"dataSizeDetails_dataBoxLittleTitle__I-Tmz","dataBoxTitle":"dataSizeDetails_dataBoxTitle__wvCow","dataBoxContent":"dataSizeDetails_dataBoxContent__BEMCC","dataBoxContentLeft":"dataSizeDetails_dataBoxContentLeft__ibBPt","leftChartOne":"dataSizeDetails_leftChartOne__W0Zsu","dataBoxContentLeftItemTitle":"dataSizeDetails_dataBoxContentLeftItemTitle__ig9+W","dataBoxContentLeftList":"dataSizeDetails_dataBoxContentLeftList__RtjwJ","dataBoxContentLeftNone":"dataSizeDetails_dataBoxContentLeftNone__RfaLr","dataBoxContentLeftItem":"dataSizeDetails_dataBoxContentLeftItem__5udRi","dataBoxContentLeftItemLeft":"dataSizeDetails_dataBoxContentLeftItemLeft__kp2Ji","dataBoxContentLeftItemMiddle":"dataSizeDetails_dataBoxContentLeftItemMiddle__yBrio","dataBoxContentLeftItemRight":"dataSizeDetails_dataBoxContentLeftItemRight__s404M","dataBoxContentMiddle":"dataSizeDetails_dataBoxContentMiddle__mWFRn","dataBoxContentMiddleTitle":"dataSizeDetails_dataBoxContentMiddleTitle__usLdO","mapBox":"dataSizeDetails_mapBox__KAmQY","dataBoxContentRight":"dataSizeDetails_dataBoxContentRight__M0pjC","rightChartOne":"dataSizeDetails_rightChartOne__9pD6s","rightChartTwo":"dataSizeDetails_rightChartTwo__kSM+c","rightChartThree":"dataSizeDetails_rightChartThree__eg-DS"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"dataBox\":\"dataSizeDetails_dataBox__Rhivk\",\"dataBoxLittleTitle\":\"dataSizeDetails_dataBoxLittleTitle__I-Tmz\",\"dataBoxTitle\":\"dataSizeDetails_dataBoxTitle__wvCow\",\"dataBoxContent\":\"dataSizeDetails_dataBoxContent__BEMCC\",\"dataBoxContentLeft\":\"dataSizeDetails_dataBoxContentLeft__ibBPt\",\"leftChartOne\":\"dataSizeDetails_leftChartOne__W0Zsu\",\"dataBoxContentLeftItemTitle\":\"dataSizeDetails_dataBoxContentLeftItemTitle__ig9+W\",\"dataBoxContentLeftList\":\"dataSizeDetails_dataBoxContentLeftList__RtjwJ\",\"dataBoxContentLeftNone\":\"dataSizeDetails_dataBoxContentLeftNone__RfaLr\",\"dataBoxContentLeftItem\":\"dataSizeDetails_dataBoxContentLeftItem__5udRi\",\"dataBoxContentLeftItemLeft\":\"dataSizeDetails_dataBoxContentLeftItemLeft__kp2Ji\",\"dataBoxContentLeftItemMiddle\":\"dataSizeDetails_dataBoxContentLeftItemMiddle__yBrio\",\"dataBoxContentLeftItemRight\":\"dataSizeDetails_dataBoxContentLeftItemRight__s404M\",\"dataBoxContentMiddle\":\"dataSizeDetails_dataBoxContentMiddle__mWFRn\",\"dataBoxContentMiddleTitle\":\"dataSizeDetails_dataBoxContentMiddleTitle__usLdO\",\"mapBox\":\"dataSizeDetails_mapBox__KAmQY\",\"dataBoxContentRight\":\"dataSizeDetails_dataBoxContentRight__M0pjC\",\"rightChartOne\":\"dataSizeDetails_rightChartOne__9pD6s\",\"rightChartTwo\":\"dataSizeDetails_rightChartTwo__kSM+c\",\"rightChartThree\":\"dataSizeDetails_rightChartThree__eg-DS\"}";
        // 1731660741246
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  