// extracted by mini-css-extract-plugin
export default {"trackPlaybackBox":"realMonitoring_trackPlaybackBox__J0WQy","carTree":"realMonitoring_carTree__3qxK2","monitoring":"realMonitoring_monitoring__Aed6H","bysk-p-layout-view":"realMonitoring_bysk-p-layout-view__kj7pf","monitoringTitle":"realMonitoring_monitoringTitle__Bs7dN","monitoringButton":"realMonitoring_monitoringButton__iYgIb","monitoringContent":"realMonitoring_monitoringContent__ghLvn","text":"realMonitoring_text__LY-Fb"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"trackPlaybackBox\":\"realMonitoring_trackPlaybackBox__J0WQy\",\"carTree\":\"realMonitoring_carTree__3qxK2\",\"monitoring\":\"realMonitoring_monitoring__Aed6H\",\"bysk-p-layout-view\":\"realMonitoring_bysk-p-layout-view__kj7pf\",\"monitoringTitle\":\"realMonitoring_monitoringTitle__Bs7dN\",\"monitoringButton\":\"realMonitoring_monitoringButton__iYgIb\",\"monitoringContent\":\"realMonitoring_monitoringContent__ghLvn\",\"text\":\"realMonitoring_text__LY-Fb\"}";
        // 1731660723663
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  