// extracted by mini-css-extract-plugin
export default {"operationGroupManage":"operationGroupManage_operationGroupManage__LL2Mw","leftPart":"operationGroupManage_leftPart__t4Gcl","buttonBox":"operationGroupManage_buttonBox__K8VtZ","groupBox":"operationGroupManage_groupBox__oQy2T","groupItem":"operationGroupManage_groupItem__j6lV7","rightPart":"operationGroupManage_rightPart__fM4oZ","title":"operationGroupManage_title__EVaJv","treeBox":"operationGroupManage_treeBox__ijMkv","searchBox":"operationGroupManage_searchBox__Br20Z","tree":"operationGroupManage_tree__wKA60","placeholderBox":"operationGroupManage_placeholderBox__129HX"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"operationGroupManage\":\"operationGroupManage_operationGroupManage__LL2Mw\",\"leftPart\":\"operationGroupManage_leftPart__t4Gcl\",\"buttonBox\":\"operationGroupManage_buttonBox__K8VtZ\",\"groupBox\":\"operationGroupManage_groupBox__oQy2T\",\"groupItem\":\"operationGroupManage_groupItem__j6lV7\",\"rightPart\":\"operationGroupManage_rightPart__fM4oZ\",\"title\":\"operationGroupManage_title__EVaJv\",\"treeBox\":\"operationGroupManage_treeBox__ijMkv\",\"searchBox\":\"operationGroupManage_searchBox__Br20Z\",\"tree\":\"operationGroupManage_tree__wKA60\",\"placeholderBox\":\"operationGroupManage_placeholderBox__129HX\"}";
        // 1731660723684
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  